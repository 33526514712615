.update-location {
  .project-map {
    .map {
      margin-bottom: 0;

      .mapboxgl-map {
        border-radius: 15px;
      }
    }
  }
}