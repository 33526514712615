/*------------------------------------*\
    BouwApp - Heading
\*------------------------------------*/
.bouwapp-heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;

  h1 {
    width: 50%;
    min-width: 50%;
    font-size: 36px;
    line-height: 48px;
    font-weight: $fontweight-bold;
    color: $color-dark;
    margin: 0;
  }

  > div {
    width: 50%;
    min-width: 50%;

    button {
      margin-left: auto;
    }
  }
}

@media screen and (max-width: $screen--m-max) {
  .bouwapp-heading {
    flex-direction: column;
    align-items: flex-start;

    h1 {
      width: 100%;
      min-width: 100%;
      font-size: 32px;
    }

    > div {
      width: 100%;
      min-width: 100%;
      margin: 16px 0 0 0;

      button {
        margin: 0;
      }
    }
  }
}

/*------------------------------------*\
  BouwApp - Global box
\*------------------------------------*/
.bouwapp-box {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  height: auto;
  transition: 0.1s all ease-in;
  background: $color-white;
  border: 1px solid $color-border;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04);
  border-radius: 6px;

  &[hidden] {
    opacity: 0;
    height: 0;
  }

  &__title {
    padding: 1.6rem 2.4rem;
    border-bottom: 1px solid $color-border;
    position: relative;

    button {
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
    }

    h2 {
      font-size: 18px;
      font-weight: $fontweight-bold;
      color: $color-dark;
      margin: 0;

      span {
        font-size: 18px;
        line-height: 18px;
        color: $color-grey;
      }
    }

    .popover {
      font-family: inherit;
    }
  }

  &__subheader {
    max-width: 1024px;
    margin-bottom: 2.4rem;

    &.border {
      padding-bottom: 2.4rem;
      border-bottom: 1px solid $color-border;
    }

    &.no-margin {
      margin: 0;
    }

    p {
      margin: 0;
    }

    > button {
      margin: 0;
      margin-top: 1.6rem;
    }
  }

  &__subtitle {
    &__category_description {
      color: $color-grey;
      font-size: 14px;
    }

    h3 {
      color: $color-dark;
      font-weight: $fontweight-semibold;
      font-size: 16px;
      margin: 4px 0;

      span {
        color: $color-grey;
        font-size: 14px;
      }
    }

    p {
      font-size: 16px;
      margin: 2px 0;
    }

    &:not(:first-child) {
      margin-top: 16px;
    }
  }

  &__self {
    padding: 2.4rem;

    &.hideTitle {
      width: 100%;
    }

    > h2 {
      font-size: 18px;
      line-height: 30px;
      color: $color-dark;
      margin: 0;
    }

    .form-split {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      > div {
        width: calc(50% - 12px);
        min-width: calc(50% - 12px);
      }
    }

    > p,
    > h3 {
      font-size: 16px;
      font-weight: $fontweight-regular;
      color: $color-grey;
      margin: 0 0 16px 0;
    }

    .form-group {
      position: relative;

      > p {
        margin: 0;
      }

      > h3,
      > label {
        display: inline-block;
        font-size: 16px;
        font-weight: $fontweight-semibold;
        color: $color-dark;
        margin: 4px 0;

        span.status {
          display: inline-block;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          margin-left: 8px;
        }
      }
    }

    &__children {
      position: relative;
      max-width: 1024px;

      > p.no-results {
        font-size: 16px;
        font-weight: $fontweight-regular;
        color: $color-grey;
        margin: 40px 0 0 0;
        text-align: center;
      }

      &__head {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 16px;

        p,
        a {
          display: inline-block;
          font-size: 16px;
          line-height: 22px;
          font-weight: $fontweight-semibold;
          color: $color-dark;
          margin: 0;
        }

        a {
          font-weight: $fontweight-regular;
          color: $color-orange;
          margin-left: 8px;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      > ul,
      form > ul {
        list-style-type: none;
        margin: 0;
        padding: 0;

        > li {
          padding: 16px 0;

          &:first-child {
            padding-top: 0;
          }
        }
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: row;
    padding: 16px;
    border-top: 1px solid $color-border;

    button {
      font-size: 14px;
      font-weight: 600;
    }

    button:first-of-type {
      &.has-inline-spinner::before {
        background-image: url('/assets/img/icons/spinner.png');
      }

      margin-right: 8px;
    }

    button:last-of-type {
      margin-left: auto;
    }
  }

  .account {
    table {
      width: 66%;
    }
  }
}

@media screen and (max-width: $screen--m-max) {
  .bouwapp-box {
    flex-direction: column;

    &__title {
      width: 100%;
      min-width: 100%;
      padding: 16px;

      h2 {
        font-size: 18px;

        span {
          font-size: 16px;
          line-height: 16px;
        }
      }
    }

    &__self {
      margin: 0;
      padding: 16px;

      .form-split {
        flex-direction: column;

        > div {
          width: 100%;
          min-width: 100%;

          &:first-child {
            margin-bottom: 16px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: $screen--m-min) and (max-width: $screen--l-max) {
  .bouwapp-box {
    &__self {
      .form-split {
        > div {
          width: 100%;
          min-width: 100%;

          &:first-child {
            margin-bottom: 16px;
          }
        }
      }
    }
  }
}

/*------------------------------------*\
  BouwApp - Bottombar
\*------------------------------------*/
.bouwapp-bottombar {
  position: fixed;
  z-index: 202;
  bottom: 0;
  left: 34.4rem;
  right: 1.6rem;
  width: calc(100% - 36rem);
  padding: 16px;
  background: $color-white;
  border: 1px solid rgba($color-grey, 0.6);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.24);
  border-radius: 6px 6px 0 0;
  text-align: right;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  &__creation {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;

    > p {
      width: 20%;
      font-size: 16px;
      line-height: 24px;
      font-weight: $fontweight-bold;
      color: $color-dark;
      margin: 0;
      text-align: left;

      span {
        display: block;
        line-height: 20px;
        color: $color-grey;
      }
    }

    &__progress {
      width: 50%;

      > p {
        margin: 0;
        font-size: 14px;
        font-weight: $fontweight-bold;
        color: $color-dark;
        text-align: center;

        span {
          color: $color-orange;
        }
      }

      .progress {
        max-width: 400px;
        margin: 0 auto;
        border-radius: 12px;
        background: $defaultBorder;
      }

      .progress-bar {
        background-color: var(--primary);
      }
    }

    &__button {
      display: flex;
      width: 30%;
      justify-content: flex-end;
      flex-direction: row;

      button {
        margin-left: auto;
      }
    }
  }

  > .button {
    margin-left: 16px;
  }
}

@media screen and (max-width: $screen--l-max) {
  .general.is-concept {
    padding: 40px 0 120px 0;

    div.bouwapp-bottombar {
      left: 0;
      right: 0;
      width: 100%;
      border-right: 0;

      &__creation {
        > p {
          width: 50%;
          min-width: 50%;
          order: 2;
        }

        &__progress {
          order: 1;
          width: 100%;
          min-width: 100%;
          margin-bottom: 16px;
        }

        &__button {
          order: 3;
          width: 50%;
          min-width: 50%;
          flex-direction: column;

          .button--ghost {
            padding-right: 0;
          }
        }
      }
    }
  }
}

/*------------------------------------*\
  BouwApp - CropBox
\*------------------------------------*/
.bouwapp-cropBox {
  &__overlay {
    position: fixed;
    z-index: 2000;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba($color-black, 0.3);
  }

  &__content {
    position: fixed;
    z-index: 2001;
    left: 50%;
    top: 50%;
    max-width: 1024px;
    width: 100%;
    transform: translate(-50%, -50%);
    background: $color-white;
    border: 1px solid $color-border;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
    border-radius: 6px;

    &__head {
      position: relative;
      padding: 16px 24px;
      border-bottom: 1px solid $color-border;

      > p {
        font-size: 18px;
        font-weight: $fontweight-bold;
        color: $color-dark;
      }

      &__close {
        position: absolute;
        top: 11px;
        right: 11px;
        width: 40px;
        height: 40px;
        background: $color-white;
        padding: 13px;
        cursor: pointer;

        svg {
          width: 14px;
          height: 14px;
          transition: 260ms ease;
        }

        &:hover {
          svg {
            transform: rotate(180deg);
          }
        }
      }
    }

    &__editor {
      position: relative;
      padding: 16px;
      min-height: 554px;
      max-height: 554px;

      > .spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 100;
      }

      &__area {
        position: relative;
        width: 100%;
        height: 522px;
      }
    }

    &__footer {
      position: relative;
      padding: 16px;
      border-top: 1px solid $color-border;
      display: flex;
      flex-direction: row;
      align-items: center;

      button {
        &:not(:first-child) {
          margin-left: 16px;
        }

        &:first-child {
          margin-right: auto;
        }
      }

      &.disabled {
        pointer-events: none;

        .button {
          opacity: 0.5;
        }
      }
    }
  }
}

@media screen and (max-width: $screen--m-max) {
  .bouwapp-cropBox {
    &__overlay {
      display: none;
    }

    &__content {
      position: fixed;
      z-index: 2001;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100vh;
      margin: 0;
      border-radius: 0;
      border: 0;
      transform: unset;

      &__editor {
        min-height: calc(100% - 136px);
        max-height: calc(100% - 136px);

        &__area {
          height: 580px;
        }
      }

      &__footer {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        justify-content: flex-end;

        button {
          &:first-child {
            display: none;
          }
        }
      }
    }
  }
}

@media screen and (min-width: $screen--m-min) and (max-width: $screen--l-max) {
  .bouwapp-cropBox {
    &__content {
      top: 40px;
      right: 40px;
      left: 40px;
      max-width: calc(100% - 80px);
      width: calc(100% - 80px);
      margin: 0;
      transform: unset;
    }
  }
}
