/*------------------------------------*\
    BouwApp - Article
\*------------------------------------*/
.article-detail {
  &.resident-letters {
    .btn {
      margin-bottom: 12px;
    }

    .article-detail__image {
      height: unset;
      border-radius: unset;
      box-shadow: unset;
      margin: 12px 0;
      text-align: center;

      img {
        height: 100px;
        width: 250px;
        display: inline-block;
        background-color: white;
        padding: 10px;
        margin: 12px;
        object-fit: contain;

        &:first-of-type {
          padding: 20px;
        }
      }
    }
  }

  &__inner {
    max-width: 772px;
    margin: 0 auto;
    padding-top: 24px;
  }

  &__action {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 24px;

    > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }

      span:first-child {
        width: 24px;
        height: 24px;
        display: inline-block;
        margin-right: 4px;

        svg {
          width: 24px;
          height: 24px;
        }
      }

      span:last-child {
        font-size: 16px;
        line-height: 24px;
      }
    }

    > div:first-child {
      color: $color-black;

      span:last-child {
        font-weight: $fontweight-bold;
        color: $color-black;
      }
    }

    > div:last-child {
      margin-left: auto;
      color: $color-orange;

      span:first-child {
        svg {
          path {
            fill: $color-orange;
          }
        }
      }

      span:last-child {
        font-weight: $fontweight-regular;
        color: $color-orange;
      }
    }
  }

  &__tag {
    font-weight: $fontweight-bold;
    font-size: 15px;
    line-height: 24px;
    color: $color-white;
    padding: 1px 10px;
    background: $color-grey;
    border-radius: 6px;
    display: inline-block;
    margin: 8px 0 12px 0;
  }

  &__head {
    h1 {
      font-weight: $fontweight-bold;
      font-size: 32px;
      line-height: 42px;
      color: $color-black;
      margin: 0;
    }

    > p {
      font-weight: $fontweight-regular;
      font-size: 16px;
      line-height: 27px;
      color: $color-grey;
      margin: 0 0 16px 0;
    }
  }

  &__image {
    width: 100%;
    height: 200px;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba($color-black, 0.16);
    margin-bottom: 24px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
  }

  &__content {
    font-weight: $fontweight-medium;
    font-size: 16px;
    line-height: 27px;
    color: rgba($color-dark, 0.8);

    p {
      margin-bottom: 24px;
    }

    h2 {
      font-size: 24px;
    }

    h3,
    h4,
    h5 {
      font-size: 20px;
    }

    a {
      font-weight: $fontweight-bold;
      color: $color-orange;
    }
  }
}

@media screen and (max-width: $screen--m-max) {
  .article-detail {
    &__inner {
      padding-top: 12px;
    }

    &__action {
      margin-top: 16px;

      > div:last-child {
        width: 32px;
        height: 32px;
        border: 1px solid $color-orange;
        border-radius: 6px;
        padding: 5px 5px 0 5px;

        &.isFavorited {
          background: $color-orange;

          svg path {
            fill: $color-white !important;
          }
        }

        span:first-child {
          margin: 0;
          text-align: center;

          svg {
            width: 20px;
            height: 20px;
          }
        }

        span:last-child {
          display: none;
        }
      }
    }

    &__tag {
      margin: 8px 0 12px 0;
      font-size: 14px;
    }

    &__head {
      h1 {
        font-size: 26px;
        line-height: 36px;
      }
    }

    &__image {
      height: 160px;
    }

    &__content {
      font-size: 15px;
      line-height: 24px;

      p {
        margin-bottom: 16px;
      }

      h2 {
        font-size: 20px;
      }

      h3,
      h4,
      h5 {
        font-size: 18px;
      }
    }
  }
}
