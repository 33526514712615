/*------------------------------------*\
    BouwApp - News
\*------------------------------------*/
.news {
  .filter {
    &__label {
      margin-bottom: 20px;
    }

    &__wrapper {
      padding: 1.6rem 2.4rem;
      background-color: $color-white;
      border-radius: 12px;
      border: 1px solid $color-border;

      .checkboxes {
        display: flex;
        .checkbox {
          padding-right: 0.6rem;
        }
      }
    }
  }
  &__head {
    h1 {
      font-size: 40px;
      font-weight: $fontweight-bold;
      margin: 0;
    }

    p {
      width: 50%;
      font-weight: $fontweight-medium;
      font-size: 16px;
      line-height: 24px;
      color: $color-dark;
      margin-bottom: 40px;
    }
  }

  &__noResults {
    padding-top: 2.4rem;
    text-align: center;
  }

  &__overview {
    display: flex;
    flex-direction: column;
    padding-top: 2.4rem;

    > p {
      font-weight: $fontweight-bold;
      font-size: 16px;
      line-height: 27px;
      color: $color-dark;
      margin: 0 0 8px 8px;
    }

    &__items {
      width: calc(100% + 24px);
      min-width: calc(100% + 24px);
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 0;
      margin: 0 -12px;
      list-style-type: none;

      .article {
        position: relative;
        width: calc(25% - 24px);
        min-width: calc(25% - 24px);
        margin: 0 12px 24px 12px;
        background: $color-white;
        border-radius: 6px;
        border: 1px solid $defaultBorder;
        padding: 8px;
        transition: 260ms ease;
        cursor: pointer;
        box-shadow: 0 2px 4px 0 rgba($color-black, 0.08);

        &:hover {
          box-shadow: 0 2px 4px 0 rgba($color-black, 0.12);
          border-color: $areaMarkerBorder;
        }

        &__image {
          position: relative;
          z-index: 1;
          width: 100%;
          height: 160px;
          border-radius: 4px;
          border: 1px solid $defaultBorder;
          background-size: cover;
          background-repeat: no-repeat;
          overflow: hidden;

          &.noImage {
            background: rgba($color-grey, 0.3);
          }
        }

        &__tag {
          position: absolute;
          z-index: 2;
          top: 16px;
          left: 16px;
          font-weight: $fontweight-bold;
          font-size: 15px;
          line-height: 24px;
          color: $color-white;
          padding: 1px 10px;
          background: $color-grey;
          border-radius: 6px;
        }

        &__favorite {
          position: absolute;
          z-index: 3;
          display: block;
          top: 16px;
          right: 16px;
          width: 32px;
          max-width: 32px;
          height: 32px;
          color: $color-white;
          background: transparent;
          text-align: center;
          border-radius: 4px;
          border: 1px solid $color-white;
          padding: 5px 0 0 0;
          margin: 0;
          box-shadow: none;
          transition: 260ms ease;
          cursor: pointer;

          svg {
            width: 20px;
            height: 20px;

            path {
              fill: $color-white;
              transition: 260ms ease;
            }
          }

          &.isFavorited {
            background: $color-orange;
            border-color: $color-orange;

            &:hover {
              svg path {
                fill: $color-white;
              }
            }
          }

          &:hover {
            border-color: $color-orange;

            svg path {
              fill: $color-orange;
            }
          }
        }

        &__content {
          padding: 12px 8px 4px 8px;

          > h3 {
            font-weight: $fontweight-semibold;
            font-size: 16px;
            line-height: 20px;
            color: $color-dark;
            max-width: 100%;
            max-height: 40px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 0 0 4px 0;
          }

          > span {
            font-weight: $fontweight-regular;
            font-size: 14px;
            line-height: 23px;
            color: $color-grey;
          }

          > .icon {
            position: absolute;
            right: 12px;
            bottom: 8px;
            width: 24px;
            height: 24px;

            &.read {
              svg {
                polygon {
                  fill: $color-read;
                }
              }
            }

            &.unread {
              svg {
                polygon {
                  fill: $color-grey-light;
                }
              }
            }

            svg {
              width: 24px;
              height: 24px;
            }
          }
        }
      }
    }

    &.related {
      padding-top: 0;

      > div {
        padding-top: 64px;
        margin-top: 64px;
        border-top: 1px solid $separator;

        > p {
          font-weight: $fontweight-bold;
          font-size: 24px;
          color: $color-black;
          margin-bottom: 16px;
        }
      }
    }
  }
}

@media screen and (max-width: $screen--m-max) {
  .news {
    &__head {
      h1 {
        font-size: 32px;
      }

      p {
        width: 100%;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 24px;
      }
    }

    &__toolbar {
      padding: 16px 16px 8px 16px;

      > div {
        > label {
          font-size: 14px;
          line-height: 22px;
        }

        input[type='search'] {
          height: 44px;
          font-size: 15px;
        }

        select {
          height: 44px;
          font-size: 15px;
        }

        &.search {
          width: 100%;
          min-width: 100%;
        }

        &.sort {
          width: 100%;
          min-width: 100%;
          margin-top: 16px;
        }

        &.category {
          > .checkboxes {
            > .checkbox {
              margin: 0 16px 8px 0;

              &:not(:last-child) {
                margin: 0 16px 8px 0;
              }
            }
          }
        }
      }
    }

    &__overview {
      &__items {
        margin: 0;
        width: 100%;
        min-width: 100%;

        .article {
          width: 100%;
          min-width: 100%;
          margin: 0 0 16px 0;
        }
      }

      &.related {
        padding-top: 0;

        > div {
          padding-top: 32px;
          margin-top: 32px;

          > p {
            font-size: 18px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: $screen--m-min) and (max-width: $screen--l-max) {
  .news {
    &__head {
      p {
        width: 80%;
      }
    }

    &__overview {
      &__items {
        .article {
          width: calc(50% - 24px);
          min-width: calc(50% - 24px);
        }
      }
    }
  }
}
