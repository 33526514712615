@mixin shadow($top, $left, $blur, $inset: false, $opacity: 0.12) {
  @if $inset {
    -webkit-box-shadow: inset $top $left $blur rgba(0, 0, 0, $opacity);
    -moz-box-shadow: inset $top $left $blur rgba(0, 0, 0, $opacity);
    box-shadow: inset $top $left $blur rgba(0, 0, 0, $opacity);
  } @else {
    -webkit-box-shadow: $top $left $blur rgba(0, 0, 0, $opacity);
    -moz-box-shadow: $top $left $blur rgba(0, 0, 0, $opacity);
    box-shadow: $top $left $blur rgba(0, 0, 0, $opacity);
  }
}
