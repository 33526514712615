.customer-picker {
  display: flex;
  justify-content: center;
  height: 100%;

  &__button {
    margin-left: 1.6rem;
    color: $color-grey;
    display: flex;
    align-items: center;
    gap: 0.8rem;

    > span {
      font-size: 1.4rem;
      gap: 0.8rem;

      svg {
        fill: $color-grey;
        display: block;
      }
    }

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
