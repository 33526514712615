/*------------------------------------*\
    Projects - Targets
\*------------------------------------*/
.targets {
  &__group {
    max-width: 800px;

    &__edit {
      color: $primaryColor;
      margin-left: 5px;
      cursor: pointer;
    }

    &__top {
      margin-bottom: 24px;

      > p {
        font-size: 16px;
        font-weight: normal;
        color: #a3a3a3;
        margin: 0 0 16px 0;
      }

      > button {
        margin-left: auto;
        margin-bottom: 20px;
        margin-top: -60px;
      }
    }

    > ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      > li {
        width: 100%;
        padding: 12px 16px 12px 24px;
        display: flex;
        flex-direction: row;
        align-items: center;
        user-select: none;
        height: 5.6rem;
        margin: 0.8rem 0;

        &.empty {
          padding: 16px 16px 16px 24px;
        }

        span.draggable {
          display: inline-block;
          margin: 0 1.6rem;
          height: 48px;
          background: $color-white;
          text-align: center;
          padding-top: 15px;
          cursor: grab;

          svg {
            width: 14px;
            height: 22px;

            path {
              fill: $color-grey-light;
              transition: 260ms ease;
            }
          }

          &:hover {
            svg path {
              fill: $color-dark;
            }
          }
        }

        p {
          padding: 12px 16px 12px 24px;
          border: 1px solid $color-border;
          border-radius: 8px;
          font-size: 16px;
          line-height: 24px;
          font-weight: $fontweight-medium;
          color: $primaryTextColor;
          width: 100%;
          margin: 0;
        }

        > div {
          > div {
            width: 40px;
            min-width: 40px;
            height: 40px;
            padding: 10px;
            margin-left: 4px;
            cursor: pointer;

            svg path {
              fill: $color-grey !important;
              transition: 260ms ease;
            }

            &.tile-edit:hover {
              svg path {
                fill: $color-blue !important;
              }
            }

            &.tile-delete {
              svg path {
                fill: $color-red !important;
              }
            }

            &.tile-delete:hover {
              svg path {
                fill: $color-dark !important;
              }
            }
          }
        }

        &.isCustom {
          padding: 0;

          > div {
            display: flex;
            flex-direction: row;
          }

          &.noDrag {
            padding: 12px 16px 12px 24px;
          }
        }
      }
    }
  }

  ~ .isCustom.gu-mirror {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px 16px 6px 6px;
    background: $color-white;
    border: 1px solid $color-border;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    cursor: grabbing;

    span.draggable {
      display: inline-block;
      min-width: 48px;
      height: 48px;
      background: $color-white;
      text-align: center;
      padding-top: 15px;
      margin-right: 8px;
      cursor: grabbing;

      svg {
        width: 10px;
        height: 16px;

        path {
          fill: $color-dark;
        }
      }
    }

    p {
      font-size: 16px;
      line-height: 24px;
      font-weight: $fontweight-medium;
      color: $color-grey;
    }
  }
}

@media screen and (max-width: $screen--m-max) {
  .targets {
    &__group {
      &__top {
        > button {
          margin-left: unset;
          margin-top: unset;
        }
      }
    }
  }
}
