.avatar {
  width: 4rem;
  min-width: 4rem;
  height: 4rem;
  border-radius: 0.6rem;

  position: relative;
  text-align: center;
  font-size: 2rem;
  line-height: 4rem;
  color: $color-white;
  overflow: hidden;

  img {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &__background {
    &__a {
      background: #d98880;
    }
    &__b {
      background: #d35400;
    }
    &__c {
      background: #ca6f1e;
    }
    &__d {
      background: #a04000;
    }
    &__e {
      background: #b9770e;
    }
    &__f {
      background: #117864;
    }
    &__g {
      background: #148f77;
    }
    &__h {
      background: #1f618d;
    }
    &__i {
      background: #1a5276;
    }
    &__j {
      background: #6c3483;
    }
    &__k {
      background: #76448a;
    }
    &__l {
      background: #633974;
    }
    &__m {
      background: #4a235a;
    }
    &__n {
      background: #512e5f;
    }
    &__o {
      background: #154360;
    }
    &__p {
      background: #0e6251;
    }
    &__q {
      background: #145a32;
    }
    &__r {
      background: #196f3d;
    }
    &__s {
      background: #a67c52;
    }
    &__t {
      background: #915b3c;
    }
    &__u {
      background: #873600;
    }
    &__v {
      background: #6e2c00;
    }
    &__w {
      background: #5b2c6f;
    }
    &__x {
      background: #283747;
    }
    &__y {
      background: #1c2833;
    }
    &__z {
      background: #212f3d;
    }
  }
}
