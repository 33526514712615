section.projects.invite {
  .invited {
    .title {
      font-size: 20px;
      font-weight: $semiBoldFontWeight;
      color: $primaryTextColor;
    }

    ul {
      padding: 0;
      margin: 5px 0;
      list-style-type: none;

      li {
        padding: 4px 0;
        .icon {
          cursor: pointer;
          float: right;

          &,
          svg {
            height: 20px;
          }
        }
      }
    }
  }
}
