@mixin counter {
  background: $color-orange;
  color: $color-white;
  transition: 260ms ease;
  margin-left: auto;
  min-width: 2.4rem;
  height: 2.4rem;
  line-height: 2.4rem;
  text-align: center;
  border-radius: 0.6rem;
  font-size: 1.4rem;
  padding: 0 0.4rem;
}

/*------------------------------------*\
    Header
\*------------------------------------*/
.app {
  &__menu {
    &__project-switch {
      color: $color-grey;
      padding-top: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.8rem;

      > span {
        font-size: 1.4rem;
        gap: 0.8rem;

        svg {
          fill: $color-grey;
          display: block;
        }
      }

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }

    &__overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 999;
      transition: left 0.3s ease;
      display: none;

      &.slideIn {
        display: block;
      }
    }
  }

  &__toggle {
    cursor: pointer;
    position: fixed;
    z-index: 20000;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    background: $color-white;
    display: none;
    transition: 320ms ease;

    &__handle {
      position: absolute;
      top: 50%;
      margin-top: -2rem;
      width: 4rem;
      height: 5.6rem;
      background: $color-white;
      box-shadow: 2px 0px 5px 2px rgba($color-black, 0.3);
      border-radius: 0 1.2rem 1.2rem 0;
      padding: 1.6rem 0.8rem;
    }

    span {
      &:last-child {
        display: none;
      }

      svg {
        transition: 260ms ease;
        fill: $color-dark;
      }
    }

    &.slideIn {
      left: calc(100% - 4.9rem);
      background: none;

      .app__toggle__handle {
        box-shadow: 4px 0px 5px 0px rgba($color-black, 0.3);
      }

      span {
        &:first-child {
          display: none;
        }

        &:last-child {
          display: block;
        }
      }
    }

    &:not(.slideIn) {
      .app__toggle__handle:hover {
        svg {
          transform: rotate(90deg);
        }
      }
    }
  }

  &__logo {
    a {
      display: block;
      text-align: center;
    }

    img {
      max-width: 18rem;
      height: auto;
    }
  }

  &__twofactor {
    margin: 3.2rem 0 0 0;
    width: 100%;

    a {
      display: block;
      font-size: 1.3rem;
      line-height: 2rem;
      color: $color-white;
      background: $color-orange;
      width: 100%;
      padding: 0.8rem 1.2rem;
      border-radius: 1.2rem;
      text-align: center;
    }

    + .app__navigation {
      margin-top: 3.2rem;
    }
  }

  &__navigation {
    list-style-type: none;
    margin: 3.2rem 0 1rem 0;
    padding: 0;

    li {
      list-style-type: none;
      margin: 0 0 0.8rem 0;
      padding: 0;

      .sub-menu {
        display: none;
        margin: 0;
        padding: 0;

        li {
          a {
            padding-left: 3.2rem;

            span:first-child {
              width: 2.2rem;
              height: 2.2rem;
              margin-right: 1.2rem;

              svg {
                fill: $color-dark;
                width: 2.2rem;
                height: 2.2rem;
              }
            }

            &.active,
            &:hover {
              span:first-child {
                svg {
                  fill: $color-white;
                }
              }
            }
          }

          &:first-child {
            margin-top: 0.8rem;
          }
        }

        &.open {
          display: block;
        }
      }

      a {
        display: flex;
        width: 100%;
        padding: 1.1rem 1.6rem;
        font-weight: $fontweight-medium;
        font-size: 1.8rem;
        line-height: 3rem;
        color: $color-dark;
        border-radius: 1.2rem;
        transition: 260ms ease;
        align-items: center;

        &.disabled:hover {
          cursor: not-allowed;
        }

        span {
          &:first-child {
            width: 2.4rem;
            height: 2.4rem;
            margin-right: 1.6rem;

            svg {
              width: 2.4rem;
              height: 2.4rem;
            }
          }

          &.count {
            @include counter;
          }

          svg {
            fill: $color-dark;
          }
        }

        &:hover,
        &.active {
          background: $color-dark;
          color: $color-white;

          span:not(:nth-child(3)) {
            color: $color-white;

            svg {
              fill: $color-white;
            }
          }
        }
      }
    }
  }

  &__user {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 2.4rem;
    background-color: $color-white;
    width: 32.8rem;
    min-width: 32.8rem;

    &__self {
      display: flex;
      flex-direction: row;
      align-items: center;
      background: $color-white;
      padding: 0.4rem;
      border-radius: 0.8rem;
      transition: 260ms ease;
      cursor: pointer;
      max-width: 28rem;
      margin-top: 0.8rem;
    }

    &__toggle {
      width: 4rem;
      min-width: 4rem;
      height: 4rem;
      border-radius: 0.6rem;
    }

    &__text {
      > span {
        display: flex;
        flex-direction: column;
        font-weight: $fontweight-regular;
        font-size: 1.6rem;
        color: $color-dark;
        padding: 0 1.6rem;
        overflow: hidden;
      }
      &__small {
        word-wrap: break-word;
        word-break: break-word;
        font-weight: $fontweight-regular;
        font-size: 1.4rem;
        color: rgba($color-dark, 0.5);
      }
    }

    &__toggle {
      margin-left: auto;
      padding: 0.8rem;
      position: relative;
      border: 0.1rem solid transparent;

      &:hover {
        border: 0.1rem solid $color-border;
        cursor: pointer;
      }

      span.count {
        @include counter;
        position: absolute;
        top: -8px;
        right: -3px;
      }

      svg {
        fill: $color-dark;
      }
    }

    &__menu.active {
      opacity: 1;
      pointer-events: auto;
    }

    &__menu {
      position: absolute;
      bottom: 8.6rem;
      left: 2.4rem;
      margin-bottom: 15px;
      width: calc(32.8rem - 4.8rem);
      height: auto;
      background: $color-white;
      border-radius: 1.2rem;
      padding: 1.6rem;
      border: 0.1rem solid $color-border;
      opacity: 0;
      pointer-events: none;
      transition: 260ms ease;

      > div {
        display: flex;
        flex-direction: column;

        span {
          display: block;
        }

        span:first-child {
          font-weight: $fontweight-medium;
          font-size: 1.6rem;
          color: $color-dark;
        }

        span:nth-child(2) {
          font-weight: $fontweight-regular;
          font-size: 1.4rem;
          color: $color-dark;
        }

        span:last-child {
          cursor: pointer;
          font-weight: $fontweight-regular;
          font-size: 1.4rem;
          color: rgba($color-dark, 0.5);
        }
      }

      ul {
        list-style-type: none;
        margin: 1.6rem 0 0 0;
        padding: 0;

        li {
          list-style-type: none;
          margin: 0;
          padding: 0;
          display: flex;
          flex-direction: row;
          align-items: center;

          &:not(:last-child) {
            margin-bottom: 0.6rem;
          }

          a {
            color: $color-dark;

            &:hover {
              text-decoration: underline;
            }
          }

          span svg {
            fill: $color-dark;
          }

          span:first-child {
            display: inline-block;
            width: 1.2rem;
            height: 1.2rem;
          }

          span:last-child {
            margin-left: 0.4rem;
            font-weight: $fontweight-regular;
            font-size: 1.4rem;
            line-height: 2rem;
            color: $color-dark;
          }

          &:last-child {
            a {
              color: $color-red;
            }

            span:last-child {
              color: $color-red;
            }

            svg path {
              fill: $color-red;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $screen--l-max) {
  .app {
    &__toggle {
      display: block;
    }

    &__user {
      position: sticky;
      width: 100%;
      min-width: 100%;
      border: none;
      padding: 2.4rem 0;
      margin-top: auto;

      &__menu {
        width: 100%;
        min-width: 100%;
        left: 0;
      }
    }
  }
}
