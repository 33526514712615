.multi-attachment {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  label {
    width: 100%;
    justify-self: start;
  }

  &__form {
    width: 50%;

    textarea {
      padding: 10px 12px;
    }
  }

  &__buttons {
    padding-top: 16px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;

    button {
      margin: 0 5px 3px;
    }

    &__right {
      margin-left: auto;
    }
  }

  &__media {
    position: relative;
    width: 50%;

    .cover {
      position: relative;
      width: 100%;
      height: 360px;
      border-radius: 5px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      border: 1px solid $color-border;

      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 4px;
      }

      .multi-attachment__icon {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba($color-dark, 0.5);
        text-align: center;
        padding-top: 80px;
      }
    }

    .form-group .errors {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 12px;
      text-align: center;
    }

    .progress {
      width: 100%;
      position: absolute;
      bottom: 0;
      margin: 0;
      border-radius: 0 0 4px 4px;
    }
  }

  &__group {
    display: flex;
    flex-direction: row;
  }

  &__list,
  &__preview {
    width: 100%;

    > span {
      font-size: 16px;
      font-weight: $fontweight-semibold;
      color: $color-dark;
      margin-bottom: 4px;
      display: block;
    }
  }

  &__list {
    .list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin: -6px;

      .file {
        position: relative;
        margin: 6px;
        border-radius: 6px;
        cursor: pointer;
        transition: 260ms ease;

        &.pdf {
          .file__image {
            background-image: url('/assets/img/icons/pdf.jpeg') !important;
            background-size: auto 70%;
          }
        }

        &:hover {
          .file__drag,
          .file__edit,
          .file__remove {
            opacity: 1;
          }

          .file__video {
            display: none;
          }

          .file__overlay {
            background-color: rgba(0, 0, 0, 0.5);
          }
        }

        &__error {
          width: 12rem;
          height: 12rem;
          border-radius: 8px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border: 1px solid $color-border;
          padding: 4px;
          position: relative;
          overflow: hidden;

          > span {
            width: 100%;
            text-align: center;

            &:first-of-type {
              height: 2rem;
              margin-bottom: 0.4rem;

              svg {
                width: 2rem;
                height: 2rem;
              }
            }

            &:last-of-type {
              max-height: 9.6rem;
              font-size: 1.2rem;
              overflow: hidden;
            }
          }
        }

        &__image {
          width: 12rem;
          height: 12rem;
          border-radius: 8px;
          overflow: hidden;
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          border: 1px solid $color-border;
          position: relative;

          video {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          &.new {
            margin-bottom: 2.4rem;
            border: none;
          }
        }

        &__overlay {
          width: 100%;
          height: 100%;
          position: relative;
          transition: 0.25s all ease-out;
          position: absolute;
          top: 0;
          left: 0;

          &.uploading {
            background-color: rgba(0, 0, 0, 0.5);
          }
        }

        &__spinner {
          display: flex;
          height: 100%;
          width: 100%;
          align-items: center;
          justify-content: center;

          .spinner {
            margin: 0;
            background-image: url('/assets/img/icons/spinner-white.png');
          }
        }

        &__edit,
        &__remove {
          opacity: 0;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          transition: 0.25s opacity ease;
          width: 2.4rem;
          height: 2.4rem;

          svg {
            width: 100%;
            height: 100%;

            path {
              fill: $color-white;
            }
          }
        }

        &__360 {
          svg {
            background: rgba(0, 0, 0, 0.3);
            padding: 5px;
            right: 0;
            width: 100%;
          }
        }

        &__video {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          height: 2.4rem;
          width: 3.2rem;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }

          svg {
            width: 2.4rem;
            height: 2.4rem;
            background-color: $color-white;
            border-radius: 2px;
            margin-left: 0.4rem;
          }
        }

        &__drag {
          opacity: 0;
          width: 100%;
          height: 24px;
          text-align: center;
          padding-top: 7px;
          cursor: grab;
          transition: 0.25s opacity ease;

          svg {
            width: 10px;
            height: 16px;
            transform: rotate(90deg);
          }
        }
      }
    }

    .button--yt {
      margin-bottom: 1.6rem;
      margin-top: 0.8rem;
      font-size: 1.4rem;

      &:hover {
        opacity: 0.7;
      }

      span,
      img {
        height: 2rem;
        line-height: 2rem;
        display: inline-block;
        vertical-align: middle;
        margin-right: 0.8rem;
      }
    }

    .sort-error {
      margin-top: 16px;
      font-size: 16px;
      font-weight: $fontweight-bold;
      text-align: center;
      color: $color-red;
    }

    .no-files {
      margin-top: 16px;
      font-size: 16px;
      font-weight: $fontweight-bold;
      text-align: center;
      color: $color-grey;
    }
  }

  &__control-holder {
    display: flex;
    flex-direction: column;
    margin-right: 16px;
  }

  &__360-option {
    display: flex;
    flex-direction: column;
    margin-top: 16px;

    .toggle {
      margin-top: 16px;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: $screen--l-max) {
  .multi-attachment {
    &__group {
      flex-direction: column;
    }

    &__list,
    &__click,
    &__form,
    &__preview {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
    }
  }
}

@media screen and (max-width: $screen--m-max) {
  .multi-attachment {
    &__click {
      .input-file {
        > div {
          flex-direction: column;
          padding: 40px 16px 16px 16px;
        }

        &__left {
          width: 100%;
          min-width: 100%;
        }

        &__right {
          width: 100%;
          min-width: 100%;
          margin-top: 24px;

          .input-file-instruction {
            max-width: 80%;
            margin: 0 auto;
            text-align: center;

            .input-file-video {
              text-align: center;
            }
          }
        }
      }
    }

    &__list {
      .list {
        .file {
          max-width: calc(50% - 12px);
        }
      }
    }
  }
}

.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  display: block;
  opacity: 0.8;
  cursor: grabbing;

  .file {
    position: relative;
    margin: 6px;
    width: 100%;
    max-width: 168px;
    border: 1px solid $color-border;
    border-radius: 6px;
    padding: 8px;
    cursor: pointer;

    &:hover {
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
      border-color: $color-grey;
    }

    &__image {
      width: 100%;
      height: 82px;
      border-radius: 4px;
      overflow: hidden;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }

    &__drag {
      width: 100%;
      height: 24px;
      text-align: center;
      padding-top: 7px;
      cursor: grab;

      svg {
        width: 10px;
        height: 16px;
        transform: rotate(90deg);
      }
    }
  }
}

.gu-hide {
  left: -9999px !important;
}

.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.gu-transit {
  cursor: grabbing;
  opacity: 0.6;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=20)';
  filter: alpha(opacity=20);
}
