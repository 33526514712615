.inline-svg__item {
  display: inline-block;
}

.inline-svg__fill-primary {
  fill: var(--primary) !important;
}

.inline-svg__stroke-primary {
  stroke: var(--primary) !important;
}
