section.content {
  margin-top: 60px;

  .holder {
    margin-top: 15px;
    p {
      margin: 8px 0 35px 0;
    }

    .image {
      @include round(3px);

      width: 100%;
      background-size: cover;
      height: 390px;
      margin-top: 15px;
    }
  }
}
