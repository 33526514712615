.loader {
  width: 100%;
  text-align: center;
  font-weight: $semiBoldFontWeight;
  padding: 40px 0;
}

.loader--condensed {
  padding: 5px 0;
  margin-top: 0;
}

.spinner {
  display: inline-block;
  width: 32px;
  height: 32px;
  vertical-align: middle;
  margin-right: 10px;
  background-image: url('/assets/img/icons/spinner.png');
  animation: loader 1.5s ease-in-out infinite;

  @keyframes loader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  &[hidden] {
    display: none;
  }
}

.has-inline-spinner {
  &::before {
    @extend .spinner;

    background-image: url('/assets/img/icons/spinner-white.png');

    content: ' ';
    height: 14px;
    width: 14px;
    background-size: 100%;
  }
  &.dark {
    &::before {
      background-image: url('/assets/img/icons/spinner.png');
    }
  }
}
