$toggleHeight: 40px;
$toggleWidth: 70px;
$toggleBorderWidth: 3px;

$toggleHeightSmall: 28px;
$toggleWidthSmall: 50px;

.toggle {
  display: block;
  width: $toggleWidth;
  height: $toggleHeight;
  margin-bottom: 5px;

  @include shadow(0, 1px, 3px, true);
  @include round($toggleHeight / 2);

  background: $toggleBackground;
  position: relative;
  transition: 0.4s;

  &::after {
    position: absolute;
    height: $toggleHeight - 2 * $toggleBorderWidth;
    width: $toggleHeight - 2 * $toggleBorderWidth;
    display: block;
    background-color: $white;
    content: ' ';

    @include round(50%);

    left: $toggleBorderWidth;
    top: $toggleBorderWidth;
    cursor: pointer;
    transition: 0.4s;
  }

  &.toggle-active {
    background-color: var(--primary);

    &::after {
      left: $toggleWidth - $toggleHeight + $toggleBorderWidth;
    }
  }
  &.toggle-disaled {
    opacity: 0.66;
  }
}

.toggle--small {
  display: block;
  width: $toggleWidthSmall;
  height: $toggleHeightSmall;

  @include round($toggleHeightSmall / 2);

  &::after {
    height: $toggleHeightSmall - 2 * $toggleBorderWidth;
    width: $toggleHeightSmall - 2 * $toggleBorderWidth;
  }

  &.toggle-active {
    &::after {
      left: $toggleWidthSmall - $toggleHeightSmall + $toggleBorderWidth;
    }
  }
}
