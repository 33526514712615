$lightFontWeight: 300;
$regularFontWeight: 400;
$mediumFontWeight: 500;
$semiBoldFontWeight: 600;
$boldFontWeight: 700;

$fontweight-light: 300;
$fontweight-regular: normal;
$fontweight-medium: 500;
$fontweight-semibold: 600;
$fontweight-bold: bold;
