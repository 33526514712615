.qr-container {
  display: flex;

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    .qr-buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1.1rem;
    }
  }

  .qrcode {
    display: flex;
  }

  @media screen and (max-width: $screen--s) {
    flex-direction: column;
    align-items: center;

    .qr-description{
      margin-bottom: 24px;
    }
  }
}
