.switch {
  height: auto;
  margin-bottom: 5px;

  /* can't use a full border, as Chrome renders a little bit of white space in the edges for some reason */

  display: flex;
  justify-content: center;
  align-items: center;

  .switch-option {
    flex: 1;
    font-size: 15px;
    cursor: pointer;
    text-align: center;
    font-weight: $semiBoldFontWeight;
    color: var(--primary);

    overflow: hidden;

    line-height: 36px; /* use line height because of different borders */

    border-color: var(--primary);
    border-style: solid;
    border-width: 2px 0;
    border-right-width: 2px;

    &.active {
      border: 0;
      cursor: default;
      color: $white;
      line-height: 40px;
      background-color: var(--primary);
    }

    &:first-of-type {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;

      &:not(.active) {
        border-width: 2px;
      }
    }

    &:last-of-type {
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      border-right-width: 0;

      &:not(.active) {
        border-width: 2px 2px 2px 0;
      }
    }
  }

  &__range {
    color: $color-black;
    &__dash {
      color: $color-grey;
      text-transform: lowercase;
    }
  }

  &.switch-inline {
    justify-content: flex-start;

    .switch-option {
      width: auto;
      flex: auto;
      flex-grow: 0;
      padding: 0 20px;
    }
  }
}
