.date-picker {
  margin-top: 10px;
  margin-bottom: 10px;

  .date-picker-quick-select {
    margin-bottom: 20px;
  }

  &:not(.date-picker-with-time) {
    .date-picker-quick-select {
      display: flex;
      justify-content: space-between;

      select {
        width: auto;
        margin: 0;
      }

      .date-picker-day,
      .date-picker-year {
        flex: 3;
      }

      .date-picker-month {
        flex: 4;
        margin: 0 10px;
      }
    }
  }

  &.date-picker-with-time {
    .date-picker-time-container {
      display: flex;
      margin-top: 10px;

      .date-picker-quick-select {
        flex: 1;
        margin-right: 5px;

        .date-picker-day {
          flex: 1;
        }

        .date-picker-year {
          flex: 3;
        }

        .date-picker-month {
          flex: 4;
        }
      }

      .date-picker-time-input {
        flex: 1;
        margin-left: 5px;
      }
    }
  }

  .date-picker-choose {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    .date-picker-item {
      flex: 1;
      flex-basis: calc(
        (100% - 6 * 3.5px) / 7
      ); /* 3.5px is a firefox-only fix.. */
      font-size: 22px;
      color: var(--primary);
      text-align: center;
      padding: 16px 0;
      margin-right: 3px;
      margin-bottom: 3px;
      cursor: pointer;

      &:nth-child(7n + 0) {
        margin-right: 0;
      }

      &.active {
        background-color: var(--primary);
        color: $white;
        cursor: default;
      }

      &.disabled {
        color: $disabledPrimaryColor;
        cursor: default;
      }

      &.date-picker-heading {
        background-color: transparent;
        font-size: 14px;
        color: $tertiaryColor;
        padding: 10px 0;
        margin-bottom: 0;
        cursor: default;
      }
    }
  }

  &.date-picker-input-only {
    @extend .input-group !optional;

    width: 100%;
    max-width: 233px;
    display: inline-flex;
    margin: 0 8px;
    vertical-align: middle;
  }
}

body.default .date-picker-item {
  background: $white;
}

body.detail-page .date-picker-item {
  background: $datePickerBackground;
}
