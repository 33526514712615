/*------------------------------------*\
    Tickets (contact)
\*------------------------------------*/
.project-contact {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;

  &__image {
    width: 100%;
    min-width: 144px;

    &__wrapper {
      display: flex;
    }

    &__buttons {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 15px;
      gap: 10px;

      a {
        display: flex;
        align-items: center;
        gap: 10px;

        span {
          font-weight: $fontweight-medium;
          color: rgba($color-dark, 0.5);

          > svg {
            > path {
              fill: rgba($color-dark, 0.5);
            }
          }
        }
      }

      a:hover {
        span {
          color: $color-dark;

          > svg {
            > path {
              fill: $color-dark;
            }
          }
        }
      }
    }

    h3 {
      font-size: 16px;
      line-height: 28px;
      font-weight: $fontweight-semibold;
      color: $color-dark;
      margin: 0 0 8px 0;
    }

    &__self {
      position: relative;
      width: 144px;
      height: 144px;
      background-size: cover;
      border-radius: 6px;
      transition: 260ms all ease;
      background-position: center center;

      &.hasImage {
        .input-file-standalone {
          border-style: solid;
        }
      }

      &:hover {
        .input-file-standalone {
          background-color: rgba(0, 0, 0, 0.4);

          > label {
            svg {
              fill: $color-white;

              path {
                fill: $color-white;
              }
            }
          }
        }
      }

      div.input-file-standalone {
        width: 100%;
        height: 100%;
        border: 2px dashed $color-border;
        border-radius: 6px;
        transition: 0.25s all ease;

        > label {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          padding: 0;
          margin: 0;
          border-radius: 6px;
          background: transparent;
          box-shadow: none;

          &.loading {
            background: rgba($color-grey-dark, 0.5);
            svg {
              display: none;
            }
          }

          .spinner {
            margin: 0;
          }

          svg {
            fill: $color-grey;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            path {
              fill: $color-grey;
            }
          }
        }
      }
    }
  }

  &__first {
    angular-intl-phone {
      > div.iti {
        width: 100%;

        .iti__dropdown-content {
          position: static;
        }
      }
    }
  }

  &__first,
  &__second {
    flex: 1;

    input {
      margin-bottom: 16px !important;
    }
  }

  &__description {
    width: 100%;
    min-width: 100%;
    margin-top: 8px;
  }

  + button {
    margin-top: 24px;
  }
}

@media screen and (max-width: $screen--m-max) {
  .project-contact {
    flex-direction: column;

    &__image {
      margin-bottom: 16px;
    }

    &__first,
    &__second {
      width: 100%;
      min-width: 100%;
    }

    &__description {
      margin-top: 16px;
    }
  }
}

@media screen and (min-width: $screen--m-min) and (max-width: $screen--l-max) {
  .project-contact {
    &__image {
      width: 100%;
      min-width: 100%;
      margin-bottom: 16px;
    }

    &__first,
    &__second {
      width: calc(50% - 8px);
      min-width: calc(50% - 8px);
    }
  }
}

.ticket-modal {
  button {
    margin-left: auto;
  }
}

/*------------------------------------*\
    Tickets (overview)
\*------------------------------------*/
.tickets {
  .app__user__self {
    max-width: 45rem;
  }
  &__head {
    border: 1px solid $color-border;
    border-radius: 1.2rem;
    overflow: hidden;

    .date,
    .time {
      font-size: 1.4rem;
    }

    .date {
      margin-right: 0.4rem;
    }

    .time {
      color: $color-grey;
    }
  }

  &__content {
    width: 100%;
  }

  &__wrapper {
    > p {
      font-weight: bold;
      font-size: 1.6rem;
      line-height: 2.4rem;
      color: $color-dark;
      margin-bottom: 8px;
    }
  }

  $self: &;

  &__title {
    margin: 0;
    font-size: 32px;
  }

  &__toolbar {
    padding: 24px;
    background: $color-grey-box;
    border-radius: 6px;

    > div {
      .flex-row {
        flex-wrap: wrap;
      }

      &:first-child {
        width: 380px;
        min-width: 380px;

        .checkbox {
          margin-top: 15px;

          &:not(:last-child) {
            margin-right: 24px;
          }
        }
      }

      &:last-child {
        width: calc(100% - 400px);
        min-width: calc(100% - 400px);
        margin-left: auto;
      }

      label,
      span {
        display: block;
        font-size: 16px;
        line-height: 26px;
        font-weight: $fontweight-semibold;
        color: $color-dark;
        margin: 0 0 4px 0;
      }

      input[type='search'] {
        width: 100%;
        border-radius: 6px;
        border: 1px solid $color-border;
        box-shadow: none;
        margin: 0;
        height: 50px;
        font-size: 16px;
        transition: 260ms ease;
      }
    }
  }

  &__list {
    ul.head,
    ul.body {
      padding: 0;
      margin: 0;
      list-style-type: none;

      > li {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        padding: 1.2rem 0.8rem;

        &.isCustomer {
          > span {
            width: calc(100% / 6);
          }
        }

        > span {
          overflow: hidden;
          padding: 0.4rem;
          max-height: 6rem;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          width: calc(100% / 5);
          line-clamp: 3;
          overflow: hidden;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;

          &:nth-child(2) {
            display: flex;
            flex-wrap: wrap;
          }

          &:last-child {
            div {
              display: flex;

              a {
                margin-left: auto;
              }
            }
          }
        }
      }
    }

    ul.head {
      li {
        span {
          font-weight: $fontweight-bold;
          font-size: 14px;
          color: $color-black;
        }
      }
      li:first-child {
        background-color: $color-grey-box;
      }
    }

    ul.body {
      li {
        cursor: pointer;
        background: $color-white;
        transition: 260ms ease;
        font-weight: $fontweight-light;
        font-size: 14px;
        color: $color-dark;

        &:not(:last-child) {
          border-bottom: 1px solid $color-border;
        }

        span.label {
          margin: 0;
          font-size: 1.2rem;
          padding: 0.4rem 1.2rem;
          border-radius: 0.4rem;
        }
      }
    }
  }

  &__closed,
  &__pending {
    padding-top: 64px;
    margin-top: 64px;
    border-top: 1px solid $color-grey-light;

    > h2 {
      margin: 0 0 16px 0;
    }
  }
}

@media screen and (min-width: $screen--l-min) and (max-width: $screen--xl) {
  .tickets__list ul.head,
  .tickets__list ul.body {
    > li {
      &.isCustomer {
        > span {
          width: calc(100% / 5);

          &:nth-child(1) {
            width: 100%;
          }
        }
      }
      > span {
        width: calc(100% / 4);

        &:nth-child(1) {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: $screen--m-max) {
  .tickets {
    &__content {
      flex-direction: column;
    }

    &__wrapper {
      width: 100%;
      min-width: 100%;
      padding: 0;

      > p {
        margin-top: 0.8rem;
        text-align: center;
      }
    }

    $self: &;

    &__toolbar {
      flex-direction: column;

      > div {
        &:first-child {
          width: 100%;
          min-width: 100%;
          margin-bottom: 16px;

          .checkbox {
            margin-top: 0;
          }
        }

        &:last-child {
          width: 100%;
          min-width: 100%;
        }
      }
    }

    &__list {
      ul.head,
      ul.body {
        > li {
          padding: 1.6rem;

          > span {
            padding: 0;

            &:nth-child(2) {
              flex-grow: 1;
              justify-content: end;
            }

            &:nth-child(3) {
              padding-top: 0.8rem;
            }

            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(6),
            &:last-child {
              width: 100%;
              min-width: 100%;
              padding-bottom: 0.8rem;

              &:before {
                content: attr(aria-label);
                display: block;
                color: $color-grey;
              }
            }

            &:last-child {
              padding-bottom: 0;
            }
          }
        }
      }

      ul.head {
        display: none;
      }
    }

    &--tabs {
      margin-top: -24px;
    }
  }
}

@media screen and (min-width: $screen--m-min) and (max-width: $screen--l-max) {
  .tickets {
    &__content {
      flex-direction: column;
    }

    &__wrapper {
      width: 100%;
      min-width: 100%;
      padding: 0;

      > p {
        margin-top: 0.8rem;
        text-align: center;
      }
    }

    $self: &;

    &__list {
      ul.head,
      ul.body {
        > li {
          &.isCustomer {
            > span {
              &:nth-child(3),
              &:nth-child(4),
              &:nth-child(5),
              &:nth-child(6),
              &:last-child {
                width: 20%;
                min-width: 20%;
              }
            }
          }

          > span {
            padding: 0.8rem;

            &:nth-child(1) {
              width: unset;
            }

            &:nth-child(2) {
              width: calc(100% - 120px);
              min-width: calc(100% - 120px);
              justify-content: end;
              flex-grow: 1;
            }

            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(6),
            &:last-child {
              width: 33%;
              min-width: 33%;
              flex-grow: 1;

              &:before {
                content: attr(aria-label);
                display: block;
                color: $color-grey;
              }
            }
          }
        }
      }

      ul.head {
        display: none;
      }
    }
  }
}

/*------------------------------------*\
    Tickets (notifications)
\*------------------------------------*/
.ticket-notification {
  > span {
    display: block;
    font-weight: $fontweight-semibold;
    font-size: 16px;
    color: $color-dark;
    margin-bottom: 4px;
  }

  form ul,
  form ul > li {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  form ul > li {
    margin-bottom: 8px;
    display: flex;
    flex-direction: row;

    .input.invalid {
      border-color: $color-red !important;

      &:focus-visible {
        outline-color: $color-red !important;
      }
    }

    > div {
      width: calc(100% - 40px) !important;
      max-width: 480px;
    }

    span {
      display: block;
      cursor: pointer;
      margin: 13px 0 0 16px;
      width: 32px;
      min-width: 32px;
      height: 32px;
      margin-left: 8px;
      text-align: center;
      padding-top: 4px;
      border-radius: 2px;
      transition: 260ms ease;

      svg path {
        fill: $color-grey !important;
        transition: 260ms ease;
      }

      &:hover {
        svg path {
          fill: $color-red !important;
        }
      }
    }
  }

  form > div {
    align-items: center;
    margin-top: 24px;

    span {
      font-size: 16px;
      font-weight: $fontweight-semibold;
      color: $color-grey;
      margin-left: 24px;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

@media screen and (max-width: $screen--m-max) {
  .ticket-notification {
    form > div {
      flex-direction: column;
      align-items: flex-start;

      button {
        order: 2;
      }

      span {
        order: 1;
        margin: 0 0 16px 10px;
      }
    }
  }
}

/*------------------------------------*\
    Tickets (feedback)
\*------------------------------------*/
.ticket-feedback {
  &,
  svg {
    height: 15px;
  }
}

/*------------------------------------*\
    Tickets (email-group)
\*------------------------------------*/
.ticket-email-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 24px 24px 24px;
  background: $color-grey-box;
  border-radius: 0 0 6px 6px;
  margin-bottom: 40px;

  > button {
    width: 240px;
    min-width: 240px;
    height: 50px;
    margin-left: auto;
  }

  .ticket-email {
    width: calc(100% - 264px);
    min-width: calc(100% - 264px);

    span {
      font-size: 16px;
      font-weight: bold;
      color: black;
      display: block;
    }

    p {
      max-width: 1000px;
      font-size: 16px;
      line-height: 24px;
    }
  }
}

@media screen and (max-width: $screen--l-max) {
}

@media screen and (max-width: $screen--m-max) {
  .ticket-email-group {
    flex-direction: column;
    align-items: center;

    > button {
      width: 100%;
      max-width: 320px;
      height: 50px;
      margin: 0 auto;
    }

    .ticket-email {
      width: 100%;
      min-width: 100%;
      margin-bottom: 24px;
      text-align: center;
    }
  }
}
