.data-filter {
  margin-top: 40px;
}

.data-filter__holder {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 5px;

  .radio,
  .checkbox {
    margin-top: 0;
  }
}

.data-filter__count {
  margin-left: auto;
}

.data-filter--unknown {
  color: $secondaryColor;
}
