/*------------------------------------*\
    Quill (global)
\*------------------------------------*/
.ql-container.ql-snow {
  border: 0;
  font-family: 'Mukta', sans-serif;
  font-size: 16px;
  line-height: 22px;
  color: $color-dark;

  .ap {
    background-image: none;
    text-indent: 0;
    width: unset;
    height: unset;
    font-size: unset;
    overflow: unset;
    line-height: unset;
    margin-top: 0;
    display: unset;
  }

  .ql-emojiblot {
    vertical-align: middle;
  }

  #emoji-palette {
    max-width: 400px;

    #tab-panel {
      justify-content: unset;
      max-height: 300px;
      padding: 5px 0;
    }

    .ap {
      width: 25px;
      height: 25px;
      font-size: 25px;
      margin: 4px;

      /* Bug in pacakge https://github.com/contentco/quill-emoji/issues/153 */
      &.ap-speech {
        display: none;
      }
    }
  }

  .emoji_completions {
    button {
      color: $color-dark;
      box-shadow: none;
      font-weight: normal;
    }
  }

  .ql-editor {
    &.ql-blank {
      font-size: 16px;
      line-height: 24px;
      color: $color-dark;
      padding: 16px;

      &:before {
        display: none;
      }

      &:after {
        color: rgba(0, 0, 0, 0.6);
        content: attr(data-placeholder);
        display: block;
        font-style: italic;
        margin-top: -1.42em;
        pointer-events: none;
      }
    }

    p {
      font-size: 16px;
      line-height: 24px;
      color: $color-dark;
    }

    a {
      color: $color-orange !important;
    }
  }

  .ql-tooltip {
    position: absolute;
    top: 5% !important;
    right: 0;
    left: 10% !important;
    width: 100%;
    max-width: 80%;
    border: 2px solid $color-border;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.16);
    padding: 12px;
    border-radius: 8px;
    transform: none !important;

    &:before {
      display: none;
    }

    .ql-preview {
      float: left;
      width: calc(100% - 100px);
      min-width: calc(100% - 100px);
      font-size: 14px;
      line-height: 40px;
      color: $color-dark;
      padding: 0 12px;
      height: 40px;
      border: 1px solid $color-border;
      border-radius: 8px;
      margin-right: 8px;
      overflow: hidden;
      background: rgba($color-grey-darker, 0.4);
      pointer-events: none;
    }

    .ql-action,
    .ql-remove {
      float: left;
      position: relative;
      width: 40px;
      height: 40px;
      background: $color-white;
      transition: 260ms ease;
      border: 1px solid $color-border;
      border-radius: 8px;

      &:hover {
        background: rgba($color-border, 0.1);
      }

      &:before,
      &:after {
        content: '' !important;
        position: absolute;
        top: 6px;
        left: 6px;
        padding: 0;
        margin: 0;
        border: 0;
        width: 26px;
        height: 26px;
        background-size: 26px;
        background-repeat: no-repeat;
        background-position: center;
      }
    }

    .ql-action {
      margin-right: 8px;

      &:before {
        top: 8px;
        left: 8px;
        width: 24px;
        height: 24px;
        background-size: 24px;
        background-image: url('/assets/img/icons/pencil--blue.svg');
      }
    }

    .ql-remove {
      &:before {
        background-image: url('/assets/img/icons/delete--red.svg');
      }
    }

    &.ql-editing {
      input {
        float: left;
        width: calc(100% - 100px);
        font-size: 14px;
        color: $color-dark;
        padding: 0 12px;
        height: 40px;
        border: 1px solid $color-border;
        margin-right: 8px;
        border-radius: 6px;
        box-shadow: none;
        outline: none;
      }

      .ql-action {
        background: $color-orange;
        border: 0;

        &:hover {
          background: rgba($color-orange, 0.8);
        }

        &:before {
          background-image: url('/assets/img/icons/save--white.svg');
        }
      }
    }
  }
}

@media screen and (max-width: $screen--m-max) {
  .ql-container.ql-snow {
    .ql-tooltip {
      top: 5% !important;
      left: 2.5% !important;
      max-width: 95%;
    }
  }
}

/*------------------------------------*\
    Quill (specific)
\*------------------------------------*/
.bouwapp-quill {
  position: relative;
  background: $color-white;
  border: 1px solid $color-border;
  border-radius: 6px;

  app-loader {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    .loader {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 0;
      margin-bottom: 100px;
    }
  }

  &__toolbar {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 41px;
    border-bottom: 1px solid $color-border;

    &__markup {
      padding: 0 !important;
      border: 0 !important;

      > button {
        width: 40px !important;
        height: 40px !important;
        border-right: 1px solid $color-border !important;
        border-radius: 0 !important;
        box-shadow: none !important;
        text-align: center !important;
        padding: 10px !important;
        float: none !important;
        margin: 0 !important;
        background: $color-white !important;
        transition: 260ms ease;

        svg {
          width: 20px !important;
          height: 20px !important;
          transition: 260ms ease;
        }

        &:hover {
          background: rgba($color-border, 0.1) !important;
        }

        &:first-child {
          border-radius: 5px 0 0 0 !important;
        }
      }
    }

    .character-counter {
      font-size: 15px;
      font-weight: $fontweight-semibold;
      margin-left: auto;
      margin-right: 8px;
      align-self: center;
    }
  }

  &__editor {
    quill-editor {
      width: 100%;
    }

    .ql-container.ql-snow {
      .ql-editor {
        min-height: 160px;
      }
    }

    &.disabled {
      opacity: 0.5;
    }
  }
}

@media screen and (max-width: $screen--l-max) {
  .bouwapp-quill__toolbar .character-counter {
    position: absolute;
    right: 0;
    top: -28px;
    margin-right: 0;
    text-align: right;
  }
}
