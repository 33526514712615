app-default-profile-company {
  .bouwapp-group {
    &__item {
      &.flex {
        gap: 2.4rem;

        :first-child {
          flex-basis: 66%;
        }

        :last-child {
          flex-basis: 34%;
        }
      }
    }
  }
}
