@import '/src/assets/scss/bouwapp/buttons';

/*------------------------------------*\
    Updates (overview)
\*------------------------------------*/

.updates {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  &__filter {
    width: 240px;
    min-width: 240px;
    background-color: $color-white;
    border-radius: 12px;
    border: 1px solid $color-border;
    height: fit-content;

    .filter__group {
      padding: 1.6rem 2.4rem;
      max-height: 32rem;
      overflow-y: auto;

      &:not(:last-child) {
        border-bottom: 1px solid $color-border;
      }

      input {
        padding: 0.8rem 1.2rem;
        color: $color-grey;
        font-weight: $fontweight-medium;
        border: 0.1rem solid $color-border;
        border-radius: 0.8rem;
        margin-bottom: 0.8rem;
      }
    }

    .filter__title {
      padding: 1.6rem 2.4rem;
      border-bottom: 1px solid $color-border;

      h3 {
        margin: 0;
        font-size: 1.8rem;
        font-weight: $fontweight-bold;
      }
    }

    .filter__label {
      display: block;
      font-weight: $fontweight-medium;
      font-size: 1.8rem;
      line-height: 3rem;
      color: $color-dark;
      margin-bottom: 8px;
    }
  }

  &__view {
    width: 100%;
  }

  &__list,
  &__view {
    .no-results {
      text-align: center;
      margin-top: 2.4rem;
    }

    > p {
      font-weight: bold;
      font-size: 1.6rem;
      line-height: 2.4rem;
      color: $color-dark;
      margin-bottom: 8px;
    }

    .update {
      &__box {
        $self: &;

        $string: inspect($self);
        $spaceIndex: str-index($string, ' ');
        $self: str-slice($string, $spaceIndex + 1, -3);

        position: relative;
        background: $color-white;
        border-radius: 1.2rem;
        border: 1px solid $color-border;
        transition: 260ms ease;
        margin-bottom: 0.8rem;
        padding: 1.6rem;

        &__overlay {
          height: 0;
          opacity: 0;
          visibility: hidden;
          transition:
            opacity 260ms ease,
            visibility 260ms ease;

          &__edit {
            display: none;
          }
        }

        &.standalone:hover {
          cursor: pointer;

          .update__box__overlay {
            position: absolute;
            left: 0;
            top: 0;
            background: rgba($color-black, 0.25);
            z-index: 10;
            width: 100%;
            height: 100%;
            border-radius: 1.2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: 1;
            visibility: visible;

            &__edit {
              display: flex;
              align-items: center;
              gap: 10px;
              color: $color-white;

              > div {
                display: flex;
                align-items: center;
              }
            }
          }
        }

        &.empty {
          text-align: center;
          padding: 3.2rem;
        }

        &:not(.empty):not(.standalone):hover {
          cursor: pointer;
          box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
          border-color: $color-grey;
        }

        &--start {
          font-weight: $boldFontWeight;
          display: flex;
          text-align: center;
          justify-content: center;
          align-items: center;
          border: 0;
          pointer-events: none;

          .flag-image {
            margin-right: 12px;
            vertical-align: middle;
            height: 16px;
          }
        }

        &__content {
          .project-name {
            color: $color-orange;
          }

          &__actions {
            position: absolute;
            top: 24px;
            right: 24px;
            z-index: 10;

            > .edit,
            > .delete {
              cursor: pointer;
              display: block;
              width: 32px;
              min-width: 32px;
              height: 32px;
              margin-left: 8px;
              text-align: center;
              padding-top: 4px;
              border-radius: 2px;
              transition: 260ms ease;

              svg path {
                fill: $color-grey !important;
                transition: 260ms ease;
              }
            }

            > .edit:hover {
              svg path {
                fill: $color-blue !important;
              }
            }

            > .delete:hover {
              svg path {
                fill: $color-red !important;
              }
            }
          }

          &__top {
            p {
              font-size: 1.6rem;
              margin: 0;
              line-height: 2.4rem;

              &.title {
                span:first-of-type {
                  font-weight: $fontweight-semibold;
                  color: $color-dark;
                }

                span:last-of-type {
                  font-weight: $fontweight-medium;
                  color: $color-grey;
                  margin-left: 0.8rem;
                }
              }

              &.scheduled {
                span {
                  font-weight: $fontweight-bold;
                  color: $color-red;
                  display: inline-block;
                  vertical-align: middle;

                  &:first-of-type {
                    height: 2rem;
                    width: 2rem;
                    margin-right: 0.8rem;

                    svg {
                      width: 100%;
                      height: 100%;
                      fill: $color-red;
                    }
                  }

                  &:last-of-type {
                    margin-top: 0.2rem;
                  }
                }
              }

              &.concept {
                font-weight: $fontweight-bold;
                color: var(--primary);
              }
            }
          }

          &__bottom {
            display: flex;
            justify-content: space-between;
            font-size: 1.6rem;
            line-height: 2.4rem;

            > span {
              > span {
                vertical-align: middle;
                display: inline-block;

                &:first-child {
                  width: 2rem;
                  height: 2rem;
                  margin-right: 0.8rem;

                  svg {
                    width: 100%;
                    height: 100%;
                    fill: $color-blue;
                  }
                }

                &:last-child {
                  color: $color-blue;
                }
              }
            }

            > a {
              font-weight: $fontweight-regular;
              color: $color-dark;

              span {
                display: inline-block;
                vertical-align: middle;

                &:last-child {
                  margin-left: 0.4rem;
                  width: 2rem;
                  height: 2rem;

                  svg {
                    width: 100%;
                    height: 100%;
                    fill: $color-dark;
                  }
                }
              }
            }
          }

          &__preview {
            display: inline-block;
            margin-left: 8px;
            line-height: 40px;
            vertical-align: top;

            svg {
              height: 12px;
            }
          }

          &__clock {
            display: inline-block;
            margin-left: 8px;
            position: relative;
            top: 4px;
            width: 24px;
            height: 24px;

            path {
              fill: $color-orange;
            }
          }

          &__concept {
            display: block;
            font-weight: $fontweight-regular;
            font-size: 18px;
            margin-top: -8px;
            color: $color-grey;
          }

          &__text {
            line-height: 2.4rem;
            font-size: 1.6rem;
            max-height: 4.8rem;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            overflow: hidden;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;

            p {
              margin: 0;
              line-height: 2.4rem;
            }
          }

          &__attachments {
            .overlay {
              padding: 0.4rem;
              background-color: rgba($color-dark, 0.5);

              svg {
                height: 2.8rem;
                display: block;
                margin: 0 auto;
              }
            }

            ul {
              padding: 0;
              margin: 1.6rem 0;
              list-style-type: none;
              display: flex;
              flex-wrap: wrap;
              gap: 1.2rem;

              li {
                width: 12rem;
                height: 12rem;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center center;
                position: relative;
                border-radius: 0.8rem;
                overflow: hidden;
                border: 0.1rem solid $color-border;

                video {
                  height: 100%;
                  width: 100%;
                  object-fit: cover;
                }

                .video-icon {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate((-50%, -50%));
                  border-radius: 0.2rem;
                  width: 3.2rem;
                  height: 2.4rem;

                  img {
                    width: 100%;
                    height: 100%;
                  }

                  svg {
                    background-color: $color-white;
                    width: 2.4rem;
                    height: 2.4rem;
                    display: block;
                    border-radius: 2px;
                    margin-left: 0.4rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.project-map {
  position: relative;
  width: 100%;
  margin: 1.6rem 0;

  .map {
    width: 100%;
    height: 42rem;
  }
}

/*------------------------------------*\
    Updates (view)
\*------------------------------------*/
.updates-view {
  .bouwapp-subheader {
    &__text {
      h3 {
        margin: 22px 0;
      }
    }
  }

  .statistics {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2.4rem;

    .stat,
    .chart {
      padding: 1.6rem;
      margin: 0;
      background-color: $color-white;
      border: 1px solid $color-border;
      border-radius: 1.2rem;
      flex: 1 1 calc((100% / 4) - 2.4rem);

      h3 {
        margin: 0;
        font-size: 1.8rem;
        margin-bottom: 0.8rem;

        span {
          display: inline-block;
          height: 1.6rem;

          svg {
            fill: var(--primary);
            cursor: pointer;
            display: block;
          }
        }
      }
    }

    .chart {
      &__interactions {
        flex-basis: calc(((100% / 3) * 2) - 1.2rem);

        .chart__inner {
          max-width: 100%;
          height: 515px;
          padding: 0;
          overflow: hidden;

          canvas {
            max-width: 100%;
            padding: 0;
          }
        }
      }

      &__inner {
        width: 100%;
        max-width: 400px;
        padding: 0 2.4rem 1.6rem 2.4rem;
        margin: 0 auto;
        position: relative;

        .empty {
          position: absolute;
          top: 2.4rem;
          left: 0;
          width: 100%;
          text-align: center;
          font-size: 1.4rem;
        }

        canvas {
          margin-top: 0;
          padding: 0 1.6rem;
        }

        .legend {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 1.6rem;
          margin-top: 1.6rem;
          justify-content: center;
          align-items: center;

          > div {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            > span {
              font-size: 1.4rem;
              font-weight: $fontweight-semibold;
              color: $color-dark;
              display: flex;
              overflow: hidden;

              > span {
                height: 2rem;
                line-height: 2rem;
                display: inline-block;
                vertical-align: middle;
                margin-right: 0.8rem;

                &:first-child {
                  width: 2rem;
                  border-radius: 50%;
                }

                > svg {
                  height: 100%;

                  &:not([width='20']) {
                    margin: 0 0.2rem;
                  }
                }
              }
            }
          }
        }
      }
    }

    .stat {
      &__map {
        flex-basis: 100%;

        mgl-map {
          height: 40rem;
          border-radius: 0.8rem;
          overflow: hidden;
        }
      }

      > span:first-of-type {
        color: var(--primary);
        font-size: 4rem;
        line-height: 4rem;
        display: inline-block;
        vertical-align: middle;
      }

      > span:last-of-type {
        margin-left: 0.8rem;
        display: inline-block;
        vertical-align: middle;

        > span {
          display: inline-block;
          line-height: 1.6rem;
          vertical-align: middle;

          svg {
            height: 100%;
            width: 100%;
          }
        }
      }

      span.negative {
        color: $color-red;

        svg path {
          fill: $color-red;
        }
      }

      span.positive {
        color: $color-green;

        svg path {
          fill: $color-green;
        }
      }

      span.grey {
        color: $color-grey;

        svg path {
          fill: $color-grey;
        }
      }
    }
  }
}

/*------------------------------------*\
    Updates (new)
\*------------------------------------*/
.updates-edit {
  .form-group {
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__general {
    position: relative;

    &__overlay {
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      position: absolute;
      opacity: 0;
      transition: 250ms opacity ease;
      z-index: 99;
      border-radius: 6px;
      pointer-events: none;
      border: 5px dashed var(--primary);

      &.file {
        opacity: 1;
      }
    }

    .character-counter {
      font-size: 15px;
      font-weight: $fontweight-semibold;
      margin-left: auto;
      margin-right: 8px;
      align-self: center;
    }
  }

  &__categories {
    .targets {
      .checkbox:first-child:last-child {
        margin-top: 16px;
      }

      hr {
        border-color: $color-border;
        width: 40%;
        text-align: left;
        left: 0;
        margin: 0;
        margin-top: 16px;
      }

      .select-all {
        width: 100%;
        margin-top: 12px;

        .toggle {
          display: inline-block;
          vertical-align: middle;
          margin-left: 8px;
        }
      }

      .target {
        > div {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          width: 50%;
          min-width: 550px;
          max-width: 100%;

          .checkbox {
            width: 50%;
          }
        }
      }
    }
  }

  .events__container {
    position: relative;

    &__overlay {
      position: absolute;
      border-radius: 6px;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.25);
      z-index: 1000;
    }
  }

  &__events {
    .events-button {
      &__wrapper {
        margin-top: 16px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        button {
          @include update-button;
          margin: 10px;
        }
      }
    }

    .update-event {
      &__item {
        scroll-margin: 50px;
        display: flex;
        flex-direction: row;
        position: relative;

        &:not(:last-child) {
          margin-bottom: 24px;
        }

        &__left {
          border: 1px solid $color-border;
          border-radius: 6px;
          width: 90%;
          padding: 8px 16px;

          .count {
            position: absolute;
            top: -10px;
            left: -10px;
            background-color: $color-dark;
            border-radius: 50%;
            width: 2.4rem;
            height: 2.4rem;
            color: $color-white;
            line-height: 2.4rem;
            font-size: 1.4rem;
            text-align: center;
          }

          .form-split {
            margin: 1.6rem 0 0.8rem 0;
          }
        }

        &__right {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          flex-grow: 1;
          margin-left: 16px;

          span {
            cursor: pointer;
          }
        }
      }
    }
  }

  &__poll {
    > .form-group {
      label {
        display: inline-block;
      }

      .button--flat {
        display: inline-block;
      }
    }

    .errors {
      position: absolute;
      bottom: 8px;
      left: 12px;
    }

    .input-file-standalone {
      label {
        display: flex;
        justify-content: center;
        align-items: center;

        .spinner {
          width: 14px;
          height: 14px;
          background-image: url(/assets/img/icons/spinner.png);
        }
      }
    }
  }

  .bouwapp-box {
    &__sub__footer {
      padding: 0 16px;
      button {
        padding: 0;
        font-size: 14px;
      }
    }
  }

  &__buttons {
    .publication {
      display: flex;
      flex-direction: row;
      margin: 16px 0;
      font-size: 16px;
      line-height: 24px;
      align-items: center;
      flex-wrap: wrap;
      color: $color-dark;

      &:first-of-type {
        margin-top: 0;
      }

      &:last-of-type {
        margin-bottom: 0;
      }

      span:first-child {
        width: 24px;
        height: 24px;
        margin-right: 8px;

        svg {
          width: 24px;
          height: 24px;
          fill: $color-dark;
        }
      }

      &.status {
        span:last-of-type {
          color: $statusGreen;
          margin-left: 8px;

          &.concept {
            color: var(--primary);
          }
        }
      }

      &.date {
        align-items: start;
        margin: 0;

        strong {
          display: block;
        }

        > span:last-of-type {
          width: calc(100% - 4rem);
          text-align: left;
        }

        span > span:last-of-type {
          cursor: pointer;
          color: $color-blue;
          font-size: 14px;
          font-weight: $regularFontWeight;
          margin-left: auto;

          &.cancel {
            color: $color-red;
          }
        }

        .custom {
          width: 100%;
          margin: 16px 0;
        }
      }

      &.notification {
        .push-status {
          width: 100%;
          display: block;
          font-size: 14px;
          font-weight: $fontweight-bold;
          margin-left: 32px;
        }

        > span {
          &:last-child {
            margin-left: 32px;
          }

          > span {
            font-size: 14px;
            font-weight: $fontweight-bold;
          }
        }

        app-toggle {
          margin-left: auto;
        }

        .push-disabled {
          margin-top: 8px;
          font-size: 14px;
          line-height: 20px;
          color: $color-grey-dark;
        }
      }

      &.targets {
        align-items: start;
        margin: 0;

        .target {
          max-height: 40vh;
          overflow-y: auto;
        }

        hr {
          margin: 10px 0;
          border: none;
        }

        > span:last-of-type {
          width: calc(100% - 4rem);
          text-align: left;
        }

        span > span:last-of-type {
          cursor: pointer;
          color: $color-blue;
          font-size: 14px;
          font-weight: $regularFontWeight;
          margin-left: auto;

          &.cancel {
            color: $color-red;
          }
        }

        .custom {
          width: 100%;
          margin: 16px 0;
        }

        .form-group.has-error label {
          color: $color-dark;
        }

        .errors {
          font-size: 14px;
        }

        .list {
          padding-left: 32px;
          margin-top: 8px;
          width: 100%;

          span {
            font-size: 14px;
            display: block;
            width: 100%;
          }
        }

        .checkbox {
          padding-left: 32px;

          label {
            font-size: 14px;
            line-height: 14px;

            &:before {
              top: 4px;
              left: 4px;
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $screen--s) {
  .updates-view {
    .statistics {
      .stat {
        flex-basis: calc((100% / 2) - 2.4rem);

        &__map {
          flex-basis: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: $screen--xs) {
  .updates-view {
    .statistics {
      .stat,
      .chart {
        flex-basis: 100%;
      }
    }
  }
}

@media screen and (max-width: $screen--l-max) {
  .updates-edit {
    h1 {
      font-size: 26px;
      text-align: center;
    }

    &__general {
      .character-counter {
        position: absolute;
        right: 0;
        top: -28px;
        margin-right: 0;
        text-align: right;
      }
    }

    &__categories .targets .target > div .checkbox {
      width: 100%;
    }

    &__buttons {
      position: relative;
      flex-direction: column;
      padding-top: 0;
      box-shadow: none;
      text-align: center;
      border: 0;
      background: none;

      .publication {
        &.targets {
          .target {
            max-height: 100%;
            overflow-y: hidden;
          }
        }
      }

      > .button {
        margin: 16px 0 0 0;
        width: 100%;
      }

      > a {
        display: block;
        margin: 0;
      }
    }
  }

  .updates {
    > div > p {
      margin-top: 0.8rem;
      text-align: center;
    }

    &__list {
      width: 100%;
      min-width: 100%;
      padding: 0;
    }
  }
}

/*------------------------------------*\
    Updates (poll)
\*------------------------------------*/
.update-poll {
  padding: 0;
  list-style: none;
  margin: 0;

  &__choice {
    display: inline-flex;
    width: 100%;
    vertical-align: middle;
    border-radius: 6px;
    margin-bottom: 8px;
    position: relative;
    border: 1px solid $color-border;

    &__image {
      width: 80px;
      height: 80px;
      background-color: $defaultPageBackground;
      border: 1px dashed $color-border;
      flex-shrink: 0;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-size: cover;
      background-position: center;
      margin: 8px;

      &.withImage {
        border: none;
      }

      &:hover {
        .update-poll__choice__image__tag {
          display: flex;
        }
      }

      &__tag {
        height: 100%;
        align-items: center;
        justify-content: center;

        &,
        svg {
          height: 24px;
          width: 24px;
          fill: $color-border;
        }
      }

      &__input {
        width: 100%;
        height: 100%;

        .input-file-standalone {
          width: 100%;
          height: 100%;
        }

        label {
          box-shadow: none;
          background: none;
          padding: 0;
          margin: 0;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        img {
          display: block;
          margin: 0;
        }
      }
    }

    &__text {
      margin: 0;
      height: 96px;
      border: none;
      border-radius: 6px;

      &-holder {
        width: 100%;

        .form-group {
          margin: 0 !important;
        }
      }
    }

    &__remove {
      position: absolute;
      right: 8px;
      bottom: 8px;
      cursor: pointer;

      span {
        color: $color-red;
        font-size: 14px;
      }

      &[hidden] {
        display: none;
      }
    }

    &__count {
      position: absolute;
      right: 8px;
      top: 8px;
      color: $mutedTextColor;
      font-size: 1.25rem;

      &-wrapper {
        position: relative;
      }
    }
  }

  .inline-svg__item {
    height: 100%;
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
  }
}
