.navigation {
  height: 60px;
  border-bottom: 1px solid $defaultBorder;
  background: $color-white;

  .tabs-menu {
    float: none;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .tab-settings {
    margin-left: auto;

    a {
      display: inline-flex;
      align-items: center;
    }
  }

  .tab-export {
    margin-left: 0;

    a {
      display: inline-flex;
      align-items: center;
    }

    .menu-image {
      margin-right: 0;
    }
  }

  .menu-image {
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.8rem;

    &,
    svg {
      height: 18px;
    }
  }
}

@media screen and (max-width: $screen--m-max) {
  .navigation {
    margin-bottom: 24px;
  }
}
