.tile {
  width: 100%;
  background-color: $tileBackground;
  margin: 20px 0;

  &.tile-inactive {
    opacity: 0.7;
  }

  &.tile-clickable {
    cursor: pointer;
  }

  @include round(2px);
  @include shadow(0, 2px, 17px, false, 0.09);

  .tile-header {
    .tile-image {
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;

      width: 100%;
      height: 170px;
      background-size: cover;
      .tile-gradient {
        width: 100%;
        height: 100%;
        background-image: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0) 43%,
          rgba(255, 255, 255, 0.83) 73%,
          $white
        );
      }
    }
    .tile-text {
      margin: -21px 20px 20px 20px;

      h3,
      h4,
      h5 {
        margin: 0;
      }

      h5 {
        color: $secondaryTextColor;
      }

      .tile-info {
        margin-top: 10px;
        margin-bottom: 20px;
        font-weight: $semiBoldFontWeight;
        color: $primaryTextColor;

        .tile-calendar {
          margin-right: 7px;
          display: inline-block;
        }
      }

      border-bottom: 1px solid $tileInnerBorder;
    }
  }
  &.tile-text {
    .tile-content {
      font-weight: $regularFontWeight;
      padding: 35px;
    }
  }
}

.tile-content {
  padding: 15px 20px 30px 20px;
  font-size: 16px;
  font-weight: $semiBoldFontWeight;

  .tile-actions {
    float: right;

    .tile-action {
      margin-left: 20px;
      cursor: pointer;
    }

    .tile-delete,
    .tile-edit {
      &,
      svg {
        height: 20px;
      }
    }
  }

  .tile-attachments {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;

    .tile-attachment {
      width: 80px;
      min-width: 80px;
      flex-shrink: 0;
      flex-grow: 0;
      flex-basis: 80px;
      display: flex;
      margin-right: 10px;
      margin-bottom: 10px;
      height: 60px;
      background-size: cover;
      background-position: center;
      align-items: center;
      justify-content: center;

      @include shadow(0, 2px, 5px, false, 0.14);
      @include round(2px);

      &:last-of-type {
        margin-right: 0;
      }

      position: relative;

      &.tile-attachment-more {
        cursor: pointer;
      }

      .tile-attachment-more-text {
        line-height: 60px;
        text-align: center;
        color: $white;
        font-size: 22px;
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        @include round(2px);
      }
    }
  }
}

.tile-attachment__icon svg {
  width: 3rem;
}
