/*------------------------------------*\
    Breakpoints
\*------------------------------------*/
$screen--xs: 480px;
$screen--s: 640px;
$screen--m-max: 767px;
$screen--m-min: 768px;
$screen--l-max: 1023px;
$screen--l-min: 1024px;
$screen--xl: 1440px;
$screen--xxl: 1920px;

$fullhd--height: 1080px;
