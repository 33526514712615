.sticky-submit {
  display: flex;
  justify-content: center;
  align-items: center;

  &__wrapper {
    min-width: 40%;
    position: fixed;
    top: 1.6rem;
    z-index: 9999;
    background: $color-white;
    border: 1px solid $color-border;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    padding: 16px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    > span {
      > h3 {
        font-size: 16px;
        font-weight: $fontweight-semibold;
        color: $color-dark;
        margin: 0;
      }

      > div {
        font-size: 14px;
      }
    }
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    > button {
      flex-shrink: 0;
      display: inline-flex;
      align-items: center;
      > span:first-child {
        margin-right: 5px;
      }

      &.primary {
        @include button--flat($color-orange, $color-white);
      }

      &.secondary {
        @include button--flat($color-blue, $color-white);
      }
    }
  }
}

@media screen and (max-width: $screen--m-max) {
  .sticky-submit {
    &__wrapper {
      flex-direction: column;
      text-align: center;

      > span > div {
        display: none;
      }

      > span > h3 {
        margin-bottom: 0.6rem;
      }

      > button {
        margin-left: 0;
      }
    }
  }
}
