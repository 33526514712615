/*------------------------------------*\
    Projects - Welcome
\*------------------------------------*/
.projects-welcome {
  padding: 12px 0;
  margin: 12px 0;
  border-radius: 6px;
  font-size: 16px;
  line-height: 24px;
  color: $color-dark;
}

.success-modal {
  .project-success {
    margin: 0 auto;
    text-align: center;
    padding-bottom: 3rem;

    h5 {
      margin: 20px 0;
    }

    p {
      font-size: 16px;
      line-height: 32px;
      color: $paragraphTextColor;
      padding: 0 3rem;
    }

    .flex.buttons {
      justify-content: center;
      margin-top: 40px;

      button {
        margin: 0 12px;
      }
    }
  }
}

/*------------------------------------*\
    Projects - Overview
\*------------------------------------*/
.projects {
  &__head {
    align-items: center;

    h1 {
      font-size: 40px;
      font-weight: $fontweight-bold;
      margin: 0;
    }

    .buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: auto;

      > a {
        margin-left: 16px;
      }
    }
  }

  &__toolbar {
    padding: 24px;
    margin: 12px 0;
    background: $color-grey-box;
    border-radius: 6px;

    > div {
      &:first-child {
        width: calc(70% - 24px);
        min-width: calc(70% - 24px);
      }

      &:last-child {
        width: 30%;
        min-width: 30%;
        margin-left: auto;
      }

      label {
        display: block;
        font-size: 16px;
        line-height: 26px;
        font-weight: $fontweight-semibold;
        color: $color-dark;
        margin: 0 0 4px 0;
      }

      input[type='search'] {
        width: 100%;
        border-radius: 6px;
        border: 1px solid $color-border;
        box-shadow: none;
        margin: 0;
        height: 50px;
        font-size: 16px;
        transition: 260ms ease;
      }

      select {
        width: 100%;
        border-radius: 6px;
        border: 1px solid $color-border;
        box-shadow: none;
        margin: 0;
        transition: 260ms ease;
        height: 50px;
        font-size: 16px;
        padding: 0 16px;
      }
    }
  }

  &__overview {
    > div {
      > p {
        font-weight: $fontweight-bold;
        font-size: 1.6rem;
        line-height: 2.4rem;
        color: $color-dark;
        margin-bottom: 8px;
      }
    }

    &__items {
      .no-results {
        text-align: center;
        margin-top: 2.4rem;
      }

      > p {
        font-weight: $fontweight-bold;
        font-size: 1.6rem;
        line-height: 2.4rem;
        color: $color-dark;
        margin-bottom: 8px;
      }

      > ul {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 0;
        margin: 0;
        list-style-type: none;
        gap: 2.4rem;

        .project {
          $self: &;
          width: calc(50% - 1.2rem);
          min-width: calc(50% - 1.2rem);
          max-width: calc(50% - 1.2rem);
          background: $color-white;
          border-radius: 1.2rem;
          border: 1px solid $color-border;
          transition: 260ms ease;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          overflow: hidden;

          &:hover {
            box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
            border-color: $color-grey-light;
          }

          &__image {
            position: relative;
            width: 100%;
            height: 200px;

            &__self {
              width: 100%;
              height: 100%;
              background-size: cover;
              background-repeat: no-repeat;

              &.noImage {
                background: rgba($color-grey, 0.1);
              }
            }
          }

          &__badge {
            position: absolute;
            bottom: 12px;
            left: 12px;
            font-size: 14px;
            font-weight: $fontweight-bold;
            line-height: 24px;
            color: $color-white;
            padding: 2px 8px;
            background: $color-orange;
            border-radius: 4px;
            text-transform: lowercase;

            &.child {
              background: $color-grey;
            }
          }

          &__heading {
            padding: 1.6rem;

            > p {
              font-weight: $fontweight-semibold;
              font-size: 1.8rem;
              line-height: 2.4rem;
              color: $color-dark;
              max-width: 100%;
              max-height: 2.4rem;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              margin-bottom: 3px;
            }

            > div {
              display: flex;
              flex-direction: row;
              gap: 1rem;

              &.followers {
                > span {
                  > span:first-child {
                    height: 2rem;
                    width: 2rem;
                    display: inline-block;
                    vertical-align: middle;

                    svg {
                      height: 100%;
                      width: 100%;
                      fill: $color-blue;
                    }
                  }

                  &:first-child {
                    font-weight: $fontweight-regular;
                    color: $color-blue;
                  }

                  &:last-child {
                    font-weight: $fontweight-light;
                  }
                }
              }

              > span {
                font-size: 1.4rem;
                line-height: 2rem;
                color: $color-grey;

                &:first-child {
                  font-weight: $fontweight-medium;
                }

                &:last-child {
                  text-wrap: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                }

                &.green {
                  color: $statusGreen;
                }

                &.orange {
                  color: $statusOrange;
                }

                &.red {
                  color: $statusRed;
                }

                &.grey {
                  color: $statusGrey;
                }

                &.dark {
                  color: $statusDark;
                }

                &.blue {
                  color: $statusBlue;
                }
              }
            }
          }

          &__content {
            margin-bottom: auto;
            padding-top: 24px;
            border-top: 1px solid $color-border;

            ul {
              padding: 0;
              margin: 0;
              list-style-type: none;

              li {
                display: flex;
                flex-direction: row;
                align-items: center;

                &:not(:last-child) {
                  margin-bottom: 8px;
                }

                &:first-child {
                  p span {
                    font-weight: $fontweight-semibold;
                    color: $color-dark;
                  }
                }

                .icon {
                  width: 24px;
                  height: 24px;
                  margin-right: 16px;

                  svg {
                    width: 24px;
                    height: 24px;
                  }
                }

                p {
                  font-weight: $fontweight-semibold;
                  font-size: 16px;
                  color: $color-dark;
                  margin: 0;

                  span {
                    font-weight: $fontweight-regular;
                    color: $color-grey;
                  }
                }
              }
            }
          }

          &__parent {
            text-align: center;
            font-size: 16px;
            font-weight: $fontweight-regular;
            color: $color-dark;
            margin-bottom: 16px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $screen--m-max) {
  .projects {
    &__head {
      flex-direction: column;
      align-items: flex-start;

      h1 {
        font-size: 32px;
      }

      .buttons {
        margin: 16px 0 0 0;
      }
    }

    &__toolbar {
      flex-direction: column;
      margin: 32px 0;
      padding: 16px;

      > div {
        width: 100% !important;
        min-width: 100% !important;

        &:last-child {
          margin-top: 16px;
        }
      }
    }

    &__overview {
      flex-direction: column;

      &__items {
        width: 100%;
        min-width: 100%;

        > ul {
          margin: 0;

          .project {
            width: 100%;
            min-width: 100%;
            max-width: 100%;
            margin: 0 0 16px 0;
            padding: 16px;

            &__image {
              height: 180px;
            }

            &__heading {
              > div {
                flex-direction: column;

                > span {
                  &:first-child {
                    margin: 0;

                    &:after {
                      display: none;
                    }
                  }
                }
              }
            }

            &__content {
              padding-top: 16px;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: $screen--l-min) and (max-width: $screen--xl) {
  .projects {
    &__overview {
      &__items {
        > ul .project {
          min-width: 100%;
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: $screen--l-max) {
  .projects {
    &__overview {
      flex-direction: column;

      > div > p {
        margin-top: 0.8rem;
        text-align: center;
      }

      &__items {
        width: 100%;
        min-width: 100%;
        padding: 0;
      }
    }
  }
}

@media screen and (min-width: $screen--xxl) {
  .projects {
    &__overview {
      &__items {
        > ul .project {
          min-width: calc((100% / 3) - 1.6rem);
          width: calc((100% / 3) - 1.6rem);
          max-width: calc((100% / 3) - 1.6rem);
        }
      }
    }
  }
}
