/*------------------------------------*\
    Project - Settings
\*------------------------------------*/
.projects-settings {
  .projects__toolbar {
    select {
      width: 100%;
      max-width: 100%;
    }
  }

  &__users {
    display: flex;

    &__wrapper {
      > p {
        font-weight: $fontweight-bold;
        font-size: 1.6rem;
        line-height: 2.4rem;
        color: $color-dark;
        margin-bottom: 0.8rem;
      }

      &__content {
        border: 1px solid $color-border;
        border-radius: 1.2rem;
        overflow: hidden;

        .head,
        .body {
          padding: 0;
          margin: 0;
          list-style-type: none;

          > li {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            padding: 1.2rem 0.8rem;

            > span {
              display: flex;
              flex-wrap: wrap;
              gap: 0.4rem;
              flex-grow: 1;
              padding: 0.4rem;
              width: 15%;
              min-width: 100px;

              &:first-child {
                min-width: 310px;
                align-items: center;
              }

              &:nth-child(2) {
                min-width: 120px;
              }

              &:nth-child(3) {
                min-width: 130px;
              }

              &:last-child {
                max-width: 100px;
              }
            }
          }
        }

        .head {
          li {
            background-color: $color-grey-box;

            span {
              font-weight: bold;
              font-size: 14px;
              color: $color-black;
            }
          }
        }

        .body {
          li {
            background: $color-white;
            transition: 260ms ease;
            font-weight: 300;
            font-size: 14px;
            color: $color-dark;

            &:not(:last-child) {
              border-bottom: 1px solid $color-border;
            }

            > span {
              &.user-profile {
                > span {
                  display: inline-block;
                  vertical-align: middle;
                  font-size: 14px;
                  font-weight: $fontweight-regular;
                  align-items: center;
                  max-width: calc(100% - 52px);

                  > span:not(:first-child) {
                    display: block;
                    color: $color-grey;
                    font-weight: $fontweight-light;
                    overflow: hidden;
                    text-overflow: ellipsis;

                    &:hover {
                      word-break: break-all;
                    }
                  }

                  &:first-child {
                    margin-right: 8px;
                  }
                }
              }

              &.user-role {
                flex-direction: column;
                gap: 12px 0;

                > span {
                  > span {
                    &:first-child {
                      display: flex;
                      gap: 4px;
                      line-height: 16px;

                      span:last-child {
                        width: 100px;
                      }

                      span:has(svg) {
                        width: 16px;
                        height: 16px;

                        svg {
                          display: block;
                        }
                      }
                    }

                    &.number {
                      font-weight: $fontweight-regular;
                      color: $color-black;
                      padding-left: 20px;
                    }
                  }
                }

                .account_admin {
                  svg path {
                    fill: $primaryColor;
                  }
                }

                .project_admin {
                  svg path {
                    fill: $color-green;
                  }
                }

                .project_user {
                  svg path {
                    fill: $color-blue-darker;
                  }
                }

                .project_reader {
                  svg path {
                    fill: $color-grey-dark;
                  }
                }
              }

              &.last-event {
                flex-direction: column;

                > span {
                  > span,
                  &:last-child {
                    color: $color-grey;
                  }
                }
              }

              &.actions {
                justify-content: end;
                gap: 0.8rem;
                margin-left: auto;

                a {
                  &:first-child {
                    font-weight: $fontweight-light;
                    color: $color-grey;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $screen--l-max) {
  .projects-settings {
    &__users {
      flex-direction: column;
      &__wrapper {
        width: 100%;
        min-width: 100%;
        padding: 0;

        > p {
          margin-top: 0.8rem;
          text-align: center;
        }
      }
    }
  }
}

@media screen and (max-width: $screen--m-max) {
  .projects-settings {
    &__users {
      &__wrapper {
        &__content {
          .head {
            display: none;
          }

          .body {
            > li {
              align-items: start;
              border-bottom: 1px solid $color-border;

              > span {
                width: 100%;
                min-width: 100%;
                max-width: 100%;
                padding: 8px 8px 0 8px;

                &:not(:last-child):before {
                  width: 100%;
                  content: attr(aria-label);
                  display: block;
                  color: $color-grey;
                }
              }
            }
          }
        }
      }
    }
  }
}
