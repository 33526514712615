.tabs {
  .tabs-menu {
    @extend .menu;

    height: 60px;
    float: left;
    overflow-x: scroll;

    &:after {
      content: '';
      display: inline-block;
      min-width: 16px;
      width: 16px;
      height: 10px;
    }

    li {
      height: 60px;

      div.count-number {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;

        span {
          position: absolute;
          right: -1rem;
          top: -1.4rem;
          width: 2rem;
          height: 2rem;
          background: $color-orange;
          border-radius: 50%;
          font-weight: $fontweight-semibold;
          font-size: 1.4rem;
          line-height: 2rem;
          color: $color-white;
          text-align: center;
        }
      }

      a {
        padding: 19px 0;
        font-size: 16px;
      }

      &:first-of-type {
        margin-left: 0;
      }

      &.action {
        align-self: flex-end;
      }
    }
  }

  .tabs-content {
    padding-top: 20px;

    .tab {
      display: none;

      &.active {
        display: block;
      }
    }
  }
}

@media screen and (max-width: $screen--m-max) {
  .tabs {
    .tabs-content {
      padding-top: 0;
    }
  }

  .tabs .navigation {
    .container {
      padding: 0;

      .tabs-menu {
        padding: 0 16px;
      }
    }
  }
}
