.chart {
  .chart-top {
    display: flex;
    justify-content: space-between;
    color: $primaryTextColor;
    align-items: center;

    .chart-title {
      font-size: 20px;
      font-weight: $semiBoldFontWeight;
    }

    .chart-export {
      font-weight: $semiBoldFontWeight;
      font-size: 16px;
    }
  }

  canvas {
    margin-top: 10px;
    width: 100% !important;
    max-width: 585px;
    height: auto !important;
  }

  ul {
    display: flex;
    margin: 10px 0 0 0;
    padding: 0 0 0 16px;
    list-style-type: none;
    font-size: 14px;
    font-weight: $mediumFontWeight;
    flex-direction: column;

    li {
      margin: 5px 0;
      display: flex;
      flex-direction: row;

      .status {
        margin: 6px 0 0 12px;
        order: 2;
      }

      input[type='checkbox'] {
        height: 15px;
        width: 15px;
      }

      order: 2;

      &.chart-dataset-prior {
        order: 1;
      }
    }
  }
}

#chartjs-tooltip {
  opacity: 1;
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  border-radius: 3px;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
  pointer-events: none;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

.chartjs-tooltip-key {
  display: inline-block;
  width: 10px;
  height: 10px;
}

.chart__holder {
  position: relative;
  height: 250px;
}

.chart__point {
  cursor: pointer;
  position: absolute;
  width: 1rem;
  height: 1rem;
  margin-left: 1rem;
  margin-bottom: 3rem;
}

.chart__point__speech {
  &,
  svg {
    height: 20px;
  }
}

.chart__point__responses {
  cursor: default;
  position: absolute;
  top: 3rem;
  left: -1rem;
  background: $white;
  width: 20rem;
  padding: 1rem;
  border-radius: 2px;
  @include shadow(0, 2px, 4px, false, 0.05);
  border: 1px solid $commentsBorder;
  z-index: $commentsZindex;

  &::after {
    position: absolute;
    content: '';
    display: block;
    left: 1.5rem;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-color: transparent transparent $white transparent;
    top: -0.5rem;
  }

  &::before {
    position: absolute;
    content: '';
    display: block;
    left: 1.2rem;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0.8rem 0.6rem 0.8rem;
    border-color: transparent transparent $commentsBorder transparent;
    top: -0.5rem;
    z-index: -1;
  }
}

.chart__point__response {
  padding: 0.5rem 0;
  border-bottom: 1px solid $commentsBorder;
  font-size: 13px;

  &:last-of-type {
    border-bottom: 0;
  }
}

.chart__point__responses__scroll {
  max-height: 20rem;
  overflow-y: auto;
}

.chart__option__items {
  display: flex;
}

.chart__option__item {
  margin-right: 0.5rem;
}
