@import '/src/assets/scss/partials/projects/invite';
@import '/src/assets/scss/partials/projects/list';
@import '/src/assets/scss/partials/projects/new-update';
@import 'projects/tab-updates';

.projects.detail,
.customer {
  overflow: hidden;

  .projects-settings {
    .container {
      padding: 0;
    }
  }

  @import 'projects/tab-general';
  @import 'projects/tab-satisfaction-monitor';
  @import '/src/assets/scss/partials/projects/tab-statistics';
  @import '/src/assets/scss/partials/projects/tab-statistics-new';
  @import '/src/assets/scss/partials/projects/tab-settings';
  @import '/src/assets/scss/partials/projects/tab-widget';
  @import '/src/assets/scss/partials/projects/tab-targets';
  @import 'projects/tab-dashboard';
  @import './two-columns-layout';
}
