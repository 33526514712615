.category-dropdown {
  .category-option {
    color: $color-dark;
    font-weight: $fontweight-semibold;
    font-size: 16px;
  }

  .category-description {
    font-size: 16px;
    color: rgba($color-dark, 0.8);

    white-space: normal; /* Allow text to wrap */
    overflow: visible; /* Ensure text is not clipped */
    word-break: break-word; /* Break long words if necessary */
  }
}
