.poll {
  &:before {
    content: '';
    display: block;
    width: 50%;
    border-bottom: 1px dashed $color-border;
    margin: 1.6rem 0;
  }
}

.poll__title {
  &__text {
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: $color-dark;
    font-weight: $fontweight-bold;

    span {
      display: inline-block;
      line-height: 2.4rem;
      vertical-align: middle;

      &:has(svg) {
        width: 2.4rem;
        height: 2.4rem;
        margin-right: 0.8rem;

        svg {
          width: 100%;
          height: 100%;
          fill: $color-dark;
        }
      }

      &:last-of-type {
        margin-left: 0.8rem;
        color: var(--primary);
      }
    }
  }
}

.poll__choices {
  margin: 0.8rem 0 1.6rem 0;
}

.poll__choice {
  display: flex;
  flex-direction: row;
  gap: 1.6rem;
  align-items: center;
  margin-bottom: 0.8rem;

  img {
    width: 12rem;
    height: 12rem;
    border-radius: 0.8rem;
    border: 0.1rem solid $color-border;
    object-fit: cover;
  }

  .placeholder {
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 0.4rem;
    border: 0.1rem solid $color-border;
  }

  &__result {
    &__percentage {
      color: $color-dark;
      font-weight: $fontweight-bold;
    }

    &__label {
      display: block;
      word-break: break-all;
    }
  }
}
