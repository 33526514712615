/*------------------------------------*\
    Projects - Widget
\*------------------------------------*/
.widget {
  > h2 {
    font-size: 20px;
    color: $color-dark;
    margin-bottom: 16px;
  }

  &__container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .bouwapp-box {
      width: calc(60% - 12px);
      min-width: calc(60% - 12px);
      margin: 0;

      &__self {
        width: 100%;
        min-width: 100%;
      }
    }

    .widget__self {
      width: calc(40% - 12px);
      min-width: calc(40% - 12px);
      margin-left: auto;
      box-shadow: 0 2px 10px rgba($color-dark, 0.1);
      border-radius: 6px;
      overflow: hidden;

      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    > div:not(.widget__form__actions) {
      margin-top: 24px;
      width: calc(50% - 12px);
      min-width: calc(50% - 12px);
      display: flex;
      flex-direction: column;

      &:nth-child(2n) {
        margin-left: auto;
      }

      > label {
        font-size: 16px;
        font-weight: $fontweight-semibold;
        color: $color-dark;
        margin-bottom: 4px;
      }

      > .select {
        width: 100%;
        max-width: 100%;
      }
    }

    &__actions {
      margin-top: 24px;
      width: 100%;

      &__copy {
        margin-top: 40px;

        > div {
          display: flex;
          flex-direction: row;

          label {
            font-size: 16px;
            line-height: 24px;
            font-weight: $fontweight-semibold;
            color: $color-dark;
          }

          p {
            font-size: 16px;
            font-weight: $fontweight-bold;
            line-height: 24px;
            margin: 4px 0;

            span {
              margin-left: 16px;
              cursor: pointer;
              color: $color-orange;

              &:hover {
                text-decoration: underline;
              }

              &:last-child {
                color: $labelSuccessColor;
                pointer-events: none;
              }
            }
          }
        }

        textarea {
          width: 100%;
          min-height: 240px;
          margin-top: 4px;
        }
      }
    }
  }
}

@media screen and (max-width: $screen--l-max) {
  .widget {
    &__container {
      .bouwapp-box {
        width: 100%;
        min-width: 100%;
      }

      .widget__self {
        width: 100%;
        min-width: 100%;
        min-height: 600px;
        margin-top: 24px;
      }
    }

    &__form {
      > div:not(.widget__form__actions) {
        width: 100%;
        min-width: 100%;
      }

      &__actions {
        &__copy {
          > div {
            flex-direction: column;

            p {
              span {
                margin: 0 16px 0 0;
              }
            }
          }
        }
      }
    }
  }
}
