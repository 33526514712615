/*------------------------------------*\
    Footer
\*------------------------------------*/
app-footer {
  padding: 2.4rem;
  padding-top: 4rem;
  margin-top: auto;
  width: 100%;
  min-width: 100%;

  p {
    width: 100%;
    font-weight: $fontweight-regular;
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: rgba($color-dark, 0.3);
    text-align: center;

    a,
    a:link,
    a:visited {
      color: unset;
      font-weight: $fontweight-semibold;
      text-decoration: underline;
    }
  }
}

@media screen and (max-width: $screen--m-max) {
  app-footer {
    padding: 1.6rem;
  }
}
