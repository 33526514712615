.permissions-form {
  > div {
    display: flex;
    gap: 0.8rem 1.6rem;
    padding: 1.6rem 0;
    align-items: center;

    &:not(:last-child) {
      border-bottom: 1px solid $color-border;

      select {
        margin: 0;
        margin-left: auto;
        width: 30%;
      }
    }

    &:last-child {
      flex-wrap: wrap;

      .errors {
        width: 100%;
      }

      select {
        width: 50%;
      }
    }
  }

  .ng-select.custom {
    width: 50%;
  }

  &__button {
    float: right;
  }
}

.form-group {
  width: 100%;
}

.ng-select.custom {
  width: 100%;

  &.ng-select-opened {
    .ng-select-container {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .ng-select-container {
    border-radius: 8px;
    height: 50px;

    .ng-value-container {
      .ng-input {
        top: 0;
        line-height: 50px;
      }
    }
  }

  .ng-dropdown-panel {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;

    &-items {
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
      height: 240px;
    }
  }
}

label {
  display: inline-block;
  margin: 4px 0;
  color: $primaryTextColor;
  font-weight: $fontweight-regular;
  font-size: 1.6rem;

  &.label-lg {
    font-weight: $semiBoldFontWeight;
    line-height: 1.35;
    font-size: 2rem;
  }

  span {
    color: $color-grey;

    &.required {
      color: $color-red;
      margin-left: 0.4rem;
    }
  }
}

input[type='search']:not([type='checkbox']):not([type='radio']):not(
    .mapboxgl-ctrl-geocoder--input
  ) {
  /* required selector to avoid using !important */
  background-image: url('/assets/img/icons/search.svg');
  background-size: 17px 17px;
  background-repeat: no-repeat;
  background-position: 9px center;
  padding-left: 35px;

  &.input-lg {
    padding-left: 35px;
  }

  &.input-sm {
    padding-left: 33px;
  }
}

input[type='checkbox'] {
  display: none;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

input[type='checkbox'] + .checkbox-mark,
input[type='checkbox'] + label .checkbox-mark {
  border: 2px solid $inputBorder;
  width: 19px;
  height: 19px;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;

  @include round(2px);

  svg {
    margin-left: 1px;
    margin-top: 1px;
    display: none;
  }
}

input[type='checkbox']:checked + .checkbox-mark,
input[type='checkbox']:checked + label .checkbox-mark {
  svg {
    display: block;
  }
}

.input-group {
  display: flex;

  & > .form-group,
  & > * {
    flex: 1;

    input:not([type='checkbox']):not([type='radio']),
    textarea,
    select,
    & {
      @include round(0);
    }

    &:first-child {
      input:not([type='checkbox']):not([type='radio']),
      textarea,
      select,
      & {
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
        border-right: 0;
      }
    }

    &:last-child {
      input:not([type='checkbox']):not([type='radio']),
      textarea,
      select,
      & {
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
        border-left: 0;
      }
    }

    &:not(:last-of-type):not(:first-of-type) {
      input:not([type='checkbox']):not([type='radio']),
      textarea,
      select {
        border-left: 0;
        border-right: 0;
      }
    }
  }
}

app-input-file {
  .input-file {
    input[type='file'] {
      height: 100%;
    }
  }
}

.input-file {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed $color-border;
  height: 100%;
  width: 100%;
  transition: 0.25s all ease-out;
  border-radius: 8px;
  position: relative;
  padding: 0.8rem;
  flex-wrap: wrap;
  text-align: center;
  gap: 0.8rem;

  &--overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.7);
    z-index: 200;

    .spinner {
      margin: 0;
      background-image: url('/assets/img/icons/spinner-white.png');
    }
  }

  &.selected {
    border-style: solid;

    &:hover {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  &__clear {
    width: auto;
    display: block;
    position: absolute;
    right: 0.8rem;
    z-index: $removeUploadZIndex;
    opacity: 0;
    cursor: pointer;
  }

  &__add {
    svg {
      width: 2.8rem;
      height: 2.8rem;
      fill: $color-grey-light;
      transition: 0.25s all ease-out;
      display: block;

      path {
        fill: $color-grey-light;
      }
    }
  }

  svg:hover {
    opacity: 0.7;
  }

  > span {
    font-size: 1.4rem;
    color: $color-grey;
  }

  &:hover {
    border-style: solid;

    .input-file__clear {
      opacity: 1;
    }

    .input-file__add {
      svg {
        width: 3.2rem;
        height: 3.2rem;
      }
    }
  }

  input[type='file'] {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
    height: 100%;
    color: rgba(0, 0, 0, 0);
  }

  .input-file-icons {
    margin-bottom: 15px;

    .input-file-icon {
      margin-right: 5px;

      &,
      svg {
        height: 34px;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .input-file-instruction {
    font-weight: $semiBoldFontWeight;
    font-size: 16px;
    max-width: 275px;
    text-align: center;
    margin: auto;

    .input-file-video {
      margin-top: 25px;
      position: absolute;
      z-index: $videoUploadZIndex;
      width: 200px;
      left: 50%;
      transform: translateX(-100px);

      a {
        text-decoration: underline;
      }
    }

    & > .input-file-small-instruction {
      font-size: 13px;
      margin-top: 70px;
    }
  }

  &-loader {
    position: absolute;
    z-index: 400;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($color-white, 0.5);
    margin: 0;

    > .spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -16px 0 0 -16px;
    }
  }

  &.input-lg {
    label {
      font-size: 16px;
      padding: 10px 12px 9px 12px;
    }
  }

  &.input-sm {
    label {
      font-size: 13px;
      padding: 5px 12px 4px 12px;
    }
  }

  &.standalone {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    border-color: $color-border;
    cursor: pointer;

    &:hover {
      border-style: solid;

      background-color: unset;

      svg {
        width: 32px;
        height: 32px;
      }
    }
  }
}

.input-file-standalone {
  .spinner {
    margin-left: 10px;
    width: 14px;
    height: 14px;
    background-size: 100% 100%;
    background-image: url(/assets/img/icons/spinner-white.png);
  }

  label.btn {
    color: $white;
  }
}

.input-button-group {
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 1.6rem;
}

.input-file-preview {
  @include round(2px);
  background-color: $white;
  display: flex;
  align-items: center;
  justify-items: center;

  .input-file-preview-holder {
    width: 100%;
    text-align: center;
  }

  .input-file-preview-image {
    margin-bottom: 10px;
    max-height: 125px;
    max-width: 90%;
    display: inline-block;
  }

  .input-file-preview-video {
    margin-bottom: 10px;
    max-height: 125px;
    max-width: 90%;
    display: inline-block;
  }

  .input-file-preview-remove {
    cursor: pointer;

    img {
      margin-right: 5px;
    }

    &:hover {
      filter: brightness(95%);
    }
  }

  color: var(--primary);

  font-size: 18px;
  font-weight: $semiBoldFontWeight;
}

.inline-input__edit {
  &,
  svg {
    height: 15px;
  }
}

.inline-input {
  &:focus {
    outline: 0;
  }

  .preview--styles__placeholder {
    position: absolute;
    top: 0;
    pointer-events: none;
  }

  .preview {
    position: relative;

    .hide {
      visibility: hidden;
    }

    & > p,
    & > span {
      white-space: pre-wrap;
    }

    &.placeholder {
      opacity: 0.7;
      font-style: italic;
    }

    .input-field {
      position: absolute;
      display: block;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: none;
      border: 0;
      font-size: inherit;
      box-shadow: none;
      color: inherit;
      padding: 0;
      font-weight: inherit;
      margin: 0;
      opacity: inherit;
      height: 100%;

      min-width: 10px;
      min-height: $defaultLineHeight + em;

      &:focus {
        outline: 0;
      }
    }

    textarea.input-field {
      min-height: $paragraphLineHeight + em;
      resize: none;
      overflow: hidden;
    }
  }

  .preview__editable {
    overflow: hidden;

    &,
    &:focus,
    &:active {
      outline: 0;
    }
  }
}

.radio {
  margin-top: 5px;
  position: relative;

  input[type='radio'] {
    display: none;
    outline: 0;
  }

  input[type='radio'].disabled {
    pointer-events: none;
  }

  input[type='radio'].disabled + label {
    opacity: 0.5;
  }

  input[type='radio'].disabled + label:hover {
    cursor: not-allowed;

    &:before,
    &:after {
      cursor: not-allowed;
    }
  }

  input[type='radio'] + label {
    padding-left: 30px;

    cursor: pointer;

    &:before,
    &:after {
      width: 20px;
      height: 20px;
      content: ' ';
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;
      border: 2px solid $radioBorder;
      margin-right: 8px;
      flex-shrink: 0;

      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);

      @include round(50%);
    }

    &:after {
      display: none;
    }

    line-height: 20px;

    margin-right: 30px;
  }

  &.radio--condensed {
    input[type='radio'] + label {
      margin-right: 6px;
    }
  }

  input[type='radio']:checked + label {
    &:after {
      background: var(--primary);
      display: inline-block;
      position: absolute;
      left: 4px;
      border: 0;
      width: 12px;
      height: 12px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &.radio-sm {
    label {
      font-size: 13px;
    }

    input[type='radio']:checked + label {
      &:after {
        top: 8px;
      }
    }
  }
}

.radio-inline {
  .radio {
    display: inline-block;
  }
}

form[readonly] {
  .inline-input {
    pointer-events: none;
  }

  .edit,
  .input-file-standalone {
    display: none !important;
  }

  input,
  select {
    pointer-events: none;
  }

  .date-picker,
  .input-file,
  .toggle,
  .map-holder {
    pointer-events: none;
  }

  .upload {
    background: transparent !important;
  }
}

.textarea--styles {
  .preview__editable {
    min-height: 14rem;

    * {
      line-height: 1.3;
    }
  }
}

label.input-file-simple-label {
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-modal {
  input[type='file'] {
    opacity: 0;
    position: absolute;
    left: -10000px;
  }

  .progress {
    margin-bottom: 0;
    align-self: center;
    margin-right: 15px;

    .progress-bar {
      background-color: var(--primary);

      &[aria-valuenow='100'] {
        background-color: $brand-success;
      }
    }
  }
}

/*------------------------------------*\
    Checkbox
\*------------------------------------*/
.checkboxes > .checkbox {
  margin-bottom: 8px;
}

.checkbox {
  position: relative;
  padding-left: 30px;

  label {
    font-weight: $regularFontWeight;
    font-size: 1.6rem;
    line-height: 2.8rem;
    margin: 0;
    color: $color-dark;
    cursor: pointer;

    &:before {
      content: '';
      position: absolute;
      top: 2px;
      left: 0;
      width: 20px;
      height: 20px;
      border-radius: 4px;
      box-shadow: 0 0 0 1px $color-border;
    }
  }

  input:checked ~ label {
    color: $color-dark;

    &:before {
      background: $color-orange;
      box-shadow: 0 0 0 1px $color-orange;
      border: 3px solid $color-white;
    }

    &:hover {
      color: rgba($color-dark, 0.8);
    }
  }
}

.category-dropdown {
  .ng-select-container {
    height: 50px;
    border-radius: 8px;
    font-size: 16px;
    color: unset;

    .ng-value-container {
      padding-left: 12px;

      .ng-input {
        top: 0;
        height: 100%;

        > input {
          line-height: 50px;
          height: 100%;
        }
      }

      .ng-placeholder {
        color: rgba($color-dark, 0.5);
      }
    }
  }
}
