.two-columns {
  &__wrapper {
    display: flex;
    flex-direction: row;
    position: relative;

    .buttons {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 16px;
      margin-bottom: 24px;

      button {
        display: flex;
        align-items: center;
        gap: 8px;

        span {
          height: 24px;
          line-height: 24px;

          svg {
            width: 24px;
            height: 24px;
          }
        }
      }
    }

    &.full {
      .left {
        width: 100%;
      }

      .right {
        display: none;
      }
    }

    .left {
      width: calc(100% - 30rem);
      display: flex;
      flex-direction: column;
    }

    .right {
      width: 28.8rem;
      margin-left: 1.2rem;

      &.sticky {
        position: fixed;
        right: 3.4rem;
        top: 1.2rem;
      }
    }

    &.empty {
      .left {
        width: 100%;
      }

      .right {
        width: 0;
      }
    }
  }

  &__buttons {
    .publication {
      display: flex;
      flex-direction: row;
      margin: 16px 0;
      font-size: 16px;
      line-height: 24px;
      align-items: center;
      flex-wrap: wrap;
      color: $color-dark;

      &:first-of-type {
        margin-top: 0;
      }

      &:last-of-type {
        margin-bottom: 0;
      }

      span:first-child {
        width: 24px;
        height: 24px;
        margin-right: 8px;

        svg {
          width: 24px;
          height: 24px;
          fill: $color-dark;
        }
      }

      &.status {
        span:last-of-type {
          color: $statusGreen;
          margin-left: 8px;

          &.concept {
            color: var(--primary);
          }
        }
      }

      &.date {
        align-items: start;
        margin: 0;

        strong {
          display: block;
        }

        > span:last-of-type {
          width: calc(100% - 4rem);
          text-align: left;
        }

        span > span:last-of-type {
          cursor: pointer;
          color: $color-blue;
          font-size: 14px;
          font-weight: $regularFontWeight;
          margin-left: auto;

          &.cancel {
            color: $color-red;
          }
        }

        .custom {
          width: 100%;
          margin: 16px 0;
        }
      }

      &.notification {
        .push-status {
          width: 100%;
          display: block;
          font-size: 14px;
          font-weight: $fontweight-bold;
          margin-left: 32px;
        }

        > span {
          > span {
            font-size: 14px;
            font-weight: $fontweight-bold;
          }
        }

        app-toggle {
          margin-left: auto;
        }

        .push-disabled {
          margin-top: 8px;
          font-size: 14px;
          line-height: 20px;
          color: $color-grey-dark;
        }
      }

      &.targets {
        align-items: start;
        margin: 0;

        .target {
          max-height: 40vh;
          overflow-y: auto;
        }

        hr {
          margin: 10px 0;
          border: none;
        }

        > span:last-of-type {
          width: calc(100% - 4rem);
          text-align: left;
        }

        span > span:last-of-type {
          cursor: pointer;
          color: $color-blue;
          font-size: 14px;
          font-weight: $regularFontWeight;
          margin-left: auto;

          &.cancel {
            color: $color-red;
          }
        }

        .custom {
          width: 100%;
          margin: 16px 0;
        }

        .form-group.has-error label {
          color: $color-dark;
        }

        .errors {
          font-size: 14px;
        }

        .list {
          padding-left: 32px;
          margin-top: 8px;
          width: 100%;

          span {
            font-size: 14px;
            display: block;
            width: 100%;
          }
        }

        .checkbox {
          padding-left: 32px;

          label {
            font-size: 14px;
            line-height: 14px;

            &:before {
              top: 4px;
              left: 4px;
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $screen--l-max) {
  .two-columns {
    &__wrapper {
      flex-wrap: wrap;

      .left {
        width: 100%;
      }

      .right {
        width: 100%;
        margin-left: 0;

        &.sticky {
          position: static;
        }
      }
    }
  }
}
