span.label {
  &.label-primary,
  & {
    background-color: $labelPrimaryColor;
  }
  &.label-info {
    background-color: $labelInfoColor;
  }

  &.label-success {
    background-color: $labelSuccessColor;
  }

  &.label-secondary {
    background-color: $secondaryColor;
  }

  &.label-black {
    background-color: $color-black;
  }

  &.label-green {
    background-color: $color-read;
  }

  @include round(11px);

  color: $labelTextColor;
  display: inline-block;
  font-size: 13px;
  margin: 0 3px;
  padding: 2px 12px;
  vertical-align: middle;
  font-weight: $boldFontWeight;
}
