.project-picker {
  .modal-header {
    border-bottom: 1px solid $color-border;
    padding-bottom: 1.6rem;
  }

  .modal-content {
    &-inner {
      padding: 0;
    }
  }

  .bouwapp-toolbar {
    padding: 1.6rem;
  }

  .project {
    &-name {
      color: $color-dark;
      font-weight: $fontweight-bold;
    }

    &__list {
      padding: 0;
      list-style: none;
      margin: 0;
    }

    &__item {
      display: flex;
      padding: 10px 20px;
      border-bottom: 1px solid $color-border;

      &__header {
        margin-right: 10px;
        font-size: 16px;
        > p:nth-child(1) {
          color: $color-black;
        }

        > p {
          line-height: 1.4;
        }
      }

      &__button {
        margin-left: auto;
        display: flex;
        align-items: center;
        button {
          height: 40px;
          width: 160px;
          display: flex;
          align-items: center;
          .icon {
            padding-left: 5px;
            display: flex;
          }
          svg {
            fill: $color-orange;
          }
          margin-left: auto;
        }
        button:hover {
          svg {
            fill: white;
          }
        }
      }
    }
  }
}
