.satisfaction-monitor {
  .alert {
    &.on-screen {
      top: 20px;
      bottom: auto;
    }
  }

  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__list {
    .modal {
      &-content {
        max-width: unset;
        width: unset;

        .modal-header {
          .modal-title {
            padding-right: 40px;
          }
        }
      }
    }
  }

  .heading {
    display: flex;
    flex-direction: row;
    align-items: center;

    > h2 {
      margin: 0;
    }

    > div {
      margin-left: auto;
    }
  }

  .charts {
    background-color: $color-white;
    padding: 20px;
    border-radius: 12px;

    margin-bottom: 40px;
  }

  .chart {
    > h4 {
      margin: 0 0 50px 0;
    }
    canvas {
      margin: auto;
    }

    .checkboxes {
      display: flex;
      align-items: center;
      justify-content: center;
      .checkbox {
        margin: 20px;
      }
    }
  }

  .period {
    margin-bottom: 24px;

    .option {
      margin-top: 48px;
    }

    .manual {
      font-size: 20px;
      font-weight: $semiBoldFontWeight;
      color: $secondarySelectTextColor;
      margin-top: 24px;
    }

    .switch {
      .switch-option {
        flex: 0 1 auto;
        padding: 0 20px;
        margin: 0 5px;
        align-items: center;
        justify-content: center;
        display: flex;

        border-width: 1px;
        border-color: transparent;
        border-radius: 0.8rem;

        box-shadow: inset 0 0 0 0.1rem $color-dark;
        color: $color-dark;

        &.active,
        &:hover {
          background: $color-dark;
          color: $color-white;
        }

        &:last-child {
          &.active {
            cursor: pointer;
          }
        }
      }
    }
  }

  .settings {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2.5rem;
  }

  .push-notification {
    margin-top: 48px;

    .push {
      margin-top: 16px;

      .disabled {
        display: block;
        margin-top: 24px;
        color: rgba($primaryTextColor, 0.4);

        &[hidden] {
          display: none;
        }
      }
    }
  }

  &__modal__button {
    margin-left: auto;
    &.disabled:hover {
      cursor: not-allowed;
    }
  }

  .targets {
    margin: 2rem;
    .target {
      > div {
        display: grid;
        grid-auto-flow: column;
        grid-template-rows: repeat(5, auto);
        gap: 5px;
      }
    }

    &.not-selected {
      display: none;
    }
  }
}
@media screen and (max-width: $screen--l-max) {
  .satisfaction-monitor {
    &__list {
      width: 100%;
      min-width: 100%;
      padding: 0;
    }
  }
}

@media screen and (max-width: $screen--m-max) {
  .satisfaction-monitor {
    .heading {
      flex-direction: column;

      > div {
        margin: 16px 0 0 0;
        display: flex;
        flex-direction: column;
        width: 100%;

        > button {
          width: 100%;
          font-size: 16px;
          padding: 8px 24px;
          margin: 0 0 12px 0;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .period {
      .manual {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        margin-top: 24px;
        font-size: 16px;

        > span {
          width: 40px;
        }

        > .ng-valid {
          width: calc(100% - 40px);
        }
      }
    }

    .settings {
      width: 100%;

      > .ng-valid {
        width: 100%;
      }
    }
  }
}

.configure-satisfaction-monitor {
  .alert {
    border: 0;
    border-radius: 12px;

    background-color: $color-border;

    &.alert-info:before {
      background-image: url('/assets/img/icons/alert.svg');
      top: auto;
      height: 50%;
      background-position: center;
    }
  }

  .themes {
    margin-top: 32px;
    background-color: $color-white;
    padding: 2rem;
    border-radius: 1.2rem;

    > h4 {
      padding: 0 2rem 2rem 2rem;
      margin: 0 -2rem;

      border-bottom: 1px solid $color-border;
    }

    > p {
      margin-top: 2rem;
    }

    .survey-notice {
      background-color: #fef7ec;
      padding: 5px 10px;
      font-size: 90%;
      color: $color-black;
      border-radius: 5px;
    }

    .search {
      margin-top: 10px;
    }

    .items {
      margin: 8px 0;
      display: flex;
      flex-wrap: wrap;
      margin: 0 -8px;

      &.empty {
        justify-content: center;
      }

      > p {
        margin: 4rem;
      }

      .item {
        margin: 8px;
        background-color: $color-white;
        color: $color-black;
        border: 1px solid $color-black;
        cursor: pointer;
        display: flex;
        align-items: center;
        border-radius: 6px;
        line-height: 24px;
        padding: 12px 24px;

        .title,
        .history {
          width: 100%;
          text-align: center;
          display: block;
        }

        .text {
          flex: 1;
        }

        .title {
          font-size: 16px;
          font-weight: $mediumFontWeight;
        }

        &[disabled] {
          pointer-events: none;
        }

        .history {
          font-size: 14px;
          font-weight: $mediumFontWeight;
        }

        &.active {
          background-color: var(--primary);
          border-color: var(--primary);
          color: $color-white;
        }

        &.required {
          opacity: 0.6;
        }
      }
    }
  }
}

@media screen and (max-width: $screen--m-max) {
  .configure-satisfaction-monitor {
    .themes {
      .items {
        margin: 12px 0;
        margin: 0 -4px;

        .item {
          padding: 16px;
          width: 50%;
          margin: 4px;
          height: auto;
        }
      }
    }
  }
}
