/*------------------------------------*\
    Phases - Overview
\*------------------------------------*/
.bouwapp-phases {
  &__start,
  &__end {
    margin-left: 0.1rem;

    > span {
      height: 2.4rem;
      line-height: 2.4rem;
      vertical-align: middle;
      margin-right: 0.8rem;
      font-size: 1.6rem;
      color: $color-dark;
      font-weight: $fontweight-medium;

      svg {
        width: 2.4rem;
        height: 2.4rem;
        fill: $color-dark;
      }
    }

    a {
      line-height: 2.4rem;
      vertical-align: middle;
    }

    & + .bouwapp-phases__item {
      &:after {
        content: '';
        position: absolute;
        top: -2.4rem;
        left: -2rem;
        width: 0.2rem;
        height: 4rem;
        border: 0.1rem dashed $color-grey-light;
      }
    }
  }

  &__end {
    margin-top: 4rem;
  }

  &__item {
    position: relative;
    margin-left: 3.2rem;
    $self: &;

    .phase,
    .event {
      &:before {
        content: '';
        position: absolute;
        top: 1.6rem;
        left: -2.6rem;
        width: 1rem;
        height: 1rem;
        background: $color-white;
        border: 0.2rem solid $color-dark;
        border-radius: 50%;
      }
    }

    .event:before {
      left: -2.4rem;
    }

    .event {
      position: relative;

      &__content {
        border: 1px solid $color-grey;
      }
    }

    .phase {
      border: 2px solid $color-border;

      & + .events > .event:first-child {
        &:after {
          content: '';
          position: absolute;
          top: -1.6rem;
          left: -1.4rem;
          width: 0.2rem;
          height: 2.8rem;
          border: 0.1rem dashed $color-grey-light;
        }
      }
    }

    .phase,
    .event__content {
      margin: 1.6rem 0;
      background: $color-white;
      padding: 2.4rem;
      border-radius: 1.6rem;
      position: relative;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: start;

      .left {
        .current {
          margin-bottom: 0.8rem;

          span {
            color: $color-red;
            font-size: 1.4rem;
            line-height: 1.8rem;
            font-weight: $fontweight-bold;
            height: 1.8rem;
            vertical-align: middle;
            display: inline-block;

            &:first-child {
              margin-right: 0.8rem;
            }

            svg {
              fill: $color-red;
            }
          }
        }

        h3 {
          margin: 0;
          margin-bottom: 0.4rem;
          font-size: 2rem;
          line-height: 2.6rem;
          font-weight: $fontweight-semibold;
        }

        p {
          font-weight: 300;
          font-size: 1.6rem;
          line-height: 2.4rem;
          margin-top: 1.2rem;
        }

        span {
          display: block;
          font-size: 1.4rem;
          line-height: 2.2rem;
          color: $color-blue;
        }

        > div > span {
          color: $color-grey;
          font-weight: $fontweight-bold;
        }
      }

      .right {
        min-width: 10rem;
        margin-left: auto;
        text-align: right;

        > a {
          margin-left: 0.8rem;

          &:first-child {
            color: $color-grey;
            font-weight: $fontweight-regular;
          }

          &:last-child {
            color: var(--primary);
            font-weight: $fontweight-semibold;
          }
        }

        > span {
          display: block;
          padding: 0.4rem 0.8rem;
          border-radius: 6px;
          text-align: center;
          margin-top: 1.6rem;
          font-size: 1.4rem;
          font-weight: $fontweight-semibold;

          &.upcoming {
            border: 1px solid var(--primary);
            color: var(--primary);
          }

          &.current {
            background-color: $color-red;
            color: $color-white;
          }
        }
      }
    }

    &.past {
      margin-top: 1.6rem;
      margin-bottom: 1.6rem;

      button {
        background-color: transparent;
        width: 100%;
        border: 1px dashed $color-dark;
        color: $color-dark;
        padding: 1rem 0;
        border-radius: 0.8rem;
        transition: 0.25s all ease;

        span {
          vertical-align: middle;
          display: inline-block;
          height: 1.6rem;
          font-size: 1.4rem;
          line-height: 1.6rem;
          font-weight: $fontweight-semibold;

          &:first-child {
            margin-right: 0.4rem;
            margin-bottom: 0.1rem;
          }

          svg {
            height: 100%;
            fill: $color-dark;
          }
        }

        &:hover {
          background-color: $color-dark;
          color: $color-white;

          svg {
            fill: $color-white;
          }
        }

        &:before {
          content: '';
          position: absolute;
          top: 1.8rem;
          left: -2.4rem;
          width: 1rem;
          height: 1rem;
          background: #fff;
          border: 0.2rem solid $color-dark;
          border-radius: 50%;
        }
      }
    }

    &:first-child:after {
      content: '';
      position: absolute;
      top: -1rem;
      left: -2rem;
      width: 0.2rem;
      height: 2.8rem;
      border: 0.1rem dashed $color-grey-light;
    }

    &:not(:last-child):before {
      content: '';
      position: absolute;
      top: 1.8rem;
      left: -2rem;
      width: 0.2rem;
      height: calc(100% + 1.6rem);
      border: 0.1rem dashed $color-grey-light;
    }

    .phase + .events > .event {
      margin: 1.6rem 0 1.6rem 4.8rem;

      &:not(:last-child) {
        .event__content {
          &:before {
            content: '';
            position: absolute;
            top: 2.2rem;
            left: -1.5rem;
            width: 0.2rem;
            height: calc(100% + 1.8rem);
            border: 0.1rem dashed $color-grey-light;
          }
        }
      }

      &:before {
        content: '';
        position: absolute;
        top: 1.4rem;
        left: -1.8rem;
        width: 1rem;
        height: 1rem;
        background: #fff;
        border: 0.2rem solid $color-dark;
        border-radius: 50%;
      }

      > span {
        display: block;

        &:first-letter {
          text-transform: uppercase;
        }
      }
    }

    &.first {
      .phase,
      .phase:before {
        border-color: $color-dark;
      }

      .phase + .events > .event {
        &:before,
        &:after,
        .event__content,
        .event__content:before {
          border-color: $color-dark;
        }
      }
    }

    &.second {
      .phase,
      .phase:before {
        border-color: $color-blue;
      }

      .phase + .events > .event {
        &:before,
        &:after,
        .event__content,
        .event__content:before {
          border-color: $color-blue;
        }
      }
    }

    &.third {
      .phase,
      .phase:before {
        border-color: $color-red-light;
      }

      .phase + .events > .event {
        &:before,
        &:after,
        .event__content,
        .event__content:before {
          border-color: $color-red-light;
        }
      }
    }
  }
}

@mixin update-button {
  background-color: $color-white;
  color: $color-dark;
  border-radius: 10px;
  border: 1px solid $color-dark;
  box-shadow: none;
  margin: 0;
  margin-right: 10px;
  padding: 8px 12px;
  display: flex;
  flex-direction: row;
  align-items: center;

  &[disabled]:not(.back).inactive {
    background-color: $color-white;
    opacity: 0.5;
    border-color: $color-dark;

    p,
    span {
      color: $color-dark;
    }

    svg,
    path {
      fill: $color-dark;
    }
  }

  p {
    line-height: 15px;
    line-height: 18px;
    font-size: 15px;
    color: $color-dark;
    margin: 0;
  }

  span {
    width: 15px;
    height: 18px;
    margin-right: 8px;

    &.caret {
      margin-left: 8px;
      margin-right: 0;
    }

    svg {
      width: 100%;
      height: 100%;
      fill: $color-dark;
    }

    &.magic-wand svg {
      fill: $color-white;
    }

    &.stylus svg {
      fill: $color-black;
    }

    &.add svg {
      fill: $color-dark;
    }
  }

  &:hover {
    background-color: $color-dark;

    svg {
      fill: $color-white;
    }

    p {
      color: $color-white;
    }

    .magic-wand svg {
      fill: $color-dark;

      path {
        stroke: $color-white;
      }
    }

    .spellcheck svg path {
      fill: $color-white;
    }

    .add svg path {
      fill: $color-white;
    }

    .undo svg {
      fill: $color-white;
    }
  }

  &[disabled]:not(.back) {
    background-color: $primaryColor;
    opacity: 1;
    border-color: $primaryColor;

    p {
      color: $color-white;
    }

    span polygon {
      fill: $color-white;
    }

    span svg {
      fill: $color-white;
    }

    .magic-wand svg {
      path {
        fill: $color-white;
        stroke: $color-dark;
      }
    }
  }
}

.new-phase {
  .form-split {
    margin-top: 1.6rem;
  }
}
