.livestreams {
  border: 1px solid $color-border;
  border-radius: 0.8rem;
  overflow: hidden;
  max-width: 1024px;

  &__head {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    list-style-type: none;
    margin: 0;
    padding: 0;
    background-color: $color-grey-box;
    border-bottom: 1px solid $color-border;
    padding: 0.8rem 1.6rem;
    gap: 0.8rem 1.6rem;

    li {
      list-style-type: none;
      width: 100px;
      font-size: 1.4rem;
      font-weight: $fontweight-semibold;
      color: $color-dark;

      &:first-child {
        width: 80px;
      }

      &:nth-child(2) {
        width: 150px;
      }

      &:nth-child(3) {
        width: 250px;
      }

      &:nth-child(4),
      &:nth-child(5) {
        width: 125px;
      }
    }
  }

  table {
    display: flex;
    flex-direction: column;

    tbody {
      tr {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        box-shadow: none;
        padding: 1.6rem;
        gap: 0.8rem 1.6rem;

        &:not(:last-child) {
          border-bottom: 1px solid $color-border;
        }

        > td {
          padding: 0;
          width: 100px;
          background: none;
          font-size: 1.4rem;
          line-height: 1.8rem;
          font-weight: $fontweight-light;
          color: $color-dark;
          word-wrap: break-word;

          > span {
            color: $color-grey;
          }

          span.livestream-status {
            color: $color-white;
            padding: 0.2rem 0.6rem;
            font-size: 1.2rem;
            font-weight: $fontweight-semibold;
            border-radius: 0.8rem;
            background-color: $color-dark;
            display: block;
            width: fit-content;

            &.active {
              background-color: $color-read;
            }
          }

          &:first-child {
            width: 80px;
          }

          &:nth-child(2) {
            width: 150px;
          }

          &:nth-child(3) {
            width: 250px;
          }

          &:nth-child(4),
          &:nth-child(5) {
            width: 125px;
          }

          &:last-child {
            margin-left: auto;
            position: relative;
            display: flex;
            justify-content: end;
            text-align: end;
            width: fit-content;

            div {
              display: flex;
              gap: 0.4rem;

              .tile-action {
                cursor: pointer;

                &:last-child {
                  font-weight: $fontweight-semibold;
                  color: var(--primary);
                }

                &:hover {
                  opacity: 0.7;
                }
              }
            }
          }
        }
      }
    }
  }

  &__modal {
    form {
      button {
        margin: 0 10px;
      }
    }
  }
}

section.projects {
  .live-stream {
    &__container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;

      margin-top: 10px;
    }

    &__video {
      width: 90%;
    }

    &__error {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: rgba(255, 0, 0, 0.8);
      color: white;
      padding: 10px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
    }
  }
}

@media screen and (max-width: $screen--m-max) {
  .livestreams {
    .account {
      ul {
        display: none;
      }
      table {
        tbody {
          tr {
            display: flex;
            flex-direction: column;
            border-bottom: 1px solid $color-border;
            margin-bottom: 16px;

            > td {
              width: 100%;
              min-width: 100%;
              padding: 16px 16px 0 16px;

              &:before {
                content: attr(data-th);
                display: block;
                font-weight: $fontweight-semibold;
                color: $color-dark;
              }
              &:last-child {
                &:before {
                  display: none;
                }

                justify-content: start;
                width: 100%;
                min-width: 100%;
                padding-bottom: 16px;
              }
            }
          }
        }
      }
    }
  }
}
