/*------------------------------------*\
    Filter
\*------------------------------------*/

.filter {
  display: flex;

  .ng-select {
    width: 250px;
    max-width: 100%;

    &.ng-select-multiple {
      .ng-select-container {
        .ng-value-container {
          .ng-input {
            flex: 1 1 100%;
          }
        }
      }
    }

    .ng-arrow-wrapper {
      width: 20px;
      padding: 0;
    }

    .ng-select-container {
      padding: 0.8rem;
      border: 0.1rem solid $color-border;

      .ng-value-container {
        padding: 0;

        .ng-placeholder {
          top: 1rem;
          padding: 0;
        }

        .ng-input {
          padding: 0;

          > input {
            margin: 0;
          }
        }
      }
    }
  }

  &.isHorizontal {
    flex-direction: column;

    .filter {
      &__wrapper {
        width: 100%;
        min-width: 100%;
      }

      &__content {
        width: 100%;
        max-width: 100%;
        margin-left: 0;
      }
    }

    .checkboxes {
      display: flex;
      flex-wrap: wrap;
      gap: 0.4rem 0.8rem;

      .checkbox {
        padding-right: 0.6rem;
      }
    }
  }

  &__content {
    margin-left: 2.4rem;
    width: calc(100% - 240px);
    max-width: calc(100% - 240px);
  }

  &__wrapper {
    width: 240px;
    min-width: 240px;
    background-color: $color-white;
    border-radius: 12px;
    border: 1px solid $color-border;
    height: fit-content;

    .filter__group {
      padding: 1.6rem 2.4rem;
      max-height: 32rem;
      overflow-y: auto;

      &:not(:last-child) {
        border-bottom: 1px solid $color-border;
      }

      input {
        padding: 0.8rem 1.2rem;
        color: $color-grey;
        font-weight: $fontweight-medium;
        border: 0.1rem solid $color-border;
        border-radius: 0.8rem;
        margin-bottom: 0.8rem;
      }
    }

    .filter__title {
      padding: 1.6rem 2.4rem;
      border-bottom: 1px solid $color-border;

      h3 {
        margin: 0;
        font-size: 1.8rem;
        font-weight: $fontweight-bold;
      }
    }

    .show-more {
      background: none;
      border: none;
      color: $color-grey;
      padding: 0;
      text-decoration: none;
      cursor: pointer;
      font-size: inherit;
    }
    .show-more:hover {
      text-decoration: underline;
    }

    .hidden {
      display: none;
    }

    .filter__label {
      display: block;
      font-weight: $fontweight-medium;
      font-size: 1.8rem;
      line-height: 3rem;
      color: $color-dark;
      margin-bottom: 8px;

      > span {
        margin-left: 5px;
        color: var(--primary);
        font-size: 14px;
        cursor: pointer;
      }
    }
  }
}
@media screen and (max-width: $screen--l-max) {
  .filter {
    flex-direction: column;
    &__wrapper {
      width: 100%;
      min-width: 100%;

      .filter__group {
        > .dates {
          display: flex;
          flex-direction: column;

          input {
            width: 250px;
            max-width: 100%;
          }
        }

        > .checkboxes {
          display: flex;
          flex-direction: row;
          align-items: center;
          flex-wrap: wrap;

          > .checkbox {
            margin-right: 16px;
          }
        }
      }
    }

    &__content {
      margin: auto;
      width: 100%;
      min-width: 100%;
    }
  }
}

@media screen and (min-width: $screen--l-min) {
  .ng-select.ng-select-multiple .ng-select-container .ng-value-container {
    max-width: 80%;

    .ng-value {
      overflow: hidden;
    }
  }
}
