table {
  width: 100%;

  border-collapse: separate;
  border-spacing: 0 15px;

  thead {
    th {
      font-weight: $boldFontWeight;
      color: $secondaryColor;
      text-align: left;
      padding: 12px 7px 0 7px;
      font-size: 15px;
    }
  }

  tbody {
    /* inside tbody to prevent box shadow on th's */
    tr {
      @include shadow(0, 2px, 10px, false, 0.1);

      td {
        padding: 17px;
        background-color: $white;
      }
    }
  }
}
