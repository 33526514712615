.dialog-status {
  width: calc(100% - 30px);
  padding: 10px 15px;
  margin-bottom: 5px;
  margin-left: 15px;
  margin-right: 15px;
  background: $statusBackground;
  @include round(3px);
  display: flex;
  align-items: center;
}

.dialog-status__logo {
  margin-right: 1rem;
}
