.audit-log {
  &__wrapper {
    > p {
      font-weight: bold;
      font-size: 1.6rem;
      line-height: 2.4rem;
      color: $color-dark;
      margin-bottom: 0.8rem;
    }
  }

  &__list {
    ul.head,
    ul.body {
      padding: 0;
      margin: 0;
      list-style-type: none;

      > li {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        padding: 0.4rem;

        > span {
          overflow: hidden;
          padding: 0.4rem;
          max-height: 6rem;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          width: calc(100% / 6);
          line-clamp: 3;
          overflow: hidden;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
        }
      }
    }

    ul.head {
      li {
        span {
          font-weight: $fontweight-bold;
          font-size: 14px;
          color: $color-black;
        }
      }
      li:first-child {
        background-color: $color-grey-box;
      }
    }

    ul.body {
      li {
        cursor: pointer;
        background: $color-white;
        transition: 260ms ease;
        font-weight: $fontweight-light;
        font-size: 14px;
        color: $color-dark;

        &:not(:last-child) {
          border-bottom: 1px solid $color-border;
        }

        span.label {
          margin: 0;
          font-size: 1.2rem;
          padding: 0.4rem 1.2rem;
          border-radius: 0.4rem;
        }
      }
    }
  }
}

@media screen and (min-width: $screen--m-min) and (max-width: $screen--l-max) {
  .audit-log {
    &__wrapper {
      > p {
        margin-top: 0.8rem;
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: $screen--m-max) {
  .audit-log {
    &__wrapper {
      > p {
        margin-top: 0.8rem;
        text-align: center;
      }
    }

    &__list {
      ul.body,
      ul.head {
        > li {
          > span {
            width: 20%;

            &:first-child {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
