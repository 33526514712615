.menu {
  list-style-type: none;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  padding: 0;

  li {
    display: block;
    height: 70px; /* has to be hardcoded, because of the 'active' border */
    vertical-align: middle;
    margin: 0 9px;
    font-weight: $semiBoldFontWeight;
    font-size: 16px;

    .label[hidden] {
      display: none;
    }

    a {
      display: block;
      flex-direction: column;
      position: relative;
      height: inherit;
      font-weight: inherit;
      font-size: inherit;
      padding: 24px 0;
      white-space: nowrap;
      transition: color 260ms ease;

      &,
      &:link,
      &:active,
      &:visited,
      &:hover {
        color: $color-grey;
      }

      &:hover {
        border-bottom: 1px solid var(--primary);
      }

      .icon {
        display: inline-flex;
        margin-right: 5px;
        vertical-align: middle;

        &,
        svg {
          width: 15px;
          height: 15px;
        }
      }
    }

    .sub-title {
      position: absolute;
      font-size: 11px;
      color: $secondaryColor;
      margin-top: 20px;
    }

    &.active {
      a {
        color: $color-dark;
        border-bottom: 3px solid var(--primary);
      }
    }

    &.separator {
      width: 1px;
      background-color: $separator;
      margin-right: 0;

      &.full {
        margin-right: 14px;
      }
    }

    &.account {
      cursor: pointer;
      padding-top: 5px;
      margin-left: 0;
      margin-right: 0;
    }

    &.dropdown {
      position: relative;
      min-width: 220px;

      .triangle {
        display: inline-block;
        background-image: url('/assets/img/icons/chevron-down.png');
        background-repeat: no-repeat;
        background-position: center center;
        width: 10px;
        height: 5px;
        margin-left: 3px;
        margin-right: 10px;
      }

      .toggle {
        width: 100%;
        height: 70px;
        position: absolute;
      }

      ul {
        position: relative; /* can't be absolute because of the box shadows */
        height: auto;
        background-color: $headerBackground;
        text-align: left;
        max-width: 100%;
        z-index: $dropdownMenuZIndex;
        padding: 0;
        margin: 0;
        display: none;

        li {
          font-weight: bold;
          border-top: 1px solid $menuBorder;
          width: 100%;
          display: block;
          margin: 0;
          height: auto;

          &.active a {
            background-color: darken($headerBackground, 1%);
          }

          a {
            padding: 15px 20px;
            height: auto;

            &,
            &:hover {
              border-bottom: 0;
            }

            &:hover {
              background-color: darken($headerBackground, 1%);
            }
          }
        }
      }

      &:hover {
        height: auto;
        @include shadow(0, 2px, 10px, false, 0.14);

        .triangle {
          background-image: url('/assets/img/icons/chevron-up.png');
        }

        ul {
          display: block;
        }
      }
    }
  }
}
