/*------------------------------------*\
    Conversation (messaging)
\*------------------------------------*/

.app {
  &.conversations__content {
    pointer-events: none;
  }

  &__content {
    &__image-modal {
      &__wrapper {
        z-index: 2001;
        pointer-events: all;
      }
    }
  }
}

section.tickets.conversations {
  position: fixed;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: row-reverse;
  bottom: 0;
  width: 100%;
  pointer-events: none;

  &__overlay {
    position: fixed;
    z-index: 2000;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba($color-black, 0.6);
  }

  app-modal {
    pointer-events: all;
  }

  .conversation {
    $self: &;
    z-index: $chatZIndex;
    pointer-events: auto;
    display: flex;
    flex-direction: column;
    width: 500px;
    height: 100vh;
    max-height: 100vh;
    background: $white;
    overflow-y: scroll;
    overflow-x: hidden;
    position: relative;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
      display: none;
    }

    .form-group {
      margin: 0;
    }

    &__header {
      border-bottom: 1px solid $color-border;
      background: $color-white;

      &__top {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 16px 12px 16px 16px;

        &__head {
          p {
            font-size: 18px;
            line-height: 22px;
            font-weight: $fontweight-semibold;
            color: $color-dark;
            margin: 0;
          }
          span {
            font-size: 14px;
          }
        }

        &__icons {
          display: flex;
          flex-direction: row;
          margin-left: auto;

          > div {
            position: relative;
            width: 32px;
            height: 32px;
            text-align: center;
            cursor: pointer;

            &:not(:first-child) {
              margin-left: 8px;
            }

            svg {
              fill: $color-grey;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              transform-origin: center;
              transition: 260ms ease;

              path,
              polygon {
                transition: 260ms ease;
              }
            }

            &.close:hover {
              svg polygon {
                fill: $color-dark;
              }
            }

            &.fullscreen:hover,
            &.normalscreen:hover,
            &.minimize:hover {
              svg path,
              svg polygon {
                fill: $color-dark;
              }
            }
          }
        }
      }

      &__type {
        margin-top: -8px;
        padding: 0 16px 16px 16px;

        > p {
          font-size: 14px;
          font-weight: $fontweight-semibold;
          color: $color-dark;
          cursor: pointer;
          margin: 0;

          span {
            color: $color-red;
            font-weight: $fontweight-semibold;
          }
        }

        &__options {
          .radio {
            margin-top: 0;

            &:not(:first-child) {
              margin-left: 12px;
            }

            input[type='radio'] + label {
              position: relative;
              font-size: 14px;
              font-weight: $regularFontWeight;
              line-height: 18px;
              color: $color-dark;
              margin: 0;
              transition: 260ms ease;

              &:hover {
                color: rgba($color-dark, 0.6);

                &:before {
                  border-color: rgba($color-grey, 0.8);
                }
              }

              &:before {
                width: 18px;
                height: 18px;
                border: 1px solid $color-grey;
                margin: -2px 8px 0 0;
                transition: 260ms ease;
              }

              &:after {
                width: 12px;
                height: 12px;
                top: 7px;
                left: 3px;
                background: $color-orange;
                transition: 260ms ease;
              }
            }

            input[type='radio']:checked + label {
              &:before {
                border-color: $color-orange;
              }
            }
          }
        }

        &__notice {
          position: relative;
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: 16px;
          padding: 13px 16px 12px 52px;
          background: rgba($color-red, 0.2);
          border-radius: 4px;

          span {
            display: block;
            width: 20px;
            height: 20px;
            position: absolute;
            top: 50%;
            left: 16px;
            transform: translateY(-50%);

            svg {
              width: 20px;
              height: 20px;
            }
          }

          p {
            font-size: 14px;
            font-weight: $fontweight-semibold;
            color: $color-red;
            margin: 0;
          }
        }
      }
    }

    &__messages {
      position: relative;
      overflow-y: auto;
      overflow-x: hidden;
      flex-grow: 1;

      .messages {
        display: flex;
        flex-direction: column-reverse;
        flex-grow: 1;
        padding: 16px 24px;

        .closed {
          width: calc(100% + 48px);
          text-align: center;
          font-weight: $semiBoldFontWeight;
          margin: 12px 0 12px -24px;
          padding-top: 24px;
          border-top: 1px solid $color-border;

          .feedback {
            margin-top: 16px;

            > .button {
              margin: 0 auto;
            }

            .awaiting-feedback,
            .given-feedback-label {
              font-size: 13px;
              color: $secondaryColor;
            }

            .given-feedback-label {
              margin-bottom: 0.8rem;
            }

            .given-feedback-result {
              &,
              svg {
                height: 60px;
              }
            }

            .given-feedback {
              display: flex;
              flex-direction: column;
              justify-content: center;
            }
          }
        }

        .loader {
          margin-top: 0;
        }

        .message {
          position: relative;
          padding: 8px 12px 24px 12px;
          margin: 0 0 16px 0;
          border-radius: 4px;
          width: 100%;
          max-width: calc(100% - 32px);

          &__head {
            display: flex;
            span.name {
              color: $color-dark;
              font-size: 14px;
              font-weight: $fontweight-bold;
            }

            &__icon {
              cursor: pointer;
              margin-left: auto;
              display: flex;
              align-items: center;
              span {
                margin-left: 5px;
                font-size: 12px;
                color: $color-dark;
                svg {
                  width: 16px;
                  height: 16px;
                }
              }
            }

            .has-inline-spinner {
              margin-left: 12px;
            }
          } /* Lorem Ipsum is simply dummy text of the printing and typesetting industry. */

          &__content {
            white-space: pre-line;
            font-weight: $fontweight-light;
            font-size: 14px;
            line-height: 24px;
            margin-top: 2px;
            word-break: break-word;
            color: $color-dark;

            strong {
              font-weight: $fontweight-bold;
            }

            p {
              word-break: break-word;
              color: $color-dark;
            }

            a {
              font-weight: $fontweight-semibold;
              color: $color-blue-dark;
              text-decoration: underline;
            }

            img {
              width: 100%;
              max-width: 100%;
              height: 130px;
              object-fit: cover;
              border-radius: 4px;
              border: 1px solid $color-white;
              margin: 4px 0;
            }
          }

          &__date {
            position: absolute;
            bottom: 4px;
            right: 8px;
            color: $color-grey;
            font-size: 10px;
            font-weight: $fontweight-light;
            text-align: right;
          }

          &__attachment {
            width: 100%;
            height: 132px;
            border-radius: 4px;
            overflow: hidden;
            margin: 4px 0;

            > div {
              width: 100%;
              max-width: 100%;
              height: 100%;
              border-radius: 4px;
              border: 1px solid $color-white;
              cursor: pointer;
              margin: 0;
              background-size: cover;
              background-position: center;
            }
          }

          // Messages send by user
          &.device {
            background: $color-chat-device;
            margin-right: auto;

            .message__head {
              span.name {
                cursor: pointer;
                color: $color-dark;

                &:hover {
                  text-decoration: underline;
                }
              }

              .has-inline-spinner:before {
                background-image: url('/assets/img/icons/spinner.png') !important;
              }
            }
          }

          // Messages send by admin
          &.support {
            background: $color-yellow-light;
            margin-left: auto;
          }

          &.animate {
            animation: fade 0.3s ease-in;
          }
        }
      }
    }

    &__bottom {
      margin-top: auto;
      width: 100%;
      position: relative;

      &__attachment {
        position: absolute;
        top: -40px;
        right: 0;
        left: 0;
        width: 100%;
        height: 40px;
        background: $color-white;
        border-top: 1px solid $color-border;
        font-size: 14px;
        line-height: 40px;
        color: $color-dark;
        padding: 0 12px;
        box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.08);
      }

      &__toolbar {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 42px;
        border-top: 1px solid $color-border;
        border-bottom: 1px solid $color-border;

        &__markup {
          padding: 0;
          border: 0;

          > button {
            width: 40px;
            height: 40px;
            border-right: 1px solid $color-border;
            border-radius: 0;
            box-shadow: none;
            text-align: center;
            padding: 10px !important;
            float: none;
            margin: 0;
            background: $color-white;
            transition: 260ms ease;

            svg {
              width: 20px;
              height: 20px;
              transition: 260ms ease;
            }

            &:hover {
              background: rgba($color-border, 0.1);
            }
          }
        }

        &__image {
          .input-file {
            position: relative;
            margin: 0;
            width: 40px;
            min-width: 40px;
            height: 40px;
            border: 0;
            border-right: 1px solid $color-border;
            cursor: pointer;
            background: $color-white;
            transition: 260ms ease;

            &:hover {
              background: rgba($color-border, 0.1);
            }

            &:after {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              width: 40px;
              height: 40px;
              background-image: url('/assets/img/icons/upload-image.svg');
              background-size: 18px;
              background-repeat: no-repeat;
              background-position: center;
              pointer-events: none;
            }

            > div {
              width: 100%;
              height: 100%;
              padding: 0;
            }

            .input-file-instruction,
            .input-file-icons {
              display: none;
            }

            .input-file-loader {
              position: absolute;
              z-index: 2;
              top: 0;
              left: 0;
              width: 36px;
              height: 36px;
              background: $color-white;
              margin: 0;
              padding: 3px;
              pointer-events: none;
            }

            input[type='file'] {
              width: 100%;
              height: 100%;
              margin: 0;
              padding: 0;
            }
          }
        }

        &__status {
          position: relative;
          width: 140px;
          margin-left: auto;
          margin-right: 10px;

          > .default {
            position: absolute;
            font-weight: bold;
            font-size: 13px;
            line-height: 40px;
            color: $color-dark;
            width: 56px;
            height: 40px;
            left: -44px;
            padding-left: 4px;
          }

          .label {
            position: absolute;
            top: 6px;
            left: 4px;
            font-size: 13px;
            line-height: 24px;
            padding: 2px 10px 1px 10px;
            display: block;
            border-radius: 4px;

            &[hidden] {
              display: none;
            }
          }

          select {
            font-weight: $fontweight-medium;
            text-transform: capitalize;
            padding: 5px 10px;
            margin: 2.5px;
            border: 1px solid $color-border;
            box-shadow: none;
            height: 35px;
            background-image: url('/assets/img/icons/caret-down-dark.svg');
            background-size: 12px 8px;
            background-repeat: no-repeat;
            background-position: right 12px center;
            outline: none;
          }
        }
      }

      &__compose {
        position: relative;
        height: 200px;
        display: flex;
        flex-direction: row;

        .ql-container.ql-snow .ql-editor.ql-blank::after {
          color: $color-grey-light;
          font-weight: $fontweight-light;
        }

        > div {
          width: calc(100% - 56px);
          min-width: calc(100% - 56px);

          quill-editor {
            width: 100%;
          }
        }

        > button.send {
          &.touched {
            color: $color-orange;
          }
          width: 48px;
          min-width: 48px;
          height: 48px;
          background: $color-white;
          color: $color-grey-light;
          font-weight: $fontweight-bold;
          border: 0;
          padding: 0;
          margin: 4px;
          box-shadow: none;
          text-align: center;
          border-radius: 0;

          &:hover {
            background: $color-white;
            filter: none;
            transform: scale(1.05);
            color: darken($color-orange, 10%);
          }
        }
      }

      .ql-container.ql-snow {
        .ql-tooltip {
          top: -68px !important;
          border-left: 0;
          border-right: 0;
          border-radius: 0;
        }
      }
    }

    &__modal {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba($color-dark, 0.5);
      padding: 40px;

      &__box {
        width: calc(100% - 80px);
        position: absolute;
        top: 40%;
        transform: translateY(-50%);
        background: $color-white;
        box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.16);
        border-radius: 4px;
        padding: 16px;

        &__close {
          width: 40px;
          min-width: 40px;
          height: 40px;
          padding: 11px;
          position: absolute;
          top: 8px;
          right: 8px;
          cursor: pointer;

          svg {
            width: 18px;
            height: 18px;
            transition: 260ms ease;
          }

          &:hover {
            svg {
              transform: rotate(90deg);
            }
          }
        }

        &__head {
          > p {
            width: calc(100% - 48px);
            font-weight: $fontweight-bold;
            font-size: 15px;
            line-height: 22px;
            color: $color-dark;

            span {
              display: block;
              font-weight: $fontweight-regular;
              color: $color-grey;
            }
          }
        }

        &__content {
          margin-top: 16px;

          input {
            width: 100%;
            border-radius: 6px;
            border: 1px solid $color-border;
            box-shadow: none;
            margin: 0;
            transition: 260ms ease;
            height: 40px;
            font-size: 15px;
            line-height: 26px;
            padding: 0 16px;
          }

          button {
            margin: 12px 0 0 auto;
            box-shadow: none;
          }
        }
      }
    }

    &.full-screen {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 0;
      border: 0;

      .conversation__header {
        &__type {
          &__options {
            .radio {
              input[type='radio'] + label {
                &:after {
                  top: 5px;
                }
              }
            }
          }
        }
      }

      .messages {
        .message {
          max-width: calc(60% - 24px);
        }
      }
    }

    &.closed {
      .conversation__header {
        &__type {
          > p {
            display: none;
          }

          &__options {
            pointer-events: none;
          }
        }
      }

      .conversation__bottom {
        &__toolbar {
          &__markup,
          &__image {
            display: none;
          }
        }

        &__compose {
          pointer-events: none;

          > div,
          .send {
            display: none;
          }

          > span {
            font-size: 16px;
            color: $color-grey;
            text-align: center;
            padding: 32px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $screen--l-max) {
  body.conversation-opened {
    overflow: hidden;
  }

  section.tickets.conversations {
    .conversation {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 0;
      border: 0;

      &__header {
        &__top {
          > div {
            div.fullscreen,
            div.normalscreen {
              display: none;
            }
          }
        }

        &__type {
          &__options {
            .radio {
              &:not(:first-child) {
                margin-left: 6px;
              }

              input[type='radio'] + label {
                &:after {
                  top: 5px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $screen--m-max) {
  section.tickets.conversations {
    .conversation {
      &__bottom {
        &__toolbar {
          &__markup {
            .ql-underline {
              display: none;
            }
          }

          &__status {
            width: 130px;

            .label {
              font-size: 12px;
            }

            .default {
              display: none;
            }
          }
        }
      }
    }
  }
}

/*------------------------------------*\
      Conversation (keyframes)
  \*------------------------------------*/
@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
