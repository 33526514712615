.media-modal {
  .modal .modal-content {
    max-width: 900px;

    .multi-attachment {
      justify-content: center;
    }
  }
}

.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  z-index: $modalZIndex;
  background-color: $modalOverlayBackground;

  &.modal-open {
    display: flex;
  }

  .modal-header {
    display: flex;
    padding: 1.6rem;
    padding-bottom: 0;
    align-items: center;

    .modal-icon {
      display: flex;
      align-items: center;
      padding-right: 15px;

      svg {
        height: 3.2rem;
        width: 3.2rem;
        fill: $color-black;
      }
    }
  }

  .modal-content {
    position: relative;
    background-color: $modalBackground;
    width: 90%;
    max-width: 640px;
    max-height: calc(100% - 70px);
    border: 1px solid $color-border;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    &-inner {
      padding: 1.6rem;
      padding-top: 0;
      overflow-y: auto;
    }

    .ng-dropdown-panel {
      position: static;
      display: block;
      border-radius: 0;
      border-top: 0;
    }

    .ng-select-container {
      border-radius: 0;
    }

    .input {
      width: 100%;
      border-radius: 8px;
      border: 1px solid $color-border;
      box-shadow: none;
      margin: 0;
      transition: 260ms ease;
      font-size: 15px;

      &--m {
        height: 50px;
        font-size: 16px;
        line-height: 26px;
        padding: 0 16px;
      }
    }

    .modal-title {
      font-weight: $fontweight-bold;
      font-size: 1.8rem;
      line-height: 3rem;
      color: $color-dark;
      margin-top: 0;
    }

    .modal-description {
      font-size: 1.6rem;

      p:not(:last-child) {
        margin-bottom: 0.8rem;
      }
    }

    .form-group {
      margin-top: 1.6rem;
    }

    .modal-text {
      font-weight: $fontweight-regular;
      font-size: 16px;
      line-height: 24px;
      color: $color-grey;
      margin: 0 0 24px 0;
    }

    .modal-close {
      cursor: pointer;
      margin-left: auto;

      > svg {
        fill: $color-black;
        width: 18px;
        height: 18px;
        transition: 260ms ease;
      }

      &:hover {
        svg {
          transform: rotate(90deg);
        }
      }
    }

    .justified-buttons {
      margin-bottom: 0;
    }

    .flex.buttons {
      display: flex;
      flex-direction: row;
      margin-top: 16px;

      button {
        margin-right: 16px;
      }
    }

    &.modal-image {
      padding: 0;
      width: auto;
      height: auto;
      max-width: 90%;
      max-height: 90%;
      position: relative;

      img.modal-image-content {
        max-width: 100%;
        max-height: 90vh;
      }
    }
  }

  .modal--no-scroll {
    overflow: visible;
  }
}
