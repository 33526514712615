app-statistics {
  section {
    margin-bottom: 3.2rem;

    .legend {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 1.6rem;
      margin-top: 1.6rem;
      justify-content: center;
      align-items: center;

      .checkbox label {
        font-size: 1.4rem;
        font-weight: $fontweight-semibold;
      }

      > div {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        > span {
          font-size: 1.4rem;
          font-weight: $fontweight-semibold;
          color: $color-dark;
          display: flex;
          overflow: hidden;

          > span {
            height: 2rem;
            line-height: 2rem;
            display: inline-block;
            vertical-align: middle;
            margin-right: 0.8rem;

            &:first-child {
              width: 2rem;
              border-radius: 50%;
            }

            > svg {
              height: 100%;

              &:not([width='20']) {
                margin: 0 0.2rem;
              }
            }
          }
        }
      }
    }

    .alert {
      width: 100%;
      padding: 2.4rem;
      background-color: $color-white;
      border-radius: 1.2rem;
      display: flex;
      align-items: center;
      gap: 0.8rem;

      &.tip {
        border: 2px solid $color-green;

        svg {
          path {
            fill: $color-green;
          }
        }
      }

      &.notice {
        border: 2px solid $color-blue;

        svg {
          path {
            fill: $color-blue;
          }
        }
      }

      > span {
        width: 4rem;
        height: 4rem;
        display: block;

        svg {
          width: 100%;
          height: 100%;
        }
      }

      p {
        span:first-of-type {
          font-weight: $fontweight-bold;
        }
      }
    }

    .feedback-link {
      width: 100%;
      text-align: center;
    }

    .settings {
      width: 100%;

      .switch {
        .switch-option {
          border-color: $color-border;
          color: $color-dark;

          &.active {
            background-color: $color-border;
          }

          &:not(.active) {
            background-color: $color-white;
          }

          &:first-of-type {
            border-top-left-radius: 1.2rem;
            border-bottom-left-radius: 1.2rem;
          }

          &:last-of-type {
            border-top-right-radius: 1.2rem;
            border-bottom-right-radius: 1.2rem;
          }
        }
      }
    }

    h2 {
      width: 100%;
      margin: 0;
    }

    h3 {
      font-size: 1.8rem;
      font-weight: $fontweight-bold;
      color: $color-dark;
      margin: 0;
      margin-bottom: 0.8rem;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      span {
        display: inline-block;
        height: 1.6rem;

        svg {
          fill: var(--primary);
          cursor: pointer;
          display: block;
        }
      }

      a {
        font-size: 1.4rem;
        margin-left: 0.4rem;
      }
    }
  }

  section.charts {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 2.4rem;
    flex-wrap: wrap;
    min-height: 250px;
    position: relative;

    .loader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin: 0;
    }

    .chart {
      margin-top: 0;
      padding: 1.6rem;
      border: 1px solid $color-border;
      border-radius: 1.2rem;
      background-color: $color-white;
      flex: 1 1 calc(25% - 2.4rem);
      overflow: hidden;
      display: flex;
      flex-wrap: wrap;
      gap: 0.8rem;

      @media screen and (max-width: $screen--m-max) {
        flex-basis: 100%;
      }

      > span {
        font-size: 1.8rem;
        color: $color-dark;
        font-weight: $fontweight-bold;
        display: block;
        margin-bottom: 1.6rem;
      }

      &__toggle {
        margin-left: auto;

        .checkbox label {
          font-size: 1.4rem;
          line-height: 2.6rem;
        }
      }

      &__inner {
        height: 400px;
        width: 100%;
        margin-top: auto;

        canvas {
          margin-top: 0;
          max-width: 100%;
        }
      }
    }
  }

  section.statistics {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2.4rem;

    .updates__list {
      padding-left: 0;
      padding-top: 1.6rem;
      width: calc(67% - 1.2rem);
      min-width: calc(67% - 1.2rem);
      position: relative;
      display: flex;
      flex-direction: column;

      .no-results {
        padding: 2.4rem;
        background-color: $color-white;
        border-radius: 1.2rem;
      }
      
      h3 {
        text-align: center;
      }

      carousel {
        flex-grow: 1;
        padding: 0 1.6rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-bottom: 5rem;

        .carousel {
          position: static;
          padding: 0 2.4rem;

          &-indicators {
            [data-bs-target] {
              box-sizing: content-box;
              flex: 0 1 auto;
              width: 20px;
              height: 3px;
              padding: 0;
              margin-right: 3px;
              margin-left: 3px;
              text-indent: -999px;
              cursor: pointer;
              background-color: $primaryColor;
              background-clip: padding-box;
              border: 0;
              border-top: 10px solid transparent;
              border-bottom: 10px solid transparent;
              opacity: 0.3;
              transition: opacity 0.6s ease;

              &.active {
                opacity: 1;
              }
            }
          }

          &-control {
            opacity: 1;
            width: 2.4rem;
            color: $color-dark;
            background: none;
            

            &:hover {
              opacity: 0.7;
            }
          }
        }

        @media screen and (max-width: $screen--m-max) {
          min-width: 100%;
        }

        .update__box {
          margin-bottom: 0;
        }
      }

      @media screen and (max-width: $screen--m-max) {
        width: 100%;

        carousel {
          .carousel {
            padding: 0;

            &-control {
              margin: -5px;
            }
          }
        }
      }
    }

    .stat,
    .chart {
      padding: 1.6rem;
      margin: 0;
      background-color: $color-white;
      border: 1px solid $color-border;
      border-radius: 1.2rem;
      flex: 1 1 calc((100% / 4) - 2.4rem);
      height: fit-content;

      @media screen and (max-width: $screen--m-max) {
        flex-basis: 100%;
      }

      h3 {
        margin: 0;
        font-size: 1.8rem;
        margin-bottom: 0.8rem;

        span {
          display: inline-block;
          height: 1.6rem;

          svg {
            fill: var(--primary);
            cursor: pointer;
            display: block;
          }
        }
        
        .export-icon {
          cursor: pointer;
          margin-left: 8px;
          width: 16px;
          height: 16px;
          display: inline-flex;
          align-items: center;
          vertical-align: middle;
          position: relative;
          top: -1px;
          
          &:hover {
            opacity: 0.8;
          }
          
          svg {
            width: 16px;
            height: 16px;
          }
        }
      }
    }

    .chart {
      &__interactions {
        flex-basis: calc(((100% / 3) * 2) - 1.2rem);

        .chart__inner {
          max-width: 100%;
          height: 515px;
          padding: 0;
          overflow: hidden;

          canvas {
            max-width: 100%;
            padding: 0;
          }
        }
      }

      &__inner {
        width: 100%;
        max-width: 400px;
        padding: 0 2.4rem 1.6rem 2.4rem;
        margin: 0 auto;
        position: relative;

        .empty {
          position: absolute;
          top: 2.4rem;
          left: 0;
          width: 100%;
          text-align: center;
          font-size: 1.4rem;
        }

        canvas {
          margin-top: 0;
          padding: 0 1.6rem;
        }
      }
    }

    .stat {
      &__map {
        flex-basis: 100%;

        mgl-map {
          height: 40rem;
          border-radius: 0.8rem;
          overflow: hidden;
        }

        .legend {
          flex-direction: row;
          justify-content: start;

          > div {
            width: unset;
            display: unset;

            span {
              cursor: pointer;
            }
          }
        }
      }

      > span:first-of-type {
        color: var(--primary);
        font-size: 4rem;
        line-height: 4rem;
        display: inline-block;
        vertical-align: middle;
      }

      > span:last-of-type {
        margin-left: 0.8rem;
        display: inline-block;
        vertical-align: middle;

        > span {
          display: inline-block;
          line-height: 1.6rem;
          vertical-align: middle;

          svg {
            height: 100%;
            width: 100%;
          }
        }
      }

      span.negative {
        color: $color-red;

        svg path {
          fill: $color-red;
        }
      }

      span.positive {
        color: $color-green;

        svg path {
          fill: $color-green;
        }
      }
    }
  }

  .conversations {
    &__select {
      display: flex;
      flex-wrap: wrap;
      margin: 0.8rem 0;

      select {
        width: 33%;
        min-width: 200px;
        margin-right: 2.4rem;
      }
    }

    &__group {
      display: flex;
      gap: 1.6rem;
      flex-wrap: wrap;

      &__item {
        background-color: $color-white;
        border: 1px solid $color-border;
        border-radius: 1.2rem;
        padding: 2.4rem 1.6rem;
        display: flex;
        flex-direction: column;
        text-align: center;
        flex: 1 1 calc(25% - 1.6rem);
        gap: 0.8rem;

        @media screen and (max-width: $screen--m-max) {
          flex-basis: calc(50% - 1.6rem);
        }

        > span {
          > span {
            width: 100%;
            display: block;

            > span:first-of-type {
              font-size: 4rem;
              line-height: 4rem;
              color: $color-dark;
              font-weight: $fontweight-semibold;
            }
          }

          &:last-of-type {
            margin-top: auto;
            padding-top: 0.8rem;

            &.positive {
              color: $color-green;
            }

            &.negative {
              color: $color-red;
            }
          }
        }

        svg {
          width: 8rem;
          height: 8rem;
          fill: $color-dark;
        }
      }
    }
  }
}
