.satisfaction-distribution-wrapper {
  background-color: $color-white;
  padding: 20px;
  border-radius: 12px;
}

.satisfaction-distribution {
  display: flex;
  margin-top: 16px;

  &__score {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    position: relative;

    &:first-of-type {
      .satisfaction-distribution__bar {
        border-top-left-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
      }
    }

    &:last-of-type {
      .satisfaction-distribution__bar {
        border-top-right-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
      }
    }

    &--1 .satisfaction-distribution__bar {
      background: $scale1;
    }
    &--2 .satisfaction-distribution__bar {
      background: $scale2;
    }
    &--3 .satisfaction-distribution__bar {
      background: $scale3;
    }
    &--4 .satisfaction-distribution__bar {
      background: $scale4;
    }
    &--5 .satisfaction-distribution__bar {
      background: $scale5;
    }

    &__image {
      top: 4rem;

      &,
      svg {
        width: 40px;
        .inline-svg__fill-primary {
          fill: $color-grey-light !important;
        }
      }
    }
  }

  &__bar {
    width: 100%;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    font-weight: bold;
    font-size: 15px;
  }
}

@media screen and (max-width: $screen--m-max) {
  .satisfaction-distribution {
    &__score {
      min-width: 64px;

      &__image {
        top: 16px;

        &,
        svg {
          width: 24px;
        }
      }
    }
  }
}
