.two-factor-screen {
  align-items: center;

  .min-vh-100 {
    min-height: 100vh;
  }

  &__title {
    width: 100%;
  }

  .page {
    max-width: 480px;
    padding-top: 2rem;

    img {
      max-width: 240px;
      height: auto;
    }

    &__content {
      flex-direction: column;
      text-align: center;
      background-color: white;
      border-radius: 0 0 25px 25px;
    }

    .align {
      width: 100%;
      display: flex;
      text-align: left;
      justify-content: center;
      padding-top: 2rem;

      h2 {
        font-size: 4rem;
      }

      #phoneNumber {
        margin-left: 5px;
        border: 1px solid $color-grey-light;
        border-radius: 6px;
        box-shadow: none;
        font-size: 16px;
        height: 50px;
        margin: 0;
        outline: 0 !important;
        padding: 0 16px;
        transition: 0.26s ease;
        width: 100%;
      }

      .form {
        width: 100%;
        background-color: white;
        border-radius: 20px;

        display: flex;
        flex-direction: column;

        button {
          height: 50px;
        }

        label {
          margin-bottom: 0;
        }

        app-form-group {
          padding-bottom: 1rem;
        }
        div.bouwapp-group__item {
          padding-bottom: 2rem;
        }

        angular-intl-phone {
          .iti.iti--allow-dropdown {
            .iti__dropdown-content {
              position: unset;
            }
          }
          div.iti {
            width: 100%;
          }
        }

        .ng-touched.ng-invalid {
          border-color: red;
          #phoneNumber {
            border-color: red;
          }
        }

        .code-input {
          display: flex;

          input {
            margin: 1rem;
            min-width: 4rem;
            max-width: 7rem;
            text-align: center;
          }
        }

        .invalidTwoFa {
          margin: 1rem 0;
        }

        .invalidCode {
          > h3 {
            color: inherit;
          }
        }

        .resend {
          margin-top: 0.5rem;
        }
      }
    }
  }

  @media screen and (max-width: $screen--m-max) {
    .page {
      padding: 16px;
      .align {
        text-align: center;
        h2 {
          font-size: 3rem;
        }
      }
    }
  }

  @media screen and (max-width: 400px) {
    .page {
      .align {
        .form {
          .code-input {
            input {
              min-width: 3rem;
              margin: 5px;
            }
          }
        }
      }
    }
  }
}
