.alert {
  width: 100%;
  font-weight: $mediumFontWeight;
  font-size: 16px;
  color: $secondaryTextColor;
  background: $white;
  border: 2px solid transparent;
  @include round(3px);
  padding: 18px 27px 18px 60px;
  margin: 10px 0;
  position: relative;

  &:before {
    content: ' ';
    width: 23px;
    height: 23px;
    display: block;
    position: absolute;
    left: 20px;
    top: 17px;

    background-repeat: no-repeat;
  }

  &.alert-info {
    border-color: $alertInfo;

    &:before {
      background-image: url('/assets/img/icons/info.svg');
    }
  }

  &.alert-danger {
    border-color: $alertDanger;

    &:before {
      background-image: url('/assets/img/icons/danger.svg');
    }
  }

  &.alert-success {
    border-color: $alertSuccess;

    &:before {
      background-image: url('/assets/img/icons/success.svg');
    }
  }

  &.on-screen {
    margin: 0;
    width: auto;
    border-radius: 8px;
    padding: 18px 26px 18px 60px;
    position: fixed;
    box-shadow: 2px 2px 5px $color-grey-light;
    bottom: 100px;
    left: calc(50% + 16.4rem);
    transform: translateX(-50%);
    z-index: $screenAlertZIndex;

    &[hidden] {
      opacity: 0;
    }
  }
}

@media screen and (max-width: $screen--l-max) {
  .alert.on-screen {
    left: 50%;
  }
}
