/*------------------------------------*\
    Project - General
\*------------------------------------*/
.nav-concept {
  position: fixed;
  z-index: 202;
  top: 0;
  left: 0;
  width: 100%;
  padding: 16px;
  background: $color-white;
  border-bottom: 1px solid $defaultBorder;
  display: flex;
  flex-direction: row;
  align-items: center;

  &__left {
    display: flex;
    flex-direction: column;
  }

  &__right {
    display: flex;
    flex-direction: row;
    margin-left: auto;
  }
}

.projects-detail {
  .bouwapp-box {
    &__self {
      width: 100%;
    }

    &__footer {
      button {
        display: flex;
        justify-content: center;
        align-items: center;

        &.has-inline-spinner {
          &::before {
            background-repeat: no-repeat;
          }
        }
      }

      > button {
        &.has-inline-spinner {
          &::before {
            height: 10px;
          }
        }
      }
    }

    .title {
      display: block;
      margin: 2px 0 20px 0;
      color: $color-dark;
      font-weight: bold;
    }
  }

  &__dates {
    display: flex;
  }

  .targets {
    > p {
      padding: 0;
    }
  }
  .standalone-planning {
    app-detail-general-planning {
      width: 100%;
    }
  }

  @media screen and (min-width: $screen--m-max) {
    .standalone-planning {
      .form-split {
        display: flex;
        gap: 2.4rem;
        > div {
          flex-grow: 1;
        }
      }
    }
  }

  .period {
    &__wrapper {
      display: flex;
      justify-content: space-around;
    }
  }
}

/*------------------------------------*\
      Project - Specifics
  \*------------------------------------*/
.cover {
  min-width: 256px;
  .project-coverImage {
    aspect-ratio: 16 / 9;
    min-height: 144px;
    max-width: 100%;
  }
}

.project-logo {
  height: 144px;
  width: 144px;
}

.project-coverImage,
.project-logo {
  position: relative;
  border-radius: 6px;
  background-size: cover;
  background-position: center center;
  transition: 0.25s all ease;

  &.hasImage {
    .input-file-standalone {
      border-style: solid;
    }
  }

  &:hover {
    background-color: rgba($color-border, 0.5);

    .input-file-standalone {
      background-color: rgba(0, 0, 0, 0.5);

      > label {
        div {
          color: $color-white;

          svg,
          svg path {
            fill: $color-white;
          }
        }
      }
    }
  }

  &__button {
    height: 100%;

    &__text {
      position: absolute;
      top: 50%;
      left: 50%;
    }
  }

  .input-file-standalone {
    width: 100%;
    height: 100%;
    border: 2px dashed $color-border;
    border-radius: 6px;
    transition: 0.25s all ease;

    > label {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      border-radius: 6px;
      background: transparent;
      box-shadow: none;

      &.loading {
        background: rgba($color-grey-dark, 0.5);
        div {
          display: none;
        }
      }

      div {
        margin: 0;
        display: flex;
        align-items: center;
        gap: 10px;
        color: $color-grey;
        transition: 260ms ease;
        flex-wrap: wrap;
        justify-content: center;
        padding: 10px;

        svg,
        svg path {
          display: block;
          fill: $color-grey;
        }
      }
    }
  }
}

@media screen and (max-width: $screen--m-max) {
  .nav-concept {
    flex-direction: column;

    &__right {
      margin-left: unset;
    }
  }
}

.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  display: block;
  opacity: 0.8;
  cursor: grabbing;

  &.document {
    display: flex;
    border: 1px solid $color-border;
    padding: 4px;
    border-radius: 6px;
    flex-direction: row;
    cursor: grabbing;

    > div {
      display: flex;
      flex-direction: column;

      p {
        font-size: 16px;
        font-weight: bold;
        color: $color-dark;
      }
    }

    > .draggable-child {
      display: inline-block;
      width: 48px;
      min-width: 48px;
      height: 40px;
      background: $color-white;
      text-align: center;
      padding-top: 15px;
      margin: 4px 0 0 auto;
      cursor: grabbing;

      svg {
        width: 10px;
        height: 16px;
      }
    }

    > .document__image {
      width: 56px;
      min-width: 56px;
      height: 56px;
      border-radius: 3px;
      border: 1px solid $color-border;
      margin-right: 16px;
      background-color: rgba($color-grey, 0.5);
    }
  }

  div[role='manager'],
  div.manage {
    display: none;
  }
}

.gu-hide {
  left: -9999px !important;
}

.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.gu-transit {
  opacity: 0.6;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=20)';
  filter: alpha(opacity=20);
}

.project-category {
  display: flex;
  flex-direction: row;

  app-form-group {
    margin-bottom: 1.6rem;
  }

  .bouwapp-quill__editor .ql-container.ql-snow .ql-editor {
    min-height: 120px;
  }

  &__manage {
    width: 5%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    > span {
      &:first-child {
        background: $color-border;
        width: fit-content;
        padding: 1rem;
        cursor: grab;
        padding-left: 0.8rem;
        margin-top: 0.8rem;
        border-radius: 0 0.8rem 0.8rem 0;

        svg {
          display: block;
          width: 15px;
          height: 24px;

          path {
            fill: $color-grey-light;
          }
        }
      }

      &:last-child {
        margin-left: 1.2rem;
        margin-bottom: 0.8rem;
        cursor: pointer;

        svg {
          display: block;
        }
      }
    }
  }

  &__default {
    width: 95%;
    display: flex;
    flex-direction: column;
    user-select: none;
    border: 1px solid $color-border;
    border-radius: 0.8rem;
    padding: 2.4rem;
    position: relative;

    .index {
      width: 2.4rem;
      height: 2.4rem;
      background-color: $color-dark;
      border-radius: 50%;
      position: absolute;
      left: -0.8rem;
      top: -0.8rem;

      span {
        text-align: center;
        color: $color-white;
        line-height: 2.4rem;
        display: block;
      }
    }

    span.draggable-parent {
      display: inline-block;
      width: 48px;
      min-width: 48px;
      height: 40px;
      background: $color-white;
      text-align: center;
      padding-top: 15px;
      margin-right: 8px;
      cursor: grab;

      svg {
        width: 10px;
        height: 16px;
      }
    }

    > div.group {
      width: calc(100% - 176px);
      min-width: calc(100% - 176px);

      p {
        font-size: 16px;
        font-weight: $fontweight-bold;
        line-height: 28px;
        color: $color-dark;
        margin: 0;
      }

      a {
        font-weight: $fontweight-regular;
      }

      > div {
        display: flex;
        flex-direction: row;
        align-items: center;

        span {
          color: $color-grey;
          margin-right: 12px;

          &:after {
            content: '';
            position: relative;
            top: -3px;
            display: inline-block;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background: $color-grey;
            margin-left: 8px;
          }
        }
      }
    }
  }

  &__documents {
    user-select: none;

    > p {
      font-size: 14px;
      line-height: 24px;
      font-weight: $fontweight-medium;
      margin-bottom: 4px;
      color: $color-dark;
    }

    > ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      .document {
        width: calc(50% - 8px);
        min-width: calc(50% - 8px);
        padding: 4px;
        display: flex;
        flex-direction: row;
        transition: 260ms ease;

        span.draggable-child {
          display: inline-block;
          width: 48px;
          min-width: 48px;
          height: 40px;
          background: $color-white;
          text-align: center;
          padding-top: 15px;
          margin: 4px 0 0 auto;
          cursor: grab;

          svg {
            width: 15px;
            height: 24px;

            path {
              fill: $color-grey-light;
            }
          }
        }

        &__image {
          width: 56px;
          min-width: 56px;
          height: 56px;
          border-radius: 3px;
          border: 1px solid $color-border;
          margin-right: 16px;
          background-color: rgba($color-border, 0.5);
          position: relative;
          align-items: center;
          justify-content: center;

          > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 2px;
          }

          > span {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 26px;
            height: 26px;

            > svg {
              width: 26px;
              height: 26px;
            }
          }

          > .spinner {
            width: 20px;
            height: 20px;
            background-size: contain;
            margin: 0;
          }

          > video {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        &__buttons {
          font-size: 1.2rem;
          font-weight: $fontweight-medium;
          transition: 260ms ease;
          display: flex;
          flex-direction: column;
          justify-content: center;

          &__delete {
            cursor: pointer;
            color: $color-red;
            margin-right: 5px;

            &:hover {
              text-decoration: underline;
            }
          }
        }

        > div {
          display: flex;
          flex-direction: row;
          border: 1px solid $color-border;
          border-radius: 0.8rem;
          width: 100%;

          p {
            font-size: 1.4rem;
            font-weight: $fontweight-medium;
            color: $color-dark;
            margin: 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $screen--m-max) {
  .project-category {
    &__default {
      > div.group {
        > div {
          flex-direction: column;
          align-items: flex-start;

          span {
            font-size: 14px;
            margin-bottom: 4px;

            &:after {
              display: none;
            }
          }

          a {
            font-size: 14px;
          }
        }
      }
    }

    &__documents {
      margin-top: 16px;

      > ul {
        flex-direction: column;

        .document {
          width: 100%;
          min-width: 100%;
        }
      }
    }
  }
}

.section-controls {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1.6rem;
  margin-top: 1.2rem;

  button {
    margin: 0;
  }

  .default-sections {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 0.8rem;
  }
}

.project-child {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  margin-bottom: 0.8rem;

  &:last-child {
    margin-bottom: 0;
  }

  &__icon {
    position: relative;
    width: 48px;
    min-width: 48px;
    height: 48px;
    background-color: $color-border;
    margin: 0.4rem;
    border-radius: 0.8rem;
    margin-right: 1.6rem;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 24px;
      height: 24px;
    }
  }

  &__content {
    display: flex;
    flex-direction: row;
    width: calc(100% - 48px);
    min-width: calc(100% - 48px);

    p {
      font-size: 16px;
      font-weight: $fontweight-bold;
      color: $color-dark;
      margin: 0;
      word-break: break-word;
      cursor: pointer;
      display: inline-block;

      span {
        font-weight: $fontweight-medium;
        color: $color-orange;
      }

      &:hover {
        text-decoration: underline;
      }
    }

    > div {
      span {
        color: $color-grey;
        font-size: 16px;
        font-weight: $fontweight-regular;

        &:first-child {
          margin-right: 8px;

          &:after {
            content: '';
            position: relative;
            top: -3px;
            display: inline-block;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background: $color-grey;
            margin-left: 8px;
          }
        }
      }
    }
  }

  > div:first-child {
    width: calc(100% - 100px);
    min-width: calc(100% - 100px);
    display: flex;
    border: 1px solid $color-border;
    border-radius: 0.8rem;
  }

  > div:last-child {
    width: 100px;
    min-width: 100px;
    margin-left: 1.6rem;

    span {
      cursor: pointer;
    }
  }

  &__empty {
    > p {
      font-size: 16px;
      font-weight: $fontweight-regular;
      color: $color-grey;
      margin: 0 0 16px 0;

      strong {
        color: rgba($color-dark, 0.8);
      }
    }

    > .button {
      display: inline-block;
    }
  }
}

@media screen and (max-width: $screen--m-max) {
  .project-child {
    &__content {
      flex-direction: column;

      > div:first-child {
        width: 100%;
        min-width: 100%;

        p {
          line-height: 24px;
        }

        div {
          flex-direction: column;
          align-items: flex-start;

          span {
            &:first-child {
              margin: 0;

              &:after {
                display: none;
              }
            }
          }
        }
      }

      > div:last-child {
        width: 100%;
        min-width: 100%;
      }
    }
  }
}

.project-coordinates {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;

  > * {
    width: 50%;
    min-width: 50%;
  }

  input.left {
    border-radius: 6px 0 0 6px;
    border-right: 0;
  }

  input.right {
    border-radius: 0 6px 6px 0;
  }
}

.project-shapefile {
  .shapefile-info {
    cursor: pointer;
  }

  .input-file {
    min-height: 120px;
  }
}

.project-map {
  position: relative;
  margin: 1.2rem 0 2.4rem 0;
  width: 100%;

  .button.mapboxgl-ctrl {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 10px;
  }

  .map {
    width: 100%;
    height: 420px;

    .mapboxgl-map {
      border-radius: 0.8rem;

      .mapboxgl-ctrl-geocoder {
        border: 2px solid $color-border;
        border-radius: 0.8rem;

        &--input:focus {
          outline: unset;
        }
      }
    }
  }
}

/*------------------------------------*\
      Project - Form Elements
  \*------------------------------------*/
.select {
  width: 100%;
  border-radius: 6px;
  border: 1px solid $color-border;
  box-shadow: none;
  margin: 0;
  transition: 260ms ease;

  &--m {
    height: 50px;
    font-size: 16px;
    line-height: 26px;
    padding: 12px 16px;
  }
}

.input {
  width: 100%;
  border-radius: 8px;
  border: 1px solid $color-border;
  box-shadow: none;
  margin: 0;
  transition: 260ms ease;
  font-size: 15px;

  &--m {
    height: 50px;
    font-size: 16px;
    line-height: 26px;
    padding: 0 16px;
  }
}

.textarea {
  width: 100%;
  min-height: 140px;
  font-size: 16px;
  line-height: 26px;
  padding: 16px;
  border-radius: 6px;
  box-shadow: none;
  margin: 0;
  transition: 260ms ease;
  border: 1px solid $color-border;
}

.form-date {
  > p {
    font-size: 16px;
    font-weight: $fontweight-semibold;
    color: $color-dark;
    margin: 8px 0;
  }

  &__notice {
    text-align: center;
    font-size: 16px;
    font-weight: $fontweight-semibold;
    color: $color-red;
    margin-top: 16px;

    &.combi {
      margin: 0 0 24px 0;
    }
  }

  .switch {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 16px;

    &-option {
      flex: unset;
      margin-right: 8px;
      border: 1px solid $color-grey;
      font-size: 14px;
      font-weight: $fontweight-semibold;
      line-height: 24px;
      color: $color-grey;
      border-radius: 6px;
      transition: 260ms ease;

      > div {
        padding: 8px 16px 6px 16px;
      }

      &:hover {
        background: rgba($color-grey, 0.5);
        color: $color-white;
      }

      &.active {
        background: $color-grey;
        color: $color-white;
      }
    }
  }

  select {
    border-radius: 6px;
    border: 1px solid $color-border;
    box-shadow: none;
    margin: 0 0 4px 0;
    height: 50px;
    font-size: 16px;
    line-height: 26px;
    padding: 12px 16px;
    transition: 260ms ease;
    margin-right: 6px;
  }

  .date-picker {
    .date-picker-choose .date-picker-item {
      padding: 12px 0;
      border-radius: 6px;
    }

    .date-picker-time-input {
      max-width: 120px;
      margin: 0;

      input {
        border-radius: 6px;
        border: 1px solid $color-border;
        box-shadow: none;
        height: 50px;
        font-size: 16px;
        line-height: 26px;
      }
    }
  }
}

@media screen and (max-width: $screen--m-max) {
  .form-date {
    .date-picker {
      .date-picker-quick-select {
        display: flex;
        flex-direction: column;
      }

      .date-picker-time-input {
        max-width: 100%;
      }

      &:not(.date-picker-with-time)
        .date-picker-quick-select
        .date-picker-month {
        margin: 4px 0 8px 0;
      }
    }
  }
}

/*------------------------------------*\
      Project - Other
  \*------------------------------------*/
.input-file-holder-modal .input-file {
  height: 300px;
}

/*------------------------------------*\
      Project - Preview
  \*------------------------------------*/
.project-previewModal {
  .button {
    &.isCopied {
      padding-left: 40px;
    }

    span {
      position: absolute;
      left: 12px;
      width: 20px;
      height: 20px;

      svg {
        width: 20px;
      }
    }
  }
}
/*------------------------------------*\
      Project - Documents (modal)
  \*------------------------------------*/
.projects.documents.create {
  .input-file {
    height: auto;
    padding: 40px 24px;

    > div {
      display: flex;
      flex-direction: column;
      padding: 0;
    }

    &__left {
      .input-file-icons {
        margin-bottom: 24px;
      }

      .input-file-video {
        display: none;
      }
    }

    &__right {
      .input-file-instruction {
        max-width: 100%;

        .input-file-video {
          position: relative;
        }
      }
    }
  }

  .input-file-preview {
    margin-bottom: 16px;
  }
}
