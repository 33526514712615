/*------------------------------------*\
  Components | Buttons
\*------------------------------------*/
@mixin button {
  position: relative;
  cursor: pointer;
  outline: 0;
  border: 0;
  font-family: 'Mukta', sans-serif;
  font-weight: $fontweight-semibold;
  letter-spacing: 0;
  user-select: none;
  display: block;
  transition: 260ms ease;
  appearance: none;
  text-decoration: none;
  border-radius: 0.8rem;

  &:hover {
    text-decoration: none;
  }

  &.isLoading {
    opacity: 0.5;
    pointer-events: none;
  }
}

@mixin button--m {
  font-size: 1.6rem;
  line-height: 100%;
  padding: 1.2rem 2.4rem;

  span {
    font-size: 1.6rem;
    line-height: 100%;
  }
}

@mixin button--l {
  font-size: 1.8rem;
  line-height: 100%;
  padding: 1.6rem 2.4rem;

  span {
    font-size: 1.8rem;
    line-height: 100%;
  }
}

@mixin button--round {
  border-radius: 20rem;
}

@mixin button--icon {
  padding: 1.2rem 1.6rem;

  span {
    display: inline-block;
    vertical-align: middle;

    &.icon {
      width: 1.6rem;
      min-width: 1.6rem;
      height: 1.6rem;
      margin-bottom: 0.2rem;

      svg {
        width: 1.6rem;
        height: 1.6rem;

        path {
          transition: 260ms ease;
        }
      }
    }

    &.arrow {
      width: 2rem;
      min-width: 2rem;
      height: 2rem;
      margin-left: auto;

      svg {
        width: 2rem;
        height: 2rem;

        path {
          transition: 260ms ease;
        }
      }
    }
  }

  span.icon + span:not(.icon),
  span:not(.icon) + span.icon {
    margin-left: 0.8rem;
  }
}

@mixin button--middle {
  width: 100%;
  text-align: center;
  padding-right: 0;
  padding-left: 0;

  span {
    width: 100%;
  }
}

@mixin button--clear($color) {
  color: $color;
}

@mixin button--flat($background, $color) {
  color: $color;
  background: $background;
}

@mixin button--outlined($background, $color-default, $color-hover) {
  background: transparent;
  color: $color-default;
  box-shadow: inset 0 0 0 0.1rem $background;
  svg path {
    fill: $color-default;
  }

  &:hover {
    background: $background;
    color: $color-hover;

    svg path {
      fill: $color-hover;
    }
  }
}

@mixin update-button {
  background-color: $color-white;
  color: $color-dark;
  border-radius: 10px;
  border: 1px solid $color-dark;
  box-shadow: none;
  margin: 0;
  margin-right: 10px;
  padding: 8px 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;

  &[disabled]:not(.back).inactive {
    background-color: $color-white;
    opacity: 0.5;
    border-color: $color-dark;

    p,
    span {
      color: $color-dark;
    }

    svg,
    path {
      fill: $color-dark;
    }
  }

  p {
    line-height: 18px;
    font-size: 15px;
    color: $color-dark;
    margin: 0;
  }

  span {
    width: 15px;
    height: 18px;
    margin-right: 8px;

    &.caret {
      margin-left: 8px;
      margin-right: 0;
    }

    svg {
      width: 100%;
      height: 100%;
      fill: $color-dark;
    }

    &.magic-wand svg {
      fill: $color-white;
    }

    &.stylus svg {
      fill: $color-black;
    }

    &.add svg {
      fill: $color-dark;
    }
  }

  &:hover {
    background-color: $color-dark;
    color: $color-white;

    svg {
      fill: $color-white;
    }

    p {
      color: $color-white;
    }

    .magic-wand svg {
      fill: $color-dark;

      path {
        stroke: $color-white;
      }
    }

    .spellcheck svg path {
      fill: $color-white;
    }

    .add svg path {
      fill: $color-white;
    }

    .undo svg {
      fill: $color-white;
    }
  }

  &[disabled]:not(.back, .button--black-white) {
    background-color: $primaryColor;
    opacity: 1;
    border-color: $primaryColor;

    p {
      color: $color-white;
    }

    span polygon {
      fill: $color-white;
    }

    span svg {
      fill: $color-white;
    }

    .magic-wand svg {
      path {
        fill: $color-white;
        stroke: $color-dark;
      }
    }
  }
}

.button {
  &--black-white {
    @include update-button;
  }
}

button[disabled] {
  opacity: 0.7;
  cursor: default !important;
}
