/*------------------------------------*\
    BouwApp header, title / text / buttons
\*------------------------------------*/
.bouwapp-header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 4rem 0 2rem 0;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 1.2rem;

  &__text {
    &__title {
      display: flex;
    }

    h1 {
      font-weight: $fontweight-bold;
      font-size: 3.2rem;
      color: $color-dark;
      margin: 0;

      span {
        color: $color-grey;
      }
    }

    p,
    ul li {
      font-weight: $fontweight-regular;
      font-size: 1.6rem;
      color: $color-dark;
      margin: 0;
    }

    ul {
      margin: 1.6rem 0 0 0;
      padding-left: 1.6rem;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    gap: 1.2rem;
    flex-wrap: wrap;

    button,
    a {
      box-shadow: none;
    }

    .secondary {
      @include button;
      @include button--m;
      @include button--icon;
      @include button--flat($color-blue, $color-white);
    }

    .primary {
      @include button;
      @include button--m;
      @include button--icon;
      @include button--flat($color-orange, $color-white);
    }

    .delete {
      @include button;
      @include button--m;
      @include button--icon;
      @include button--outlined($color-red, $color-red, $color-white);
    }

    .tertiary {
      @include button;
      @include button--m;
      @include button--icon;
      @include button--outlined($color-grey, $color-black, $color-white);
    }
  }

  &__content {
    width: 100%;

    a {
      color: $color-dark;
    }
  }
}

@media screen and (max-width: $screen--m-max) {
  .bouwapp-header {
    padding: 2.4rem 0;

    &__text {
      h1 {
        font-size: 2.6rem;
      }

      p {
        font-size: 1.4rem;
      }
    }
  }
}

/*------------------------------------*\
      BouwApp toolbar, search / sorting
  \*------------------------------------*/
.bouwapp-toolbar {
  padding-bottom: 2.4rem;
  width: 100%;

  &.hasSorting {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    > div:first-child {
      width: calc(100% - 28rem);
      min-width: calc(100% - 28rem);
    }

    > div:last-child {
      padding-left: 2.4rem;
      width: 28rem;
      min-width: 28rem;
      margin-left: auto;
    }
  }

  &.hasFilter {
    display: flex;
    flex-direction: column;

    > div:nth-child(2) {
      margin-top: 1.2rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 2rem;
    }
  }

  &.hasSortingAndFilter {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    > div:first-child {
      width: calc(100% - 56rem);
      min-width: calc(100% - 56rem);
    }

    > div:nth-child(2) {
      padding: 0 2.4rem;
    }

    > div:nth-child(2),
    > div:last-child {
      width: 28rem;
      min-width: 28rem;
    }
  }

  input[type='search'] {
    @include input;
    padding: 1.3rem 4.9rem !important;
    background-image: url('/assets/icons/search--dark.svg') !important;
    background-size: 2.4rem !important;
    background-position: 1.3rem center !important;
  }

  select {
    @include input;
    box-shadow: none;
  }
}

@media screen and (max-width: $screen--m-max) {
  .bouwapp-toolbar {
    &.hasSorting {
      > div:first-child {
        width: 100%;
        min-width: 100%;
        padding: 0 0 1.2rem 0;
      }

      > div:last-child {
        width: 100%;
        min-width: 100%;
      }
    }

    &.hasSortingAndFilter {
      > div:first-child,
      > div:nth-child(2),
      > div:last-child {
        width: 100%;
        min-width: 100%;
      }

      > div:nth-child(2) {
        padding: 2.4rem 0;
      }
    }
  }
}

/*------------------------------------*\
      BouwApp filter, overview
  \*------------------------------------*/
.bouwapp-filter {
  padding: 1.6rem 2.4rem;
  background: $color-white;
  border: 0.1rem solid $color-chat-device;
  border-radius: 1.2rem;

  > span {
    font-weight: $fontweight-bold;
    font-size: 1.8rem;
    color: $color-dark;
  }

  > ul,
  > ul > li {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  > ul > li {
    @include checkbox;
  }

  &.isHorizontal {
    ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 1.2rem -0.3rem -0.3rem -0.3rem;

      > li {
        margin: 0.6rem;
      }
    }

    + .bouwapp-overview {
      > div {
        > span {
          padding: 1.6rem 0;
        }
      }
    }
  }
}

/*------------------------------------*\
      BouwApp overview, lists
  \*------------------------------------*/
.bouwapp-overview {
  &__empty {
    padding-top: 8rem;
    margin: 0 auto;
    max-width: 48rem;
    font-weight: $fontweight-regular;
    font-size: 1.6rem;
    color: $color-dark;
    text-align: center;
  }

  &__list {
    > span {
      display: block;
      font-weight: $fontweight-bold;
      font-size: 1.6rem;
      color: $color-dark;
      padding: 1.6rem 0 1.6rem 0;
    }
  }
}

/*------------------------------------*\
      BouwApp overview, lists
  \*------------------------------------*/
.bouwapp-group {
  background: $color-white;
  border-radius: 1.2rem;
  border: 0.1rem solid $color-border;
  margin-bottom: 2.4rem;

  &__header {
    padding: 1.6rem 2.4rem 1.2rem 2.4rem;
    border-bottom: 0.1rem solid $color-border;

    > h2 {
      font-weight: $fontweight-bold;
      font-size: 1.8rem;
      color: $color-black;
      margin: 0;
    }

    > p {
      font-weight: $fontweight-regular;
      font-size: 1.6rem;
      line-height: 2.4rem;
      color: rgba($color-black, 0.5);
      margin: 0;
    }
  }

  &__row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 1.2rem 2.4rem;
    gap: 1.6rem;

    &.password {
      p {
        display: block;
        font-weight: 600;
        font-size: 1.6rem;
        color: $color-black;
        margin-bottom: 0.4rem;
      }

      app-form-group:not(:last-child) {
        .form-group {
          margin-bottom: 2.4rem;
        }
      }

      ul {
        list-style-type: 'X';
        padding-left: 1rem;
        margin: 0;
        margin-bottom: 0.8rem;

        li {
          padding-left: 1rem;
          margin: 0.8rem 0;
          color: $color-dark;

          &::marker {
            font-size: 2rem;
          }

          &.valid {
            list-style-type: '✓';
            color: $color-green;
          }
        }
      }
    }

    > p {
      width: 100%;
      min-width: 100%;
      font-weight: $fontweight-regular;
      font-size: 1.6rem;
      line-height: 2.4rem;
      color: rgba($color-black, 0.5);
      margin: 0;
    }

    &:first-of-type {
      padding-top: 2.4rem;
    }

    &:last-of-type {
      padding-bottom: 2.4rem;
    }

    > app-form-group:not(.bouwapp-group__item) {
      width: 100%;
      min-width: 20rem;
    }

    > div {
      flex: 1 1 0;
      min-width: 200px;
    }

    &.two {
      gap: 2.4rem;

      > div {
        width: calc(50% - 1.2rem);
        min-width: calc(50% - 1.2rem);
      }
    }

    &.three {
      gap: 2.4rem;

      > div {
        width: calc(33.3% - 1.6rem);
        min-width: calc(33.3% - 1.6rem);
      }
    }
  }

  &__item {
    width: 100%;
    position: relative;

    > div:not(:last-child) {
      padding-bottom: 2.4rem;
    }

    label + p {
      margin-bottom: 0.8rem;
    }

    &__media {
      width: 12rem;
      height: 12rem;
      background-size: cover;
      position: relative;
      border-radius: 8px;
      overflow: hidden;

      + div.error {
        font-size: 1.4rem;
        color: $color-red;
        margin-top: 0.8rem;
      }

      .input-file {
        &__clear {
          top: 0.8rem;
        }
      }
    }

    .form-group {
      margin: 0;

      label {
        display: block;
        font-weight: $fontweight-semibold;
        font-size: 1.6rem;
        color: $color-black;
        margin-bottom: 0.4rem;

        span.info {
          cursor: pointer;
          vertical-align: middle;
          display: inline-block;
          margin-top: -0.4rem;

          svg {
            display: block;
            fill: var(--primary);
          }
        }
      }

      .two-fa-link {
        position: absolute;
      }

      input:not([type='file']),
      select {
        @include input;
        margin: 0;
      }

      textarea {
        @include textarea;
      }
    }
  }
}

@media screen and (max-width: $screen--m-max) {
  .bouwapp-group {
    &__row {
      &.two {
        gap: 1.2rem;

        > div {
          width: 100%;
          min-width: 100%;
        }
      }

      &.three {
        gap: 1.2rem;

        > div {
          width: 100%;
          min-width: 100%;
        }
      }
    }
  }
}
