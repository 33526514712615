/*------------------------------------*\
    Components | Reset
\*------------------------------------*/
input,
textarea {
  width: 100%;
  box-sizing: border-box;

  &[disabled] {
    background-color: darken($inputBackground, 2%);
    cursor: not-allowed;
    user-select: none;
  }
}

select {
  background-image: url('/assets/img/icons/chevron-down.svg');
  background-size: 2rem;
  background-repeat: no-repeat;
  background-position: right 1.2rem center;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  padding: 1.2rem;
  width: 100%;
  border: 0.1rem solid $color-border;
  border-radius: 0.8rem;
  margin: 0.6rem 0;

  // Removes arrow within IE
  &::-ms-expand {
    display: none;
  }
}

.has-error {
  label {
    color: $color-red;
  }

  input:not([type='checkbox']):not([type='radio']),
  textarea,
  select,
  .bouwapp-quill,
  .input-file {
    border-color: $color-red !important;
    margin: 0;
  }

  .errors {
    margin-top: 0.4rem;
    color: $color-red;
  }
}

/*------------------------------------*\
    Components | Form Elements
\*------------------------------------*/
::-webkit-input-placeholder {
  color: rgba($color-dark, 0.5);
  opacity: 1;
}

:-moz-placeholder {
  color: rgba($color-dark, 0.5);
  opacity: 1;
}

::-moz-placeholder {
  color: rgba($color-dark, 0.5);
  opacity: 1;
}

:-ms-input-placeholder {
  color: rgba($color-dark, 0.5) !important;
  opacity: 1;
}

::-ms-input-placeholder {
  color: rgba($color-dark, 0.5);
  opacity: 1;
}

/*------------------------------------*\
    Components | Input
\*------------------------------------*/
@mixin input {
  box-sizing: border-box;
  font-family: 'Mukta', sans-serif;
  font-weight: $fontweight-regular;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: $color-dark;
  width: 100%;
  height: 5rem;
  border-radius: 0.8rem;
  border: 0.1rem solid $color-border;
  padding: 1.3rem 2rem;
  transition: 260ms ease;
}

@mixin textarea {
  @include input;
  padding: 2rem;
  min-height: 20rem;
  resize: vertical;
}

@mixin checkbox {
  input {
    display: none !important;

    & + label {
      position: relative;
      display: flex !important;
      flex-direction: row;
      align-items: center;
      font-weight: $fontweight-regular;
      font-size: 1.6rem;
      line-height: 100%;
      color: $color-dark;
      cursor: pointer;

      &:before,
      &:after {
        content: '';
        display: block;
        transition: 260ms ease;
      }

      &:before {
        width: 2rem;
        height: 2rem;
        margin-right: 1.2rem;
        border: 0.1rem solid $color-border;
        border-radius: 0.4rem;
      }

      &:after {
        content: '';
        position: absolute;
        width: 1.2rem;
        height: 1.2rem;
        top: 0.4rem;
        left: 0.4rem;
        border-radius: 0.2rem;
        background: $color-orange;
        opacity: 0;
        transform: scale(0.5);
      }

      &:hover {
        color: $color-orange;

        &:before {
          border-color: $color-orange;
        }
      }
    }

    &:checked + label {
      &:before {
        border-color: $color-orange;
      }

      &:after {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
}
