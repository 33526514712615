@import '/src/assets/scss/bouwapp/form-elements';

.smart-reporting {
  &__content {
    width: 100%;
  }

  &__wrapper {
    > p {
      font-weight: bold;
      font-size: 1.6rem;
      line-height: 2.4rem;
      color: $color-dark;
      margin-bottom: 8px;
    }
  }

  $self: &;

  &__error-text {
    color: $statusRed;
  }

  &__title {
    margin: 0;
    font-size: 32px;
  }

  &__list {
    border: 1px solid $color-border;
    border-radius: 1.2rem;
    overflow: hidden;

    .date,
    .time {
      font-size: 1.4rem;
    }

    .date {
      margin-right: 0.4rem;
    }

    .time {
      color: $color-grey;
    }

    ul.head,
    ul.body {
      padding: 0;
      margin: 0;
      list-style-type: none;

      > li {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        padding: 1.2rem 0.8rem;

        > span {
          flex: 1 1 0;
          overflow: hidden;
          padding: 0.4rem;
          max-height: 6rem;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          line-clamp: 3;
          overflow: hidden;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;

          &.datetime {
            display: flex;
            flex-wrap: wrap;
          }

          &.actions-column {
            > div {
              display: flex;
              .actions {
                display: inline-block;
                margin-left: auto;

                a {
                  cursor: pointer;
                  &:first-child {
                    color: $color-grey;
                    margin-right: 16px;

                    &:hover {
                      color: $color-grey-dark;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    ul.head {
      li {
        span {
          font-weight: $fontweight-bold;
          font-size: 14px;
          color: $color-black;
        }
      }
      li:first-child {
        background-color: $color-grey-box;
      }
    }

    ul.body {
      li {
        cursor: pointer;
        background: $color-white;
        transition: 260ms ease;
        font-weight: $fontweight-light;
        font-size: 14px;
        color: $color-dark;

        &:not(:last-child) {
          border-bottom: 1px solid $color-border;
        }

        span.label {
          margin: 0;
          font-size: 1.2rem;
          padding: 0.4rem 1.2rem;
          border-radius: 0.4rem;
        }
      }
    }
  }

  &__closed,
  &__pending {
    padding-top: 64px;
    margin-top: 64px;
    border-top: 1px solid $color-grey-light;

    > h2 {
      margin: 0 0 16px 0;
    }
  }

  &__notice-create {
    &__box {
      width: 75%;
    }

    &__theme {
      background-color: $color-white;
      color: $color-grey;
    }

    &__info {
      margin-right: 0.5rem;
    }

    &__media {
      width: 12rem;
      height: 12rem;
      background-size: cover;
      border-radius: 8px;
      overflow: hidden;
      position: relative;

      + div.error {
        font-size: 1.4rem;
        color: $color-red;
        margin-top: 0.8rem;
      }

      .input-file {
        &__clear {
          top: 0.8rem;
        }
      }
    }

    &__submit {
      position: fixed;
      left: 50%;
      transform: translateX(-50%);
      top: 1.6rem;
      z-index: 9999;
      background: $color-white;
      border: 1px solid $color-border;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
      border-radius: 12px;
      padding: 16px 20px;
      display: flex;
      flex-direction: row;

      > span {
        > h3 {
          font-size: 16px;
          font-weight: $fontweight-semibold;
          color: $color-dark;
          margin: 0;
        }

        > div {
          font-size: 14px;
        }
      }

      > button {
        @include button;
        @include button--m;
        @include button--icon;
        @include button--flat($color-blue, $color-white);
        margin-left: 10vw;
      }
    }
  }

  &__map {
    width: 100%;
    height: 28rem;
    margin-top: 16px;
    margin-bottom: 8px;

    .map {
      width: 100%;
      height: 100%;

      .mapboxgl-map {
        border-radius: 12px;
      }
    }

    &.large {
      height: 45rem;
    }
  }

  &__settings {
    .checkbox {
      margin-top: 10px;
    }
  }

  &__box {
    border-radius: 12px;

    .bouwapp-box {
      &__title,
      &__self {
        padding: 16px 24px;

        .form-group {
          margin-top: 16px;
        }

        .bouwapp-group__item .iti.iti--allow-dropdown .iti__dropdown-content {
          position: unset;
        }
      }
    }

    .input {
      @include input;
    }
  }

  &__themes {
    &__text {
      max-width: 40%;
    }

    &__modal {
      label {
        font-weight: $fontweight-semibold;
        color: $color-dark;
      }

      input {
        @include input;
      }

      .buttons {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  &__detail {
    display: flex;
    flex-direction: row;

    > div {
      &:first-child {
        width: 65%;
        margin-right: 32px;
      }

      &:last-child {
        width: 35%;
        max-height: 83rem;
      }
    }

    .bouwapp-box {
      &__self {
        > span {
          display: inline-block;
          width: calc(100% / 3);
          vertical-align: top;

          &:first-child {
            width: 100%;
          }

          > div {
            font-size: 16px;

            &:first-child {
              font-weight: $fontweight-bold;
              color: $color-dark;
              margin: 4px 0;
            }

            &:last-child {
              font-weight: $fontweight-regular;
              color: $color-grey;
              margin: 0 0 24px 0;

              > p {
                font-weight: $fontweight-regular;
                color: $color-grey;
                margin: 0 0 24px 0;
              }
            }

            select {
              background-color: $color-white;
              color: $color-dark;
              font-weight: $fontweight-semibold;
            }
          }
        }
      }
    }

    .responses {
      position: relative;
      overflow-x: hidden;
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      .messages {
        overflow-y: auto;
        overflow-x: hidden;
        padding: 24px;
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        .closed {
          width: calc(100% + 48px);
          text-align: center;
          font-weight: $semiBoldFontWeight;
          margin: 12px 0 12px -24px;
          padding-top: 24px;
          border-top: 1px solid $color-border;

          .feedback {
            margin-top: 16px;

            > .button {
              margin: 0 auto;
            }

            .awaiting-feedback,
            .given-feedback-label {
              font-size: 13px;
              color: $secondaryColor;
            }

            .given-feedback-label {
              margin-bottom: 0.8rem;
            }

            .given-feedback-result {
              &,
              svg {
                height: 60px;
              }
            }

            .given-feedback {
              display: flex;
              flex-direction: column;
              justify-content: center;
            }
          }
        }

        .loader {
          margin-top: 0;
        }

        .message {
          position: relative;
          padding: 8px 12px 24px 12px;
          margin: 0 0 16px 0;
          border-radius: 4px;
          width: 100%;
          max-width: calc(100% - 32px);

          &__head {
            display: flex;
            span.name {
              color: $color-dark;
              font-size: 14px;
              font-weight: $fontweight-bold;
            }

            &__icon {
              cursor: pointer;
              margin-left: auto;
              display: flex;
              align-items: center;
              span {
                margin-left: 5px;
                font-size: 12px;
                color: $color-dark;
                svg {
                  width: 16px;
                  height: 16px;
                }
              }
            }

            .has-inline-spinner {
              margin-left: 12px;
            }
          }

          &__content {
            white-space: pre-line;
            font-weight: $fontweight-light;
            font-size: 14px;
            line-height: 24px;
            margin-top: 2px;
            word-break: break-word;
            color: $color-dark;

            strong {
              font-weight: $fontweight-bold;
            }

            p {
              word-break: break-word;
              color: $color-dark;
            }

            a {
              font-weight: $fontweight-semibold;
              color: $color-blue-dark;
              text-decoration: underline;
            }

            img {
              width: 100%;
              max-width: 100%;
              height: 130px;
              object-fit: cover;
              border-radius: 4px;
              border: 1px solid $color-white;
              margin: 4px 0;
            }
          }

          &__date {
            position: absolute;
            bottom: 4px;
            right: 8px;
            color: $color-grey;
            font-size: 10px;
            font-weight: $fontweight-light;
            text-align: right;
          }

          // Messages send by user
          &.device {
            background: $color-chat-device;
            margin-right: auto;
          }

          // Messages send by admin
          &.support {
            background: $color-yellow-light;
            margin-left: auto;
          }

          &.animate {
            animation: fade 0.3s ease-in;
          }
        }
      }
    }

    .bottom {
      width: 100%;
      position: relative;

      &__toolbar {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 42px;
        border-top: 1px solid $color-border;
        border-bottom: 1px solid $color-border;

        &__markup {
          padding: 0;
          border: 0;

          > button {
            width: 40px;
            height: 40px;
            border-right: 1px solid $color-border;
            border-radius: 0;
            box-shadow: none;
            text-align: center;
            padding: 10px !important;
            float: none;
            margin: 0;
            background: $color-white;
            transition: 260ms ease;

            svg {
              width: 20px;
              height: 20px;
              transition: 260ms ease;
            }

            &:hover {
              background: rgba($color-border, 0.1);
            }
          }
        }
      }

      &__compose {
        position: relative;
        height: 200px;
        display: flex;
        flex-direction: row;

        .ql-container.ql-snow .ql-editor.ql-blank::after {
          color: $color-grey-light;
          font-weight: $fontweight-light;
        }

        > div {
          width: calc(100% - 56px);
          min-width: calc(100% - 56px);

          quill-editor {
            width: 100%;
          }
        }

        > button.send {
          &.touched {
            color: $color-orange;
          }
          width: 48px;
          min-width: 48px;
          height: 48px;
          background: $color-white;
          color: $color-grey-light;
          font-weight: $fontweight-bold;
          border: 0;
          padding: 0;
          margin: 4px;
          box-shadow: none;
          text-align: center;
          border-radius: 0;

          &:hover {
            background: $color-white;
            filter: none;
            transform: scale(1.05);
            color: darken($color-orange, 10%);
          }
        }
      }

      .ql-container.ql-snow {
        .ql-tooltip {
          top: -68px !important;
          border-left: 0;
          border-right: 0;
          border-radius: 0;
        }
      }
    }
  }
}

@media screen and (min-width: $screen--l-min) and (max-width: $screen--xl) {
  .smart-reporting__list.notices ul.head,
  .smart-reporting__list.notices ul.body {
    > li {
      &.isCustomer {
        > span {
          &:nth-child(1) {
            flex-basis: 100%;
          }
        }
      }
      > span {
        &:nth-child(1) {
          flex-basis: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: $screen--m-max) {
  .smart-reporting {
    &__content {
      flex-direction: column;
    }

    &__wrapper {
      width: 100%;
      min-width: 100%;
      padding: 0;

      > p {
        margin-top: 0.8rem;
        text-align: center;
      }
    }

    $self: &;

    &__toolbar {
      flex-direction: column;

      > div {
        &:first-child {
          width: 100%;
          min-width: 100%;
          margin-bottom: 16px;

          .checkbox {
            margin-top: 0;
          }
        }

        &:last-child {
          width: 100%;
          min-width: 100%;
        }
      }
    }

    &__list {
      ul.head,
      ul.body {
        > li {
          padding: 1.6rem;

          > span {
            &:nth-child(2) {
              flex-grow: 1;
              justify-content: end;
            }

            &:nth-child(3) {
              padding-top: 0.8rem;
            }

            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(6),
            &:last-child {
              width: 100%;
              min-width: 100%;
              padding-bottom: 0.8rem;

              &:before {
                content: attr(aria-label);
                display: block;
                color: $color-grey;
              }
            }

            &:nth-last-child(1 of .datetime) {
              display: inline-block;
            }

            &:last-child {
              padding-bottom: 0;
            }
          }
        }
      }

      ul.head {
        display: none;
      }
    }

    &--tabs {
      margin-top: -24px;
    }

    &__notice-create__submit {
      > span > div {
        display: none;
      }
    }
  }
}

@media screen and (min-width: $screen--m-min) and (max-width: $screen--l-max) {
  .smart-reporting {
    &__content {
      flex-direction: column;
    }

    &__wrapper {
      width: 100%;
      min-width: 100%;
      padding: 0;

      > p {
        margin-top: 0.8rem;
        text-align: center;
      }
    }

    $self: &;

    &__list {
      ul.head,
      ul.body {
        > li {
          &.isCustomer {
            > span {
              &:nth-child(3),
              &:nth-child(4),
              &:nth-child(5),
              &:nth-child(6),
              &:last-child {
                width: 20%;
                min-width: 20%;
              }
            }
          }

          > span {
            padding: 0.8rem;

            &:nth-child(1) {
              width: unset;
            }

            &:nth-child(2) {
              width: calc(100% - 120px);
              min-width: calc(100% - 120px);
              justify-content: end;
              flex-grow: 1;
            }

            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(6),
            &:last-child {
              width: 33%;
              min-width: 33%;
              flex-grow: 1;

              &:before {
                content: attr(aria-label);
                display: block;
                color: $color-grey;
              }
            }

            &:nth-last-child(1 of .datetime) {
              display: inline-block;
            }
          }
        }
      }

      ul.head {
        display: none;
      }
    }
  }
}

@media screen and (max-width: $screen--xl) {
  .smart-reporting__detail {
    flex-direction: column;

    > div {
      width: 100% !important;

      &:first-child {
        margin-right: 0;
      }
    }
  }
}

@media screen and (max-width: $screen--s) {
  .smart-reporting__detail .bouwapp-box__self {
    > span {
      width: 50%;
    }
  }
}
