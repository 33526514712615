@import '/src/assets/scss/bouwapp/buttons';

.ai-loading {
  border-top: 1px solid #cdcdcd;
  padding: 16px;
  text-align: center;
  font-weight: 600;
  color: $color-dark;

  span:first-child {
    color: $color-grey-light;
  }

  span:nth-child(2) {
    color: $primaryColor;
  }
}

.ai-tool {
  border-top: 1px solid $color-border;
  padding: 16px;
  font-size: 15px;

  &__top {
    p {
      font-weight: 600;
      color: $color-dark;

      span {
        color: $primaryColor;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    flex-wrap: wrap;

    button {
      margin-bottom: 10px;
      &:hover {
        cursor: pointer;
      }

      &.back {
        margin-left: auto;
        border-color: $color-grey-light;

        &[disabled] {
          opacity: 1;
        }

        &:hover {
          border-color: $color-dark;
        }
      }
    }

    .dropdown {
      position: relative;
      display: inline-block;
      margin-bottom: 10px;

      button {
        margin-bottom: 0;
      }

      &:hover:not(.disabled) {
        .dropdown__content {
          display: block;
        }

        .dropdown__button {
          background-color: $color-dark;
          border-radius: 10px 10px 0 0;

          p {
            color: $color-white;
          }

          .stylus svg {
            fill: $color-white;
          }

          .caret svg polygon {
            fill: $color-white;
          }
        }
      }

      &__content {
        display: none;
        position: absolute;
        min-width: 200px;
        background-color: $color-white;
        border: 1px solid $color-dark;
        border-top: none;
        border-radius: 0 0 10px 10px;
        width: calc(100% - 10px);
        z-index: 1;

        ul {
          list-style: none;
          padding: 0;
          margin: 0;

          li {
            cursor: pointer;
            padding: 6px 10px;

            p {
              font-size: 15px;
            }

            &:hover {
              background-color: $color-dark;

              p {
                color: $color-white;
              }
            }
          }
        }
      }
    }
  }
}
