section.authentication {
  padding: 0 16px;

  .content {
    width: 100%;
    max-width: 460px;
    margin: 90px auto 20px auto;

    .logo {
      text-align: center;
      margin-bottom: 90px;

      img {
        width: 100%;
        max-width: 310px;
      }
    }

    .actions {
      margin-top: 15px;

      &.form-group {
        margin-top: 20px;

        button {
          float: right;
        }

        a {
          float: left;
          line-height: 45px;
          font-size: 18px;
        }
      }
    }
  }

  form {
    margin-top: 18px;
  }
}

.twofactor {
  width: 100%;
  max-width: 374px;
  margin: 140px auto;
  padding: 40px 32px 32px 32px;
  background: $color-white;
  border: 1px solid $color-grey-light;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
  border-radius: 2px;

  &__logo {
    text-align: center;
  }

  &__form {
    h1 {
      font-size: 18px;
      font-weight: $fontweight-bold;
      color: $color-dark;
      margin: 32px 0 4px 0;
      text-align: center;
    }

    .errors {
      font-size: 14px;
    }

    p {
      font-size: 16px;
      font-weight: $fontweight-regular;
      color: $color-dark;
      text-align: center;
    }

    input[type='number'] {
      width: 100%;
      height: 40px;
      border: 1px solid $color-grey-light;
      border-radius: 2px;
      font-size: 16px;
      font-weight: $fontweight-regular;
      padding: 0 16px;
      box-shadow: none;
      margin-top: 24px;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    button {
      width: 100%;
      height: 40px;
      font-size: 16px;
      font-weight: $fontweight-bold;
      color: $color-white;
      background: $color-orange;
      margin: 16px 0 0 0;
      box-shadow: none;
      border-radius: 2px;
    }
  }

  &__resend {
    margin-top: 24px;
    padding-top: 16px;
    border-top: 1px solid $color-grey-light;
    text-align: center;

    p {
      cursor: pointer;
      font-weight: $fontweight-bold;
      font-size: 16px;
      color: $color-orange;
      margin: 0;
      padding: 0;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

@media screen and (max-width: $screen--m-max) {
  .twofactor {
    height: 100%;
    max-width: 100%;
    margin: 0;
    border: none;
    box-shadow: none;
    border-radius: 0;
  }
}
