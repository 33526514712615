/*------------------------------------*\
    Colors
\*------------------------------------*/
$primaryColor: #f5b049;

:root {
  --primary: #{$primaryColor};
}

$color-white: #fff;
$color-black: #000;
$color-dark: #212121;
$color-orange: #f5b049;
$color-yellow-light: #ffefb9;
$color-grey-darker: #d3d3d3;
$color-grey-dark: #727272;
$color-grey: #a3a3a3;
$color-grey-light: #cdcdcd;
$color-grey-box: #f3f3f3;
$color-border: #e9e9e9;
$color-red: #fc5447;
$color-red-dark: #f35c7b;
$color-red-light: #e1667d;
$color-red-darker: #c04861;
$color-blue: #3373ea;
$color-blue-dark: #006ff2;
$color-blue-darker: #0057bf;
$color-chat-device: #e9e9e9;
$color-read: #49da16;
$color-green: #50c878;
$color-background: #f3f1ef;

$white: #fff;
$secondaryColor: #a3a3a3;
$disabledPrimaryColor: #f5dab1;

$secondaryPageBackground: $color-white;
$defaultPageBackground: #fafafa;
$breadcrumbsBackground: #f0f0f0;
$defaultCoverImageBackground: #f0f0f0;
$headerBackground: $color-white;
$inputBackground: $color-white;
$breadcrumbsBackground: rgba(194, 194, 194, 0.15);
$buttonPrimaryBackground: var(--primary);
$tileBackground: $color-white;
$datePickerBackground: #f6f6f6;
$modalOverlayBackground: rgba($color-dark, 0.6);
$modalBackground: $color-white;

$labelInfoColor: #77ced8;
$labelPrimaryColor: var(--primary);
$labelSuccessColor: #fc5447;

$labelTextColor: $color-white;
$paragraphTextColor: rgba(62, 62, 62, 0.8);
$primaryTextColor: #212121;
$linkTextColor: var(--primary);
$secondaryTextColor: #3e3e3e;
$mutedTextColor: #999999;
$secondarySelectTextColor: #a3a3a3;
$breadcrumbsTextColor: #a3a3a3;
$inverseTextColor: $color-white;
$tertiaryColor: #7f7f7f;

$inputBorder: #e3e3e3;
$radioBorder: #999999;
$defaultBorder: #eeeeee;
$selectBorder: $defaultBorder;
$menuBorder: #efefef;
$tileInnerBorder: #f0f0f0;
$areaMarkerBorder: #c1c1c1; /* for file uploads etc. */
$ticketBorder: #d5d5d5;

$statusGreen: #a5e253;
$statusOrange: #f5b049;
$statusRed: #fc5447;
$statusGrey: #a3a3a3;
$statusDark: #212121;
$statusBlue: #0057bf;

$scale1: #fc5447;
$scale2: #f98248;
$scale3: #f5b049;
$scale4: #cfe238;
$scale5: #a5e253;

$separator: #d8d8d8;

$alertInfo: #77ced8;
$alertDanger: $statusRed;
$alertSuccess: $statusGreen;

$formErrorColor: $statusRed;

$toggleBackground: #e0e0e0;
$styleBarBackground: #f6f6f6;
$styleBarBorder: #e3e3e3;
$commentsBorder: #e3e3e3;
$statusBackground: #efefef;
