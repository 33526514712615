/*
 * Import app styles
 */
@import 'variables/line-heights';
@import 'variables/weights';
@import 'variables/zindices';
@import 'variables/breakpoints';
@import 'variables/colors';

/* Import mixins */
@import 'mixins/shadows';
@import 'mixins/round';
@import 'mixins/btn';
@import 'bouwapp/form-elements';

/* Import vendor files */
@import 'vendor/bootstrap';
@import 'intl-tel-input/build/css/intlTelInput.css';
@import 'quill/dist/quill.core.css';
@import 'quill/dist/quill.snow.css';
@import 'quill2-emoji/dist/style.css';
@import '@ng-select/ng-select/themes/default.theme.css';

@import 'base';
@import 'global';
@import 'bouwapp/buttons';

/* Import components */
@import 'components/modal';
@import 'components/forms';
@import 'components/buttons';
@import 'components/charts';
@import 'components/footer';
@import 'components/header';
@import 'components/data-filter';
@import 'components/dialog-status';
@import 'components/date-pickers';
@import 'components/inline-svg';
@import 'components/labels';
@import 'components/qr';
@import 'components/list';
@import 'components/loaders';
@import 'components/skeleton-loader';
@import 'components/menu';
@import 'components/switches';
@import 'components/polls';
@import 'components/tables';
@import 'components/tiles';
@import 'components/toggle';
@import 'components/navigation';
@import 'components/livestreams';
@import 'components/update-location';
@import 'components/update-categories';
@import 'components/two-factor';
@import 'components/ai-tool';
@import 'components/alerts';
@import 'components/conversation';
@import 'components/satisfaction-distribution';
@import 'components/customer-picker';
@import 'components/phases';
@import 'components/project-picker';
@import 'components/sticky-submit';
@import 'components/multi-attachment';
@import 'components/update-location';
@import 'components/tabs';
@import 'components/avatar';

/* Import pages */
@import 'partials/smart-reporting';
@import 'partials/authentication';
@import 'partials/content';
@import 'partials/news';
@import 'partials/article';
@import 'partials/tickets';
@import 'partials/profile';
@import 'partials/feedback';
@import 'partials/filter';
@import 'partials/audit-log';

@import url('https://fonts.googleapis.com/css?family=Mukta:300,400,500,600,700');
@import url('https://fonts.googleapis.com/css?family=Catamaran:300,400,500,600,700');

@import 'bouwapp/app';
@import 'components/quill';

@import 'partials/projects';
