/*------------------------------------*\
    Feedback (overview)
\*------------------------------------*/
.feedback {
  &__head {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;

    > div:first-child {
      h1 {
        font-weight: $fontweight-bold;
        font-size: 36px;
        line-height: 46px;
        color: $color-dark;
        margin: 0;
      }

      p {
        margin-top: 4px;
        font-weight: $fontweight-medium;
        font-size: 16px;
        line-height: 27px;
        color: $color-dark;
        max-width: 600px;
      }
    }

    > div:last-child {
      margin-left: auto;
    }
  }

  &__filter {
    padding: 24px;
    margin: 40px 0;
    background: $color-grey-box;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    > div {
      &:first-child {
        width: calc(100% - 540px);
        min-width: calc(100% - 540px);
      }

      &:nth-child(2),
      &:nth-child(3) {
        width: calc(270px - 24px);
        min-width: calc(270px - 24px);
        margin-left: 24px;
      }

      label {
        display: block;
        font-size: 16px;
        line-height: 26px;
        font-weight: $fontweight-semibold;
        color: $color-dark;
        margin: 0 0 4px 0;
      }

      input[type='search'] {
        width: 100%;
        border-radius: 6px;
        border: 1px solid $color-border;
        box-shadow: none;
        margin: 0;
        height: 50px;
        font-size: 16px;
        transition: 260ms ease;
      }

      select {
        width: 100%;
        border-radius: 6px;
        font-weight: $fontweight-regular;
        border: 1px solid $color-border;
        box-shadow: none;
        margin: 0;
        transition: 260ms ease;
        height: 50px;
        font-size: 16px;
        padding: 0 16px;
      }
    }
  }

  &__count {
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: $color-dark;
    margin-bottom: 8px;
  }

  &__list {
    &__hidden {
      display: none;
    }
    table {
      table-layout: fixed;
      border-spacing: 0;
      border-collapse: collapse;
      font-size: 14px;

      thead {
        background-color: $color-grey-box;
        border: 1px solid $color-border;
        th {
          font-weight: $fontweight-semibold;
          color: $color-dark;
          padding: 16px;
          border: none;
        }
      }

      tbody {
        tr {
          box-shadow: 0 2px 4px 0 rgba($color-black, 0.08);

          td {
            line-height: 24px;
            padding: 1.2rem 1rem;
            border-top: 1px solid $color-border;
            border-bottom: 1px solid $color-border;
            word-wrap: break-word;

            .date,
            .time {
              font-size: 1.4rem;
            }

            .date {
              margin-right: 0.4rem;
            }

            .time {
              color: $color-grey;
            }

            .label {
              border-radius: 0.4rem;
            }

            &:first-child {
              border-left: 1px solid $color-border;
              border-radius: 6px 0 0 6px;
            }

            &:nth-child(2) {
              span {
                display: block;

                &:first-child {
                  cursor: pointer;

                  &:hover {
                    text-decoration: underline;
                  }
                }

                &:last-child {
                  font-weight: $fontweight-regular;
                  font-size: 14px;
                  font-style: italic;
                  margin-top: 4px;
                }
              }
            }

            &:last-child {
              border-right: 1px solid $color-border;
              border-radius: 0 6px 6px 0;

              > div {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-end;

                > a {
                  display: block;
                  margin-left: 12px;
                }
              }
            }

            .tile-actions {
              display: inline-block;
              margin-left: auto;

              a {
                cursor: pointer;
                &:first-child {
                  font-weight: $fontweight-regular;
                  color: $color-grey;
                  &:hover {
                    color: $color-red;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $screen--xl) {
  .feedback {
    &__list {
      table {
        tbody {
          tr {
            td:last-child {
              > div.tile-actions {
                flex-direction: column;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $fullhd--height) {
  .feedback {
    &__count {
      margin: 0.8rem 0;
      text-align: center;
    }

    &__head {
      > div:first-child {
        width: 100%;
        min-width: 100%;

        p {
          max-width: 100%;
        }
      }

      > div:last-child {
        width: 100%;
        min-width: 100%;
        margin-top: 24px;

        a {
          display: inline-block;
        }
      }
    }

    &__list {
      &__hidden {
        display: block;
      }
      table {
        thead {
          display: none;
        }

        tbody {
          tr {
            display: flex;
            flex-direction: column;
            border: 1px solid $color-border;
            border-radius: 6px;
            background: $color-white;
            margin-bottom: 16px;

            td {
              width: 100%;
              padding: 8px 16px;
              border: 0;
              border-radius: 6px;
              background: none;

              td:nth-child(3) {
                display: block;
              }

              &:before {
                content: attr(data-th);
                display: block;
                font-weight: $fontweight-semibold;
                color: $color-dark;
              }

              &:first-child,
              &:last-child {
                border: 0;
                border-radius: 0;
              }

              &:first-child {
                padding-top: 16px;
              }

              &:last-child {
                > div.tile-actions {
                  flex-direction: row;
                }
                padding-bottom: 16px;
                &:before {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $screen--m-max) {
  .feedback {
    &__head {
      > div:first-child {
        h1 {
          font-size: 32px;
          line-height: 42px;
        }
      }
    }

    &__filter {
      padding: 16px;
      margin: 32px 0;

      > div {
        &:first-child {
          width: 100%;
          min-width: 100%;
        }

        &:nth-child(2),
        &:nth-child(3) {
          width: 100%;
          min-width: 100%;
          margin: 16px 0 0 0;
        }
      }
    }
  }
}

@media screen and (min-width: $screen--m-min) and (max-width: $screen--l-max) {
  .feedback {
    &__filter {
      > div {
        &:first-child {
          width: 100%;
          min-width: 100%;
          margin-bottom: 24px;
        }

        &:nth-child(2),
        &:nth-child(3) {
          width: calc(50% - 12px);
          min-width: calc(50% - 12px);
          margin: 0;
        }

        &:nth-child(2) {
          margin-right: 24px;
        }
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .feedback {
    &__list {
      &__hidden {
        display: block;
      }
      table {
        thead {
          th:nth-child(3) {
            display: none;
          }
        }
        tbody {
          td:nth-child(3) {
            display: none;
          }
        }
      }
    }
  }
}
/*------------------------------------*\
    Feedback (creation)
\*------------------------------------*/
.feedback-editor {
  .bouwapp-header__content {
    p {
      width: 50%;
    }
  }
  .bouwapp-header__text {
    display: flex;
    align-items: center;
    > span {
      margin-left: 5px;
    }
  }

  input,
  select {
    scroll-margin-top: 175px;
  }

  .form-group {
    > label {
      span.required {
        margin-left: 0;
      }
    }
  }

  .count {
    position: absolute;
    top: -10px;
    left: -10px;
    background-color: $color-dark;
    border-radius: 50%;
    width: 2.4rem;
    height: 2.4rem;
    color: $color-white;
    line-height: 2.4rem;
    font-size: 1.4rem;
    text-align: center;
  }

  .copy-link {
    svg {
      margin-right: 5px;
    }
    transition:
      color 0.3s ease,
      transform 0.3s ease;
  }

  .copy-link.copied {
    color: $color-green;
    box-shadow: 0 0 0 0.1rem $color-green;

    svg {
      fill: $color-green;
    }

    &:hover {
      background-color: $color-green;
      color: $color-white;
      svg {
        fill: $color-white;
      }
    }

    animation: bounce 0.7s ease;
  }

  @keyframes bounce {
    0%,
    100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-5px);
    }
  }

  &__tabs {
    margin-top: 20px;
    &:before,
    &:after {
      content: '';
      width: 100%;
      height: 48px;
      position: absolute;
      top: 0;
      background: $color-white;
      border-bottom: 1px solid $defaultBorder;
    }

    &:before {
      left: -100%;
    }

    &:after {
      right: -100%;
    }

    ul {
      display: flex;
      flex-direction: row;
      justify-content: start;
      list-style-type: none;
      margin: 0;
      padding: 0;

      li:first-child {
        margin-right: 24px;
      }

      li:has(.counter) {
        &:after {
          width: calc(100% - 2.4rem);
        }
      }

      li {
        position: relative;
        font-size: 16px;
        font-weight: $fontweight-medium;
        line-height: 48px;
        color: $color-black;
        cursor: pointer;
        transition: 260ms ease;

        .counter {
          background-color: var(--primary);
          color: $color-white;
          transition: 260ms ease;
          margin-left: 5px;
          min-width: 2.4rem;
          height: 2.4rem;
          line-height: 2.4rem;
          text-align: center;
          border-radius: 0.6rem;
          font-size: 1.4rem;
          padding: 0 0.4rem;
        }

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          width: 100%;
          height: 3px;
          border-radius: 2px 2px 0 0;
          background: $color-orange;
          opacity: 0;
          transform: scaleY(0);
          transform-origin: bottom center;
          transition: 260ms ease;
        }

        &:hover {
          &:after {
            opacity: 1;
            transform: scaleY(1);
          }
        }

        &.active {
          color: $color-dark;

          &:after {
            opacity: 1;
            transform: scaleY(1);
          }
        }
      }
    }
  }

  &__head {
    margin-bottom: 40px;
    border-bottom: 1px solid $color-border;
    display: flex;
    flex-direction: row;

    > div {
      &:first-child {
        width: calc(100% - 200px);
        min-width: calc(100% - 200px);
      }
    }

    h1 {
      font-weight: $fontweight-bold;
      font-size: 36px;
      line-height: 46px;
      color: $color-dark;
      margin: 0;
    }

    p {
      margin-top: 4px;
      font-size: 16px;
      line-height: 27px;
      color: $color-dark;
      max-width: 600px;
    }
  }

  &__default {
    .form-split {
      margin-bottom: 24px;

      > div:first-child {
        width: 240px;
        min-width: 240px;
      }

      > div:last-child {
        width: calc(100% - 264px);
        min-width: calc(100% - 264px);
        margin-left: 24px;
      }
    }

    select {
      font-weight: $fontweight-regular;
    }
  }

  &__separator {
    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;
    font-weight: $fontweight-regular;
    font-size: 16px;
    line-height: 28px;
    color: $color-black;
    text-align: center;
    margin: 40px 0;

    > span {
      margin: 0 5px;
    }

    svg {
      fill: $color-black;
      display: block;
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 13px;
      width: calc(50% - 100px);
      height: 1px;
      background: $color-black;
    }

    &:before {
      left: 0;
    }
    &:after {
      right: 0;
    }
  }

  &__question {
    position: relative;
    width: 100%;
    min-width: 100%;
    background: $color-white;
    border: 1px solid $color-border;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    padding: 24px;
    margin-bottom: 24px;
    scroll-margin-top: 150px;

    .checkbox {
      > label {
        > span {
          color: $color-black;
        }
      }
    }

    &__header {
      display: flex;
      border-bottom: 1px solid $color-border;
      padding-bottom: 10px;

      > article:first-child {
        display: flex;
        flex-grow: 1;

        > h2 {
          position: relative;
          font-size: 18px;
          line-height: 30px;
          color: $color-dark;
          margin: 0;

          &:after {
            content: '';
            position: absolute;
            top: 0;
            right: -13px;
            width: 1px;
            height: 30px;
            background: $color-border;
          }
        }
      }
    }

    select {
      font-weight: $fontweight-regular;
    }

    .form-group {
      margin: 0;
      position: relative;

      > label {
        display: block;
        font-size: 16px;
        font-weight: $fontweight-semibold;
        color: $color-dark;
        margin: 0;

        &:not(.hasDescription) {
          margin: 0 0 4px 0;
          line-height: 28px;
        }
      }
    }

    .form-split {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    &__start {
      padding-top: 10px;
      > .form-split {
        margin-bottom: 8px;

        > div:first-child {
          width: calc(100% - 264px);
          min-width: calc(100% - 264px);
          margin-right: 24px;
        }

        > div:last-child {
          width: 240px;
          min-width: 240px;
        }
      }

      .form-group.description {
        margin-top: 24px;
      }

      .show {
        .disabled {
          display: none;
        }
        .enabled,
        .form-group.description {
          display: block;
        }
      }

      .hide {
        .disabled {
          display: block;
        }
        .enabled,
        .form-group.description {
          display: none;
        }
      }
    }
    &__bottom {
      display: none;
      position: relative;
      justify-content: center;

      > span {
        background-color: $color-white;
        position: absolute;
        cursor: pointer;
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $color-black;
        border-radius: 6px;
        transition: 260ms ease;

        svg {
          fill: $color-black;
        }

        &:hover {
          background-color: $color-black;
          border-color: $color-white;

          svg {
            fill: $color-white;
          }
        }
      }
    }

    &:hover {
      .feedback-editor__question__bottom {
        display: flex;
      }
    }

    &__delete {
      &__container {
        display: flex;

        div {
          display: flex;
          align-items: center;
          cursor: pointer;
        }

        > div:nth-last-child(2) {
          position: relative;
          margin-right: 20px;
          &:after {
            content: '';
            position: absolute;
            top: 0;
            right: -10px;
            width: 1px;
            height: 30px;
            background: $color-border;
          }
        }

        &__button {
          margin-left: 5px;

          svg path {
            fill: $color-red !important;
            transition: 260ms ease;
          }
        }

        > div:last-child {
          display: flex;
          position: relative;
          text-align: center;
          color: $color-red;
          transition: 260ms ease;

          &:hover {
            color: darken($color-red, 25%);
            svg path {
              fill: darken($color-red, 25%) !important;
            }
          }
        }
      }
    }

    &__required {
      position: relative;
      margin-left: 25px;
      padding: 0 52px 0 24px;

      label {
        display: block;
        font-weight: $fontweight-regular;
        font-size: 16px;
        line-height: 24px;
        color: $color-dark;
        padding-top: 1px;
        margin: 0;
        margin-left: 25px;
        cursor: pointer;
        user-select: none;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 40px;
          height: 24px;
          border-radius: 16px;
          background: $color-border;
          transition: 260ms ease;
        }

        &:after {
          content: '';
          position: absolute;
          top: 4px;
          left: 5px;
          width: 16px;
          height: 16px;
          background: $color-white;
          border-radius: 50%;
          transition: 260ms ease;
        }
      }

      input[type='checkbox']:checked + label {
        &:before {
          background: $color-orange;
        }

        &:after {
          transform: translateX(16px);
        }
      }
    }

    &__options {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        > div {
          &:first-child {
            width: calc(60% - 12px);
            min-width: calc(60% - 12px);
            display: flex;
            flex-direction: row;
            margin-right: 24px;

            app-form-group {
              width: 100%;
            }

            input {
              scroll-margin-top: 175px;
              width: calc(100% - 50px);
              min-width: calc(100% - 50px);
            }

            span {
              display: block;
              width: 50px;
              height: 50px;
              cursor: pointer;
              text-align: center;
              padding: 15px;

              svg polygon {
                fill: $color-border !important;
                transition: 260ms ease;
              }

              &:hover {
                svg polygon {
                  fill: $color-red !important;
                }
              }
            }
          }

          &:last-child {
            width: calc(40% - 12px);
            min-width: calc(40% - 12px);
          }
        }

        &:not(:first-child):not(:last-child) {
          margin-bottom: 8px;
        }

        &:first-child > div {
          display: block;
          font-size: 16px;
          font-weight: $fontweight-semibold;
          color: $color-dark;
          margin-bottom: 4px;
        }
      }
    }

    &__scale {
      &__options {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        > div.form-group {
          width: 25%;
          min-width: 25%;
          margin-right: 24px;
        }
      }

      &__toggle {
        margin-top: 16px;
        padding-bottom: 40px;

        p {
          font-size: 14px;
          color: $color-grey;
          padding-left: 31px;
        }
      }
    }
  }

  &__nav {
    .sticky-submit__buttons {
      width: 100%;
      justify-content: space-between;
      > div {
        display: flex;
      }

      select {
        margin: 0;
        margin-right: 20px;
        height: 40px;
        padding: 0 4rem 0 1.2rem;
        width: 25%;
      }
    }

    &.isEdit {
      .feedback-editor__nav__top {
        box-shadow: none;
        border-bottom: 1px solid $defaultBorder;
      }
    }

    &__top {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 6px 16px;
      z-index: 1;
      position: relative;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.24);

      &__left {
        display: flex;
        flex-direction: column;
      }

      &__right {
        display: flex;
        flex-direction: row;
        margin-left: auto;
      }
    }
  }

  &__notice {
    font-weight: $fontweight-semibold;
    font-size: 14px;
    line-height: 24px;
    color: $color-dark;
    padding: 16px 24px;
    border-radius: 6px;
    margin: 0 0 32px 0;
    background: $color-border;

    display: flex;
    align-items: center;

    > span {
      display: flex;
      margin-right: 10px;
    }
  }

  &__summary {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -12px;

    > div {
      width: calc(20% - 24px);
      min-width: calc(20% - 24px);
      margin: 0 12px;
      background: $color-white;
      border: 1px solid $color-border;
      box-shadow: 0 2px 4px 0 rgba($color-black, 0.08);
      border-radius: 12px;
      padding: 16px;

      p {
        font-weight: $fontweight-bold;
        font-size: 16px;
        line-height: 22px;
        color: $color-black;
        margin: 0;
      }

      span {
        font-weight: $fontweight-bold;
        font-size: 16px;
        line-height: 24px;
        color: var(--primary);
      }
    }
  }

  &__graph {
    position: relative;
    margin-top: 24px;
    background: $color-white;
    border: 1px solid $color-border;
    box-shadow: 0 2px 4px 0 rgba($color-black, 0.08);
    border-radius: 6px;
    padding: 24px;

    &__no-stats {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 10px;

      > span:first-child {
        display: flex;
        margin-right: 5px;
      }

      a {
        font-weight: $fontweight-semibold;
        margin-left: 4px;
      }

      p {
        font-weight: $fontweight-semibold;
        color: $color-black;
        strong {
          color: var(--primary);
        }
      }
    }

    &__head {
      padding-bottom: 16px;
      margin-bottom: 16px;
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid $color-border;

      &__count {
        flex-grow: 1;
        display: flex;
        justify-content: flex-end;
        > span {
          line-height: 30px;
          font-weight: $fontweight-bold;
          color: var(--primary);
        }
      }

      &__title {
        display: flex;
        flex-wrap: wrap;

        > span:first-child {
          position: relative;
          font-weight: $fontweight-bold;
          &:after {
            content: '';
            position: absolute;
            top: 0;
            right: -10px;
            width: 1px;
            height: 30px;
            background: $color-border;
          }
        }

        span {
          font-size: 16px;
          line-height: 30px;
          margin: 0 10px;
          color: $color-black;
          font-weight: $fontweight-regular;
        }

        p {
          font-weight: $fontweight-bold;
          font-size: 16px;
          line-height: 24px;
          color: $color-black;
          margin: 0;
        }
      }

      &:last-child {
        margin-left: auto;

        span {
          font-weight: $fontweight-semibold;
          font-size: 14px;
          line-height: 22px;
          color: $color-grey;
        }
      }
    }
  }
}

@media screen and (max-width: $screen--m-max) {
  .feedback-editor {
    .bouwapp-header__content {
      width: 100%;
      padding-top: 20px;
    }
    padding-top: 20px;

    &__nav {
      &__top {
        flex-direction: column;
        &__right {
          margin-left: unset;
        }
      }
    }

    &__head {
      flex-direction: column;

      > div {
        &:first-child,
        &:last-child {
          width: 100%;
          min-width: 100%;
        }

        &:last-child {
          padding-top: 32px;

          button {
            margin: 0;
          }

          span {
            text-align: left;
          }
        }
      }

      h1 {
        font-size: 32px;
        line-height: 42px;
      }
    }

    &__default {
      .form-split {
        margin-bottom: 24px;

        > div:first-child {
          width: 100%;
          min-width: 100%;
        }

        > div:last-child {
          width: 100%;
          min-width: 100%;
          margin: 0;
        }
      }
    }

    &__question {
      padding: 16px;
      margin-bottom: 25px;
      scroll-margin-top: 190px;

      &__start {
        margin-bottom: 16px;

        > .form-split {
          margin-bottom: 8px;

          > div:first-child {
            width: 100%;
            min-width: 100%;
            margin: 0 0 16px 0;
          }

          > div:last-child {
            width: 100%;
            min-width: 100%;
          }
        }

        > .form-group.description {
          margin-top: 16px;
        }
      }

      &__bottom {
        display: flex;
      }

      &__delete {
        margin-right: auto;

        &:after {
          display: none;
        }
      }

      &__options {
        li {
          &:before {
            content: attr(data-label);
            width: 100%;
            display: block;
            font-size: 16px;
            font-weight: $fontweight-semibold;
            color: $color-dark;
            margin-bottom: 4px;
          }

          > div {
            &:first-child {
              width: 100%;
              min-width: 100%;
              margin: 0 0 4px 0;
            }

            &:last-child {
              width: 100%;
              min-width: 100%;
            }
          }

          &:not(:first-child):not(:last-child) {
            margin-bottom: 16px;
          }

          &:first-child {
            display: none;
          }
        }
      }

      &__scale {
        &__options {
          > div.form-group {
            width: calc(50% - 8px);
            min-width: calc(50% - 8px);
            margin: 0;

            &:first-child {
              margin-right: 16px;
            }
          }
        }

        &__toggle {
          margin-top: 16px;
          padding-bottom: 40px;

          p {
            font-size: 14px;
            color: $color-grey;
            padding-left: 31px;
          }
        }
      }
    }

    &__summary {
      margin: 0;

      > div {
        width: 100%;
        min-width: 100%;
        margin: 16px 0 0 0;
      }
    }

    &__graph {
      margin-top: 16px;
      padding: 16px;

      &__head {
        flex-direction: column;

        > div {
          &:last-child {
            margin: 0 auto 0 0;
          }
        }
      }

      &__no-stats {
        align-items: center;
      }
    }
  }
}

@media screen and (min-width: $screen--m-min) and (max-width: $screen--l-max) {
  .feedback-editor {
    &__summary {
      margin: 0 -12px;

      > div {
        width: calc(50% - 24px);
        min-width: calc(50% - 24px);
        margin: 0 12px;

        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
          margin-top: 24px;
        }
      }
    }
  }
}

/*------------------------------------*\
    Feedback (modal)
\*------------------------------------*/
.feedback-modal {
  .modal-content {
    width: 96%;
    max-width: 560px;
  }

  &.deactivate {
    .modal-content {
      p {
        margin: 4px auto 32px auto;
      }

      .buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .button {
          margin: 0 8px;
        }
      }
    }
  }

  &.reorder {
    .modal-content {
      padding: 0;

      .modal-header {
        border-bottom: 1px solid $color-border;

        > div:nth-child(2) {
          margin-bottom: 20px;
        }
      }

      .modal-close {
        top: 16px;
        right: 16px;
      }

      .reorder-container {
        width: 100%;
        height: 380px;
        overflow-y: scroll;

        ul {
          display: flex;
          flex-direction: column;
          list-style-type: none;
          margin: 0;
          padding: 0;

          li {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 8px;
            border-bottom: 1px solid $color-border;

            span.draggable {
              display: block;
              width: 50px;
              min-width: 50px;
              height: 50px;
              text-align: center;
              padding-top: 17px;
              margin-right: 8px;
              cursor: grab;

              &:hover {
                background: rgba($color-border, 0.2);
              }
            }

            > .question {
              p {
                font-weight: bold;
                font-size: 16px;
                line-height: 24px;
                color: $color-black;
              }

              span {
                font-weight: bold;
                font-size: 14px;
                line-height: 16px;
                color: $color-grey;
              }
            }

            > .order {
              display: flex;
              flex-direction: row;
              align-items: center;
              margin-left: auto;

              span {
                display: block;
                width: 50px;
                height: 50px;
                text-align: center;
                padding-top: 13px;
                transition: 260ms ease;
                cursor: pointer;

                svg polygon {
                  transition: 260ms ease;
                }

                &:hover {
                  background: rgba($color-border, 0.2);

                  svg polygon:last-child {
                    fill: $color-black;
                  }
                }
              }
            }
          }
        }
      }

      .buttons {
        border-top: 1px solid $color-border;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        padding: 24px;

        .button {
          margin-left: 16px;
        }
      }
    }
  }
}

.gu-mirror {
  display: flex;
  flex-direction: row;
  align-items: center;

  span.draggable {
    display: block;
    width: 50px;
    height: 50px;
    text-align: center;
    padding-top: 17px;
    margin-right: 8px;
  }

  .order {
    display: none;
  }

  .question p {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #000;
  }

  .question span {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #a3a3a3;
  }
}

/*------------------------------------*\
    Feedback (not-allowed)
\*------------------------------------*/
.feedback-not-active {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding-top: 20px;
  border-radius: 12px;
  article {
    border-radius: 12px;
    &:first-child {
      width: 30%;
      > p {
        margin: 20px 0;
      }

      a {
        display: inline-block;
        > div {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          svg {
            display: flex;
            fill: $color-white;
          }
        }
      }
    }

    &:nth-child(2) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto;
      gap: 20px;
      align-items: center;
      justify-content: center;

      > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        gap: 20px;
        img {
          width: 100%;
          max-width: 250px;
          display: block;
          &:first-child {
            grid-column: span 2;
            justify-self: center;
          }
          &:last-child {
            grid-column: span 2;
            justify-self: center;
          }
        }
      }
    }
  }
}
@media screen and (max-width: $screen--m-max) {
  .feedback-not-active {
    flex-direction: column;
    padding: 20px;

    article {
      &:first-child {
        width: 100%;
      }
    }
  }
}
