:root {
  --logo: url('/assets/img/logo_bouwapp.png');
}

/*------------------------------------*\
    Grid + Flex
\*------------------------------------*/
.container {
  width: 100%;
  padding: 0 6.4rem;
  margin: 0 auto;

  &.full {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}

@media screen and (max-width: $screen--m-max) {
  .container {
    padding: 0 1.6rem;
  }
}

.flex {
  display: flex;

  &-row {
    flex-direction: row;

    &-reverse {
      flex-direction: row-reverse;
    }
  }

  &-wrap {
    flex-wrap: wrap;
  }
}

/*------------------------------------*\
    Global(s)
\*------------------------------------*/
html,
body,
div[app-default],
.app {
  height: 100%;
  overflow: hidden;
}

body {
  font-family: 'Mukta', sans-serif;
  font-weight: $regularFontWeight;
  font-size: 1.6rem;
  color: $paragraphTextColor;
  line-height: $defaultLineHeight;

  &.authentication-page {
    background-color: $secondaryPageBackground;
  }
}

app-two-factor {
  .app {
    flex-direction: column;
  }
}

.app {
  display: flex;
  flex-direction: row;

  &__menu {
    position: sticky;
    top: 0;
    width: 32.8rem;
    min-width: 32.8rem;
    height: 100%;
    padding: 4.8rem 2.4rem 10rem 2.4rem;
    background: $color-white;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__content {
    width: calc(100% - 32.8rem);
    min-width: calc(100% - 32.8rem);
    height: 100%;
    background: $color-background;
    overflow: auto;
    display: flex;
    flex-direction: column;
    border-radius: 4rem 0 0 4rem;

    &__wrapper {
      max-width: calc($screen--xxl - 32.8rem);
    }
  }

  &.fullscreen {
    .app__content {
      width: 100%;
      min-width: 100%;
    }

    .header__logo img {
      max-width: 200px;
      height: auto;
    }

    .bouwapp-bottombar {
      left: 6.4rem;
      right: unset;
      width: calc(100% - 12.8rem - 16px);
    }

    .header,
    .app__menu,
    .breadcrumbs,
    .app__toggle__handle,
    .navigation,
    app-footer,
    .footer {
      display: none;
    }
  }
}

@media screen and (max-width: $screen--l-max) {
  .app {
    &__content {
      border-radius: 0;
    }
  }
}

/*------------------------------------*\
    Typography
\*------------------------------------*/
p {
  color: $paragraphTextColor;
  margin: 3px 0 0 0;
  line-height: $paragraphLineHeight;

  b {
    font-weight: $semiBoldFontWeight;
  }

  a {
    font-weight: $regularFontWeight;
  }
}

a {
  cursor: pointer;

  &,
  &:link,
  &:visited {
    color: $linkTextColor;
    text-decoration: none;
  }

  font-weight: $semiBoldFontWeight;

  &:hover {
    text-decoration: none;
    filter: brightness(95%);
  }

  &:active {
    text-decoration: none;
    filter: brightness(90%);
  }

  &.unstyled {
    font: inherit;
    color: inherit;
    filter: none;
  }
}

h1 {
  color: $primaryTextColor;
  font-weight: $boldFontWeight;
  font-size: 32px;
  margin: 20px 0;

  &.hasDescription {
    margin-bottom: 0;
  }
}

h2 {
  color: $primaryTextColor;
  font-weight: $semiBoldFontWeight;
  font-size: 26px;
  margin: 20px 0 2px 0;
}

h3 {
  color: $primaryTextColor;
  font-weight: $semiBoldFontWeight;
  font-size: 22px;
  margin: 16px 0 2px 0;
}

h4 {
  color: $primaryTextColor;
  font-weight: $semiBoldFontWeight;
  font-size: 20px;
  margin: 16px 0 2px 0;
}

h5 {
  color: $primaryTextColor;
  font-weight: $semiBoldFontWeight;
  font-size: 18px;
  margin: 12px 0 2px 0;
}

h6 {
  font-weight: $semiBoldFontWeight;
  color: $primaryTextColor;
  font-size: 16px;
}

p.pageDescription {
  font-weight: $regularFontWeight;
  color: $color-dark;
  font-size: 16px;
  margin-bottom: 20px;
  max-width: 50%;
}

/*------------------------------------*\
    Layout
\*------------------------------------*/
.muted-text {
  color: $mutedTextColor;
  font-size: 15px;
}

hr {
  border-color: $separator;
  margin-top: 24px;
  margin-bottom: 24px;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.logo-image {
  background-image: var(--logo);
}

[hidden] {
  display: none !important;
}

@media screen and (max-width: $screen--l-max) {
  .app {
    flex-direction: column;

    &__menu {
      display: flex;
      flex-direction: column;
      position: fixed;
      z-index: 2000;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      pointer-events: none;
      transform: translateX(-100%);
      transition: 260ms ease;
      padding-left: 7.3rem;
      border: 0;
      padding-bottom: 0;

      &.slideIn {
        opacity: 1;
        pointer-events: auto;
        transform: translateX(-4.9rem);
      }
    }

    &__content {
      width: 100%;
      min-width: 100%;
    }
  }
}

@media screen and (max-width: $screen--m-max) {
  .header__nav__concept {
    flex-direction: column;
    margin-top: 20px;
  }

  p.pageDescription {
    max-width: 100%;
  }
}
