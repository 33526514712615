app-default-projects-detail-dashboard {
  section {
    margin-bottom: 2.4rem;

    h3 {
      font-size: 1.6rem;
      font-weight: $fontweight-bold;
      color: $color-dark;
      margin: 0;
      margin-bottom: 1.6rem;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      span {
        display: inline-block;
        height: 1.6rem;

        svg {
          fill: var(--primary);
          cursor: pointer;
          display: block;
        }
      }

      a {
        font-size: 1.4rem;
        margin-left: 0.4rem;
      }
    }
  }

  > div.split-sections {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0 2.4rem;
    align-items: start;

    .loader {
      text-align: center;
    }

    > section {
      flex-basis: calc(50% - 1.2rem);

      @media screen and (max-width: $screen--m-max) {
        flex-basis: 100%;
      }
    }
  }

  .communication {
    .ticket,
    .survey {
      background-color: $color-white;
      border: 1px solid $color-border;
      border-radius: 1.2rem;
      padding: 1.6rem;
      margin-bottom: 2.4rem;

      &.empty {
        text-align: center;
        padding: 3.2rem;
      }

      &:not(:last-child) {
        margin-bottom: 0.8rem;
      }

      > div {
        display: flex;
        justify-content: space-between;
        align-items: end;

        &:first-child {
          margin-bottom: 0.4rem;
          align-items: center;

          > span:first-child {
            font-weight: $fontweight-semibold;
            color: $color-dark;
          }

          > span:last-child {
            font-size: 1.4rem;
            font-weight: $fontweight-light;
            color: $color-dark;

            > span {
              color: $color-grey;
            }
          }
        }

        > div {
          color: $paragraphTextColor;
          height: 4.4rem;
          line-height: 2.2rem;
          width: calc(100% - 90px);
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;

          p {
            display: inline-block;
            margin: 0;
            line-height: 2.2rem;
          }
        }

        > a {
          font-weight: normal;
          color: $color-dark;
          width: 90px;
          text-align: right;
          line-height: 2.2rem;

          > span {
            display: inline-block;
            vertical-align: middle;

            &:last-child {
              width: 2rem;
              height: 2rem;

              svg {
                width: 100%;
                height: 100%;
                fill: $color-dark;
              }
            }
          }
        }
      }
    }
  }

  .updates {
    &__list {
      width: 100%;
      min-width: 100%;
      padding-left: 0;
    }
  }

  .charts {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 2.4rem;
    flex-wrap: wrap;
    min-height: 250px;
    position: relative;

    .loader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin: 0;
    }

    .chart {
      margin-top: 0;
      padding: 1.6rem;
      border: 1px solid $color-border;
      border-radius: 1.2rem;
      background-color: $color-white;
      flex: 1 1 calc(25% - 2.4rem);
      overflow: hidden;

      @media screen and (max-width: $screen--m-max) {
        flex-basis: calc(50% - 2.4rem);
      }

      > span {
        font-size: 1.8rem;
        color: $color-dark;
        font-weight: $fontweight-bold;
        display: block;
        margin-bottom: 1.6rem;
      }

      &__inner {
        height: 200px;
        canvas {
          margin-top: 0;
        }
      }
    }
  }
}

app-modal.export-data {
  form {
    display: flex;
    flex-wrap: wrap;
    gap: 1.6rem;

    app-form-group {
      flex-basis: calc(50% - 0.8rem);

      input {
        margin-bottom: 0;
      }
    }

    button {
      margin-left: auto;
    }
  }
}
