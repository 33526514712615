.btn {
  cursor: pointer;
  display: inline-block;
  border-radius: 0.8rem;
  font-size: 1.6rem;
  line-height: 100%;
  padding: 1.2rem 2.4rem;
  font-weight: $semiBoldFontWeight;
  white-space: nowrap;

  &,
  &:link,
  &:visited {
    color: $inverseTextColor;
    background-color: $buttonPrimaryBackground;
  }

  border: 0;
  outline: 0;

  &:hover {
    filter: brightness(95%);
    outline: 0;
    color: $inverseTextColor;
  }

  &:active {
    filter: brightness(90%);
    outline: 0;
    color: $inverseTextColor;
  }

  &.btn-xlg {
    font-size: 1.8rem;
    line-height: 100%;
    padding: 1.6rem 2.4rem;
  }

  &.btn-lg {
    font-size: 1.6rem;
    line-height: 100%;
    padding: 1.2rem 2.4rem;
  }

  &.btn-sm {
    font-size: 1.6rem;
    line-height: 100%;
    padding: 1.2rem 2.4rem;
  }

  img {
    display: inline-block;
    margin-right: 5px;
    vertical-align: middle;
  }

  &.btn-disabled,
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.6;
  }

  &.btn-secondary {
    &,
    &:link,
    &:visited {
      color: $inverseTextColor;
      background-color: $secondaryColor;
    }

    &:hover {
      background-color: darken($secondaryColor, 5%);
      outline: 0;
      color: $inverseTextColor;
    }

    &:active {
      background-color: darken($secondaryColor, 10%);
      outline: 0;
      color: $inverseTextColor;
    }
  }

  &[hidden] {
    display: none;
  }
}

.justified-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 35px;
  margin-bottom: 20px;

  button,
  .btn {
    &:first-child {
      margin-left: 0;
    }
  }
}

@media screen and (max-width: $screen--m-max) {
  .justified-buttons {
    flex-direction: column;

    > .btn {
      margin-bottom: 16px;
      text-align: center;
    }
  }
}

.button {
  position: relative;
  cursor: pointer;
  outline: 0;
  border: 0;
  font-family: 'Mukta', sans-serif;
  font-weight: $fontweight-semibold;
  letter-spacing: 0;
  user-select: none;
  display: block;
  transition: 260ms ease;
  appearance: none;
  text-decoration: none;
  border-radius: 0.8rem;

  &.noTransition {
    transition: none;
  }

  &.margin {
    margin: 5px;
  }

  &--m {
    font-size: 1.6rem;
    line-height: 100%;
    padding: 1.2rem 2.4rem;
  }

  &--l {
    font-size: 1.8rem;
    line-height: 100%;
    padding: 1.6rem 2.4rem;
  }

  &--flat {
    color: $color-white !important;
    box-shadow: none;

    &:visited {
      color: $color-white !important;
    }

    &.bg--blue {
      background: $color-blue;

      &:hover {
        background: darken($color-blue, 8%);
        color: $color-white;
      }
    }

    &.bg--orange {
      background: $color-orange;

      &:hover {
        background: darken($color-orange, 8%);
        color: $color-white;
      }
    }

    &.bg--red {
      background: $color-red;

      &:hover {
        background: darken($color-red, 8%);
        color: $color-white;
      }
    }

    &.bg--grey {
      color: $color-dark !important;
      background: $defaultBorder;

      &:hover {
        background: darken($defaultBorder, 8%);
      }
    }
  }

  &--ghost {
    background: none;
    box-shadow: none;

    &.bg--grey--hover {
      color: $color-grey !important;

      &:hover {
        background: $defaultBorder;
        color: $color-dark !important;
      }
    }

    &.bg--red--hover {
      color: $color-red !important;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &--icon {
    span {
      height: 24px;
      line-height: 24px;
      vertical-align: middle;
      display: inline-block;

      &:last-child {
        margin-left: 8px;
      }
    }
  }

  &--dashed {
    box-shadow: none;
    border: 1px dashed $color-border;

    &:hover {
      color: inherit;
      border-style: solid;
    }
  }

  &--outlined {
    box-shadow: inset 0 0 0 0.1rem $color-grey-light;
    color: $color-grey-light;
    background: none;

    &:hover,
    &:active {
      box-shadow: none;
    }

    &.bg--orange--hover {
      box-shadow: inset 0 0 0 0.1rem $color-orange;
      color: $color-orange;

      svg {
        fill: $color-orange;
      }

      &:hover {
        background: $color-orange;
        color: $color-white;

        svg {
          fill: $color-white;
        }
      }
    }

    &.bg--blue--hover {
      box-shadow: inset 0 0 0 0.1rem $color-blue;
      color: $color-blue;

      &:hover {
        background: $color-blue;
        color: $color-white;
      }
    }

    &.bg--dark--hover {
      box-shadow: inset 0 0 0 0.1rem $color-dark;
      color: $color-dark;

      svg {
        fill: $color-dark;
      }

      &:hover {
        background: $color-dark;
        color: $color-white;

        svg {
          fill: $color-white;
        }
      }
    }

    &.bg--grey--hover {
      color: $color-grey-light !important;

      &:hover {
        background: $color-grey-light;
        color: $color-white !important;
      }
    }

    &.bg--red--hover {
      color: $color-red !important;
      box-shadow: inset 0 0 0 0.1rem $color-red;

      &:hover {
        background: $color-red;
        color: $color-white !important;
      }
    }
  }

  &--no-border {
    border: 0;
    box-shadow: none;
  }

  &--dashed {
    border: 1px dashed $color-grey;
    box-shadow: none;
    background: none;

    &.bg--grey {
      color: $color-grey !important;

      &:hover {
        background: $defaultBorder;
        border: 1px solid $defaultBorder;
        color: $color-dark !important;
      }
    }
  }
}
